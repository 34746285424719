import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadTemplates as loadTemplatesAction,
  loadTemplatesSuccess,
  addTemplateSuccess,
  updateTemplateSuccess
} from '../actions/Template';

import {
  TEMPLATES_LOAD,
  TEMPLATE_ADD,
  TEMPLATE_UPDATE,
  TEMPLATE_DELETE,
  TEMPLATE_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getTemplateById = (id, callback) => {
  Api().get('template/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllTemplates = (callback) => {
  Api().post('template/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadTemplatesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`template/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`template`, { params })
    .then(data => data)
    .catch(error => error);
}


const addTemplateRequest = async (payload) =>
  await Api().post(`template`, payload)
    .then(data => data)
    .catch(error => error);

const updateTemplateRequest = async (payload) =>
  await Api().patch(`template/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteTemplateRequest = async (id) =>
  await Api().delete(`template/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadTemplates({ payload }) {
  try {
    const data = yield call(loadTemplatesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTemplatesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTemplates() {
  yield takeEvery(TEMPLATES_LOAD, loadTemplates);
}

function* loadTemplate({ selectedTemplateId }) {
  try {
    const data = yield call(loadTemplatesRequest, selectedTemplateId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTemplatesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTemplate() {
  yield takeEvery(TEMPLATES_LOAD, loadTemplate);
}


function* addTemplate({ payload }) {
  try {
    const data = yield call(addTemplateRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addTemplateSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddTemplate() {
  yield takeEvery(TEMPLATE_ADD, addTemplate);
}


function* updateTemplate({ payload }) {
  try {
    const data = yield call(updateTemplateRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateTemplateSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateTemplate() {
  yield takeEvery(TEMPLATE_UPDATE, updateTemplate);
}

function* deleteTemplate({ payload }) {
  try {
    const data = yield call(deleteTemplateRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTemplatesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllTemplates({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteTemplateRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadTemplatesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteTemplate() {
  yield takeEvery(TEMPLATE_DELETE, deleteTemplate);
}

function* processDeleteAllTemplates() {
  yield takeEvery(TEMPLATE_DELETE_ALL, deleteAllTemplates);
}

export default function* TemplateSagas() {
  yield all([fork(processLoadTemplates),
  fork(processLoadTemplate),
  fork(processAddTemplate),
  fork(processUpdateTemplate),
  fork(processDeleteAllTemplates),
  fork(processDeleteTemplate)]);
}
