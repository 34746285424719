import {
    TEMPSERIALNUMBERS_SELECT_ALL,
    TEMPSERIALNUMBERS_UNSELECT_ALL,
    TEMPSERIALNUMBER_SELECTED,
    TEMPSERIALNUMBERS_LOAD,
    TEMPSERIALNUMBERS_LOAD_SUCCESS,
    TEMPSERIALNUMBERS_LOAD_FAILED,
    TEMPSERIALNUMBER_LOAD,
    TEMPSERIALNUMBER_LOAD_SUCCESS,
    TEMPSERIALNUMBER_LOAD_FAILED,
    TEMPSERIALNUMBER_ADD,
    TEMPSERIALNUMBER_ADD_SUCCESS,
    TEMPSERIALNUMBER_ADD_FAILED,
    TEMPSERIALNUMBER_UPDATE,
    TEMPSERIALNUMBER_UPDATE_SUCCESS,
    TEMPSERIALNUMBER_UPDATE_FAILED,
    TEMPSERIALNUMBER_DELETE,
    TEMPSERIALNUMBER_DELETE_SUCCESS,
    TEMPSERIALNUMBER_DELETE_FAILED,
    TEMPSERIALNUMBER_DELETE_ALL,
    TEMPSERIALNUMBER_DELETE_ALL_SUCCESS,
    TEMPSERIALNUMBER_DELETE_ALL_FAILED
  } from '../constants/ActionTypes';
  import { PAGE_SIZES } from '../constants/AppConstant';
  
  const INIT_STATE = {
    paging: {
      records: [],
      offset: 0,
      limit: PAGE_SIZES,
      totalRecords: 0
    },
    selectedTempSerialNumber: null,
    selectedTempSerialNumberId: null,
    message: null,
    messageType: 'info',
    filter: {
      offset: 0,
      limit: PAGE_SIZES,
      orderBy: 'codeSorted',
      descending: true,
      filterGroups: null
    },
    hideLoading: true
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case TEMPSERIALNUMBERS_SELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = true;
              return data;
            })
          }
        }
      case TEMPSERIALNUMBERS_UNSELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = false;
              return data;
            })
          }
        }
      case TEMPSERIALNUMBER_SELECTED:
        return {
          ...state,
          selectedTempSerialNumber: action.payload,
          selectedTempSerialNumberId: action.payload.id
        };
      case TEMPSERIALNUMBERS_LOAD:
        return {
          ...state,
          filter: action.payload ? action.payload : state.filter
        };
      case TEMPSERIALNUMBERS_LOAD_SUCCESS:
        return {
          ...state,
          paging: action.payload
        };
      case TEMPSERIALNUMBERS_LOAD_FAILED:
        return {
          ...state
        };
      case TEMPSERIALNUMBER_LOAD:
        return {
          ...state
        };
      case TEMPSERIALNUMBER_LOAD_SUCCESS:
        return {
          ...state
        };
      case TEMPSERIALNUMBER_LOAD_FAILED:
        return {
          ...state
        };
      case TEMPSERIALNUMBER_ADD:
        return {
          ...state,
          hideLoading: false
        };
      case TEMPSERIALNUMBER_ADD_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case TEMPSERIALNUMBER_ADD_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case TEMPSERIALNUMBER_UPDATE:
        return {
          ...state,
          hideLoading: false
        };
      case TEMPSERIALNUMBER_UPDATE_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case TEMPSERIALNUMBER_UPDATE_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case TEMPSERIALNUMBER_DELETE:
        return {
          ...state
        };
      case TEMPSERIALNUMBER_DELETE_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case TEMPSERIALNUMBER_DELETE_FAILED:
        return {
          ...state
        };
      case TEMPSERIALNUMBER_DELETE_ALL:
        return {
          ...state
        };
      case TEMPSERIALNUMBER_DELETE_ALL_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case TEMPSERIALNUMBER_DELETE_ALL_FAILED:
        return {
          ...state
        };
      default:
        return state;
    }
  }
  