import {
  DOCUMENTS_SELECT_ALL,
  DOCUMENTS_UNSELECT_ALL,
  DOCUMENT_SELECTED,
  DOCUMENTS_LOAD,
  DOCUMENTS_LOAD_SUCCESS,
  DOCUMENTS_LOAD_FAILED,
  DOCUMENT_LOAD,
  DOCUMENT_LOAD_SUCCESS,
  DOCUMENT_LOAD_FAILED,
  DOCUMENT_ADD,
  DOCUMENT_ADD_SUCCESS,
  DOCUMENT_ADD_FAILED,
  DOCUMENT_UPDATE,
  DOCUMENT_UPDATE_SUCCESS,
  DOCUMENT_UPDATE_FAILED,
  DOCUMENT_DELETE,
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_DELETE_FAILED,
  DOCUMENT_DELETE_ALL,
  DOCUMENT_DELETE_ALL_SUCCESS,
  DOCUMENT_DELETE_ALL_FAILED,
  DOCUMENTS_EXPORT_EXCEL,
  DOCUMENTS_EXPORT_EXCEL_SUCCESS,
  DOCUMENTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllDocuments = () => {
  return {
    type: DOCUMENTS_SELECT_ALL
  }
}

export const unselectAllDocuments = () => {
  return {
    type: DOCUMENTS_UNSELECT_ALL
  }
}

export const selectDocument = data => {
  return {
    type: DOCUMENT_SELECTED,
    payload: data
  }
}

export const loadDocuments = (data) => {
  return {
    type: DOCUMENTS_LOAD,
    payload: data
  };
}

export const loadDocumentsSuccess = (data) => {
  return {
    type: DOCUMENTS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadDocumentsFailed = () => {
  return {
    type: DOCUMENTS_LOAD_FAILED
  };
}

export const loadDocument = () => {
  return {
    type: DOCUMENT_LOAD
  };
}

export const loadDocumentSuccess = (data) => {
  return {
    type: DOCUMENT_LOAD_SUCCESS,
    payload: data
  };
}


export const loadDocumentFailed = () => {
  return {
    type: DOCUMENT_LOAD_FAILED
  };
}


export const addDocument = (data) => {
  return {
    type: DOCUMENT_ADD,
    payload: data
  };
}


export const addDocumentSuccess = (filter) => {
  return {
    type: DOCUMENT_ADD_SUCCESS,
    message: 'Document added successfully.',
    payload: filter
  };
}

export const addDocumentFailed = () => {
  return {
    type: DOCUMENT_ADD_FAILED
  };
}

export const updateDocument = (data) => {
  return {
    type: DOCUMENT_UPDATE,
    payload: data
  };
}


export const updateDocumentSuccess = (data) => {
  return {
    type: DOCUMENT_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateDocumentFailed = (data) => {
  return {
    type: DOCUMENT_UPDATE_FAILED,
    payload: data
  };
}

export const deleteDocument = (data) => {
  return {
    type: DOCUMENT_DELETE,
    payload: data
  };
}

export const deleteDocumentSuccess = () => {
  return {
    type: DOCUMENT_DELETE_SUCCESS,
    message: 'Document deleted successfully.'
  };
}

export const deleteDocumentFailed = () => {
  return {
    type: DOCUMENT_DELETE_FAILED
  };
}

export const deleteAllDocuments = (data) => {
  return {
    type: DOCUMENT_DELETE_ALL,
    payload: data
  };
}

export const deleteAllDocumentsSuccess = () => {
  return {
    type: DOCUMENT_DELETE_ALL_SUCCESS,
    message: 'Document all deleted successfully.'
  };
}

export const deleteAllDocumentsFailed = () => {
  return {
    type: DOCUMENT_DELETE_ALL_FAILED
  };
}

export const exportExcelDocuments = (filter) => {
  return {
    type:DOCUMENTS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelDocumentsSuccess = (data) => {
  return {
    type:DOCUMENTS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelDocumentsFailed = () => {
  return {
    type:DOCUMENTS_EXPORT_EXCEL_FAILED
  };
}
