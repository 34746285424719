import {
  TEMPLATES_SELECT_ALL,
  TEMPLATES_UNSELECT_ALL,
  TEMPLATE_SELECTED,
  TEMPLATES_LOAD,
  TEMPLATES_LOAD_SUCCESS,
  TEMPLATES_LOAD_FAILED,
  TEMPLATE_LOAD,
  TEMPLATE_LOAD_SUCCESS,
  TEMPLATE_LOAD_FAILED,
  TEMPLATE_ADD,
  TEMPLATE_ADD_SUCCESS,
  TEMPLATE_ADD_FAILED,
  TEMPLATE_UPDATE,
  TEMPLATE_UPDATE_SUCCESS,
  TEMPLATE_UPDATE_FAILED,
  TEMPLATE_DELETE,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_DELETE_FAILED,
  TEMPLATE_DELETE_ALL,
  TEMPLATE_DELETE_ALL_SUCCESS,
  TEMPLATE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllTemplates = () => {
  return {
    type: TEMPLATES_SELECT_ALL
  }
}

export const unselectAllTemplates = () => {
  return {
    type: TEMPLATES_UNSELECT_ALL
  }
}

export const selectTemplate = data => {
  return {
    type: TEMPLATE_SELECTED,
    payload: data
  }
}

export const loadTemplates = (data) => {
  return {
    type: TEMPLATES_LOAD,
    payload: data
  };
}

export const loadTemplatesSuccess = (data) => {
  return {
    type: TEMPLATES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadTemplatesFailed = () => {
  return {
    type: TEMPLATES_LOAD_FAILED
  };
}

export const loadTemplate = () => {
  return {
    type: TEMPLATE_LOAD
  };
}

export const loadTemplateSuccess = (data) => {
  return {
    type: TEMPLATE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadTemplateFailed = () => {
  return {
    type: TEMPLATE_LOAD_FAILED
  };
}


export const addTemplate = (data) => {
  return {
    type: TEMPLATE_ADD,
    payload: data
  };
}


export const addTemplateSuccess = (filter) => {
  return {
    type: TEMPLATE_ADD_SUCCESS,
    message: 'Template added successfully.',
    payload: filter
  };
}

export const addTemplateFailed = () => {
  return {
    type: TEMPLATE_ADD_FAILED
  };
}

export const updateTemplate = (data) => {
  return {
    type: TEMPLATE_UPDATE,
    payload: data
  };
}


export const updateTemplateSuccess = (data) => {
  return {
    type: TEMPLATE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateTemplateFailed = (data) => {
  return {
    type: TEMPLATE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteTemplate = (data) => {
  return {
    type: TEMPLATE_DELETE,
    payload: data
  };
}

export const deleteTemplateSuccess = () => {
  return {
    type: TEMPLATE_DELETE_SUCCESS,
    message: 'Template deleted successfully.'
  };
}

export const deleteTemplateFailed = () => {
  return {
    type: TEMPLATE_DELETE_FAILED
  };
}

export const deleteAllTemplates = (data) => {
  return {
    type: TEMPLATE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllTemplatesSuccess = () => {
  return {
    type: TEMPLATE_DELETE_ALL_SUCCESS,
    message: 'Template all deleted successfully.'
  };
}

export const deleteAllTemplatesFailed = () => {
  return {
    type: TEMPLATE_DELETE_ALL_FAILED
  };
}
