import {
  MAILS_SELECT_ALL,
  MAILS_UNSELECT_ALL,
  MAIL_SELECTED,
  MAILS_LOAD,
  MAILS_LOAD_SUCCESS,
  MAILS_LOAD_FAILED,
  MAIL_LOAD,
  MAIL_LOAD_SUCCESS,
  MAIL_LOAD_FAILED,
  MAIL_ADD,
  MAIL_ADD_SUCCESS,
  MAIL_ADD_FAILED,
  MAIL_UPDATE,
  MAIL_UPDATE_SUCCESS,
  MAIL_UPDATE_FAILED,
  MAIL_DELETE,
  MAIL_DELETE_SUCCESS,
  MAIL_DELETE_FAILED,
  MAIL_DELETE_ALL,
  MAIL_DELETE_ALL_SUCCESS,
  MAIL_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllMails = () => {
  return {
    type: MAILS_SELECT_ALL
  }
}

export const unselectAllMails = () => {
  return {
    type: MAILS_UNSELECT_ALL
  }
}

export const selectMail = data => {
  return {
    type: MAIL_SELECTED,
    payload: data
  }
}

export const loadMails = (data) => {
  return {
    type: MAILS_LOAD,
    payload: data
  };
}

export const loadMailsSuccess = (data) => {
  return {
    type: MAILS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadMailsFailed = () => {
  return {
    type: MAILS_LOAD_FAILED
  };
}

export const loadMail = () => {
  return {
    type: MAIL_LOAD
  };
}

export const loadMailSuccess = (data) => {
  return {
    type: MAIL_LOAD_SUCCESS,
    payload: data
  };
}


export const loadMailFailed = () => {
  return {
    type: MAIL_LOAD_FAILED
  };
}


export const addMail = (data) => {
  return {
    type: MAIL_ADD,
    payload: data
  };
}


export const addMailSuccess = (filter) => {
  return {
    type: MAIL_ADD_SUCCESS,
    message: 'Mail added successfully.',
    payload: filter
  };
}

export const addMailFailed = () => {
  return {
    type: MAIL_ADD_FAILED
  };
}

export const updateMail = (data) => {
  return {
    type: MAIL_UPDATE,
    payload: data
  };
}


export const updateMailSuccess = (data) => {
  return {
    type: MAIL_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateMailFailed = (data) => {
  return {
    type: MAIL_UPDATE_FAILED,
    payload: data
  };
}

export const deleteMail = (data) => {
  return {
    type: MAIL_DELETE,
    payload: data
  };
}

export const deleteMailSuccess = () => {
  return {
    type: MAIL_DELETE_SUCCESS,
    message: 'Mail deleted successfully.'
  };
}

export const deleteMailFailed = () => {
  return {
    type: MAIL_DELETE_FAILED
  };
}

export const deleteAllMails = (data) => {
  return {
    type: MAIL_DELETE_ALL,
    payload: data
  };
}

export const deleteAllMailsSuccess = () => {
  return {
    type: MAIL_DELETE_ALL_SUCCESS,
    message: 'Mail all deleted successfully.'
  };
}

export const deleteAllMailsFailed = () => {
  return {
    type: MAIL_DELETE_ALL_FAILED
  };
}
