import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSettings as loadSettingsAction,
  loadSettingsSuccess,
  addSettingSuccess,
  updateSettingSuccess
} from '../actions/Setting';

import {
  SETTINGS_LOAD,
  SETTING_ADD,
  SETTING_UPDATE,
  SETTING_DELETE,
  SETTING_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSettingById = (id, callback) => {
  Api().get('setting/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllSettings = (callback) => {
  Api().post('setting/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadSettingsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`setting/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`setting`, { params })
    .then(data => data)
    .catch(error => error);
}


const addSettingRequest = async (payload) =>
  await Api().post(`setting`, payload)
    .then(data => data)
    .catch(error => error);

const updateSettingRequest = async (payload) =>
  await Api().patch(`setting/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSettingRequest = async (id) =>
  await Api().delete(`setting/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadSettings({ payload }) {
  try {
    const data = yield call(loadSettingsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSettingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSettings() {
  yield takeEvery(SETTINGS_LOAD, loadSettings);
}

function* loadSetting({ selectedSettingId }) {
  try {
    const data = yield call(loadSettingsRequest, selectedSettingId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSettingsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSetting() {
  yield takeEvery(SETTINGS_LOAD, loadSetting);
}


function* addSetting({ payload }) {
  try {
    const data = yield call(addSettingRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addSettingSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSetting() {
  yield takeEvery(SETTING_ADD, addSetting);
}


function* updateSetting({ payload }) {
  try {
    const data = yield call(updateSettingRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateSettingSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSetting() {
  yield takeEvery(SETTING_UPDATE, updateSetting);
}

function* deleteSetting({ payload }) {
  try {
    const data = yield call(deleteSettingRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSettingsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSettings({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSettingRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadSettingsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSetting() {
  yield takeEvery(SETTING_DELETE, deleteSetting);
}

function* processDeleteAllSettings() {
  yield takeEvery(SETTING_DELETE_ALL, deleteAllSettings);
}

export default function* SettingSagas() {
  yield all([fork(processLoadSettings),
  fork(processLoadSetting),
  fork(processAddSetting),
  fork(processUpdateSetting),
  fork(processDeleteAllSettings),
  fork(processDeleteSetting)]);
}
