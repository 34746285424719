import {
  EMPLOYEES_SELECT_ALL,
  EMPLOYEES_UNSELECT_ALL,
  EMPLOYEE_SELECTED,
  EMPLOYEES_LOAD,
  EMPLOYEES_LOAD_SUCCESS,
  EMPLOYEES_LOAD_FAILED,
  EMPLOYEE_LOAD,
  EMPLOYEE_LOAD_SUCCESS,
  EMPLOYEE_LOAD_FAILED,
  EMPLOYEE_ADD,
  EMPLOYEE_ADD_SUCCESS,
  EMPLOYEE_ADD_FAILED,
  EMPLOYEE_UPDATE,
  EMPLOYEE_UPDATE_SUCCESS,
  EMPLOYEE_UPDATE_FAILED,
  EMPLOYEE_DELETE,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_FAILED,
  EMPLOYEE_DELETE_ALL,
  EMPLOYEE_DELETE_ALL_SUCCESS,
  EMPLOYEE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllEmployees = () => {
  return {
    type: EMPLOYEES_SELECT_ALL
  }
}

export const unselectAllEmployees = () => {
  return {
    type: EMPLOYEES_UNSELECT_ALL
  }
}

export const selectEmployee = data => {
  return {
    type: EMPLOYEE_SELECTED,
    payload: data
  }
}

export const loadEmployees = (data) => {
  return {
    type: EMPLOYEES_LOAD,
    payload: data
  };
}

export const loadEmployeesSuccess = (data) => {
  return {
    type: EMPLOYEES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadEmployeesFailed = () => {
  return {
    type: EMPLOYEES_LOAD_FAILED
  };
}

export const loadEmployee = () => {
  return {
    type: EMPLOYEE_LOAD
  };
}

export const loadEmployeeSuccess = (data) => {
  return {
    type: EMPLOYEE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadEmployeeFailed = () => {
  return {
    type: EMPLOYEE_LOAD_FAILED
  };
}


export const addEmployee = (data) => {
  return {
    type: EMPLOYEE_ADD,
    payload: data
  };
}


export const addEmployeeSuccess = (filter) => {
  return {
    type: EMPLOYEE_ADD_SUCCESS,
    message: 'Employee added successfully.',
    payload: filter
  };
}

export const addEmployeeFailed = () => {
  return {
    type: EMPLOYEE_ADD_FAILED
  };
}

export const updateEmployee = (data) => {
  return {
    type: EMPLOYEE_UPDATE,
    payload: data
  };
}


export const updateEmployeeSuccess = (data) => {
  return {
    type: EMPLOYEE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateEmployeeFailed = (data) => {
  return {
    type: EMPLOYEE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteEmployee = (data) => {
  return {
    type: EMPLOYEE_DELETE,
    payload: data
  };
}

export const deleteEmployeeSuccess = () => {
  return {
    type: EMPLOYEE_DELETE_SUCCESS,
    message: 'Employee deleted successfully.'
  };
}

export const deleteEmployeeFailed = () => {
  return {
    type: EMPLOYEE_DELETE_FAILED
  };
}

export const deleteAllEmployees = (data) => {
  return {
    type: EMPLOYEE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllEmployeesSuccess = () => {
  return {
    type: EMPLOYEE_DELETE_ALL_SUCCESS,
    message: 'Employee all deleted successfully.'
  };
}

export const deleteAllEmployeesFailed = () => {
  return {
    type: EMPLOYEE_DELETE_ALL_FAILED
  };
}
