import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadTempSerialNumbers as loadTempSerialNumbersAction,
  loadTempSerialNumbersSuccess,
  addTempSerialNumberSuccess,
  updateTempSerialNumberSuccess,
  exportExcelTempSerialNumbersSuccess
} from '../actions/TempSerialNumber';

import {
  TEMPSERIALNUMBERS_LOAD,
  TEMPSERIALNUMBER_ADD,
  TEMPSERIALNUMBER_UPDATE,
  TEMPSERIALNUMBER_DELETE,
  TEMPSERIALNUMBER_DELETE_ALL,
  TEMPSERIALNUMBERS_EXPORT_EXCEL
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getTempSerialNumberById = (id, callback) => {
  Api().get('temp-serial-number/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllTempSerialNumbers = (callback) => {
  Api().post('temp-serial-number/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadTempSerialNumbersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`temp-serial-number/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`temp-serial-number`, { params })
    .then(data => data)
    .catch(error => error);
}

const addTempSerialNumberRequest = async (payload) =>
  await Api().post(`temp-serial-number`, payload)
    .then(data => data)
    .catch(error => error);

const updateTempSerialNumberRequest = async (payload) =>
  await Api().patch(`temp-serial-number/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteTempSerialNumberRequest = async (id) =>
  await Api().delete(`temp-serial-number/${id}`)
    .then(data => data)
    .catch(error => error);

const exportTempSerialNumbersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`temp-serial-number/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadTempSerialNumbers({ payload }) {
  try {
    
    const data = yield call(loadTempSerialNumbersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTempSerialNumbersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTempSerialNumbers() {
  yield takeEvery(TEMPSERIALNUMBERS_LOAD, loadTempSerialNumbers);
}

function* loadTempSerialNumber({ selectedTempSerialNumberId }) {
  try {
    
    const data = yield call(loadTempSerialNumbersRequest, selectedTempSerialNumberId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTempSerialNumbersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTempSerialNumber() {
  yield takeEvery(TEMPSERIALNUMBERS_LOAD, loadTempSerialNumber);
}

function* addTempSerialNumber({ payload }) {
  try {
    const data = yield call(addTempSerialNumberRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addTempSerialNumberSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddTempSerialNumber() {
  yield takeEvery(TEMPSERIALNUMBER_ADD, addTempSerialNumber);
}


function* updateTempSerialNumber({ payload }) {
  try {
    const data = yield call(updateTempSerialNumberRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateTempSerialNumberSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateTempSerialNumber() {
  yield takeEvery(TEMPSERIALNUMBER_UPDATE, updateTempSerialNumber);
}

function* deleteTempSerialNumber({ payload }) {
  try {
    const data = yield call(deleteTempSerialNumberRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTempSerialNumbersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllTempSerialNumbers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteTempSerialNumberRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadTempSerialNumbersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteTempSerialNumber() {
  yield takeEvery(TEMPSERIALNUMBER_DELETE, deleteTempSerialNumber);
}

function* processDeleteAllTempSerialNumbers() {
  yield takeEvery(TEMPSERIALNUMBER_DELETE_ALL, deleteAllTempSerialNumbers);
}

function* exportTempSerialNumbers({ payload }) {
  try {
    const data = yield call(exportTempSerialNumbersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelTempSerialNumbersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportTempSerialNumbers() {
  yield takeEvery(TEMPSERIALNUMBERS_EXPORT_EXCEL, exportTempSerialNumbers);
}

export default function* TempSerialNumberSagas() {
  yield all([fork(processLoadTempSerialNumbers),
  fork(processLoadTempSerialNumber),
  fork(processAddTempSerialNumber),
  fork(processUpdateTempSerialNumber),
  fork(processDeleteAllTempSerialNumbers),
  fork(processDeleteTempSerialNumber),
  fork(processExportTempSerialNumbers)]);
}
