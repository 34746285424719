import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "../constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "../actions/Auth";
import Api from './Api';

const auth = {};

const createUserWithEmailPasswordRequest = async (email, password) => {
  Api().post('user/register', { email: email, password: password })
    .then(authUser => authUser)
    .catch(error => error);
}

const signInUserWithEmailPasswordRequest = async (email, password) => { 
  return await Api().post('user/authenticate', { email: email, password: password })
    .then(authUser => {
      localStorage._ra___ = authUser.data.token;
      localStorage._info___ = JSON.stringify(authUser.data.info);

      return authUser;
    })
    .catch(error => error);
}

const signOutRequest = async () =>
  await  auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    
    if (!signInUser.data.token) {
      yield put(showAuthMessage('Đăng nhập không thành công'));
    } else {
      localStorage.setItem('user_id', signInUser.data.info.userId);
      yield put(userSignInSuccess(signInUser.data.info.userId));
    }
  } catch (error) {
    console.log(error);
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      localStorage.removeItem('_ra___');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* authSaga() {
  yield all([fork(signInUser),
    fork(createUserAccount),
    fork(signOutUser)]);
}
