import {
  SERIALNUMBERS_SELECT_ALL,
  SERIALNUMBERS_UNSELECT_ALL,
  SERIALNUMBER_SELECTED,
  SERIALNUMBERS_LOAD,
  SERIALNUMBERS_LOAD_SUCCESS,
  SERIALNUMBERS_LOAD_FAILED,
  SERIALNUMBER_LOAD,
  SERIALNUMBER_LOAD_SUCCESS,
  SERIALNUMBER_LOAD_FAILED,
  SERIALNUMBER_ADD,
  SERIALNUMBER_ADD_SUCCESS,
  SERIALNUMBER_ADD_FAILED,
  SERIALNUMBER_UPDATE,
  SERIALNUMBER_UPDATE_SUCCESS,
  SERIALNUMBER_UPDATE_FAILED,
  SERIALNUMBER_DELETE,
  SERIALNUMBER_DELETE_SUCCESS,
  SERIALNUMBER_DELETE_FAILED,
  SERIALNUMBER_DELETE_ALL,
  SERIALNUMBER_DELETE_ALL_SUCCESS,
  SERIALNUMBER_DELETE_ALL_FAILED,
  SERIALNUMBERS_EXPORT_EXCEL,
  SERIALNUMBERS_EXPORT_EXCEL_SUCCESS,
  SERIALNUMBERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllSerialNumbers = () => {
  return {
    type: SERIALNUMBERS_SELECT_ALL
  }
}

export const unselectAllSerialNumbers = () => {
  return {
    type: SERIALNUMBERS_UNSELECT_ALL
  }
}

export const selectSerialNumber = data => {
  return {
    type: SERIALNUMBER_SELECTED,
    payload: data
  }
}

export const loadSerialNumbers = (data) => {
  return {
    type: SERIALNUMBERS_LOAD,
    payload: data
  };
}

export const loadSerialNumbersSuccess = (data) => {
  return {
    type: SERIALNUMBERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSerialNumbersFailed = () => {
  return {
    type: SERIALNUMBERS_LOAD_FAILED
  };
}

export const loadSerialNumber = () => {
  return {
    type: SERIALNUMBER_LOAD
  };
}

export const loadSerialNumberSuccess = (data) => {
  return {
    type: SERIALNUMBER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSerialNumberFailed = () => {
  return {
    type: SERIALNUMBER_LOAD_FAILED
  };
}


export const addSerialNumber = (data) => {
  return {
    type: SERIALNUMBER_ADD,
    payload: data
  };
}


export const addSerialNumberSuccess = (filter) => {
  return {
    type: SERIALNUMBER_ADD_SUCCESS,
    message: 'SerialNumber added successfully.',
    payload: filter
  };
}

export const addSerialNumberFailed = () => {
  return {
    type: SERIALNUMBER_ADD_FAILED
  };
}

export const updateSerialNumber = (data) => {
  return {
    type: SERIALNUMBER_UPDATE,
    payload: data
  };
}


export const updateSerialNumberSuccess = (data) => {
  return {
    type: SERIALNUMBER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSerialNumberFailed = (data) => {
  return {
    type: SERIALNUMBER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSerialNumber = (data) => {
  return {
    type: SERIALNUMBER_DELETE,
    payload: data
  };
}

export const deleteSerialNumberSuccess = () => {
  return {
    type: SERIALNUMBER_DELETE_SUCCESS,
    message: 'SerialNumber deleted successfully.'
  };
}

export const deleteSerialNumberFailed = () => {
  return {
    type: SERIALNUMBER_DELETE_FAILED
  };
}

export const deleteAllSerialNumbers = (data) => {
  return {
    type: SERIALNUMBER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSerialNumbersSuccess = () => {
  return {
    type: SERIALNUMBER_DELETE_ALL_SUCCESS,
    message: 'SerialNumber all deleted successfully.'
  };
}

export const deleteAllSerialNumbersFailed = () => {
  return {
    type: SERIALNUMBER_DELETE_ALL_FAILED
  };
}

export const exportExcelSerialNumbers = (filter) => {
  return {
    type: SERIALNUMBERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelSerialNumbersSuccess = (data) => {
  return {
    type: SERIALNUMBERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelSerialNumbersFailed = () => {
  return {
    type: SERIALNUMBERS_EXPORT_EXCEL_FAILED
  };
}