import {
  CONTRACTS_SELECT_ALL,
  CONTRACTS_UNSELECT_ALL,
  CONTRACT_SELECTED,
  CONTRACTS_LOAD,
  CONTRACTS_LOAD_SUCCESS,
  CONTRACTS_LOAD_FAILED,
  CONTRACT_LOAD,
  CONTRACT_LOAD_SUCCESS,
  CONTRACT_LOAD_FAILED,
  CONTRACT_ADD,
  CONTRACT_ADD_SUCCESS,
  CONTRACT_ADD_FAILED,
  CONTRACT_UPDATE,
  CONTRACT_UPDATE_SUCCESS,
  CONTRACT_UPDATE_FAILED,
  CONTRACT_DELETE,
  CONTRACT_DELETE_SUCCESS,
  CONTRACT_DELETE_FAILED,
  CONTRACT_DELETE_ALL,
  CONTRACT_DELETE_ALL_SUCCESS,
  CONTRACT_DELETE_ALL_FAILED,
  CONTRACTS_EXPORT_EXCEL,
  CONTRACTS_EXPORT_EXCEL_SUCCESS,
  CONTRACTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllContracts = () => {
  return {
    type: CONTRACTS_SELECT_ALL
  }
}

export const unselectAllContracts = () => {
  return {
    type: CONTRACTS_UNSELECT_ALL
  }
}

export const selectContract = data => {
  return {
    type: CONTRACT_SELECTED,
    payload: data
  }
}

export const loadContracts = (data) => {
  return {
    type: CONTRACTS_LOAD,
    payload: data
  };
}

export const loadContractsSuccess = (data) => {
  return {
    type: CONTRACTS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadContractsFailed = () => {
  return {
    type: CONTRACTS_LOAD_FAILED
  };
}

export const loadContract = () => {
  return {
    type: CONTRACT_LOAD
  };
}

export const loadContractSuccess = (data) => {
  return {
    type: CONTRACT_LOAD_SUCCESS,
    payload: data
  };
}


export const loadContractFailed = () => {
  return {
    type: CONTRACT_LOAD_FAILED
  };
}


export const addContract = (data) => {
  return {
    type: CONTRACT_ADD,
    payload: data
  };
}


export const addContractSuccess = (filter) => {
  return {
    type: CONTRACT_ADD_SUCCESS,
    message: 'Contract added successfully.',
    payload: filter
  };
}

export const addContractFailed = () => {
  return {
    type: CONTRACT_ADD_FAILED
  };
}

export const updateContract = (data) => {
  return {
    type: CONTRACT_UPDATE,
    payload: data
  };
}


export const updateContractSuccess = (data) => {
  return {
    type: CONTRACT_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateContractFailed = (data) => {
  return {
    type: CONTRACT_UPDATE_FAILED,
    payload: data
  };
}

export const deleteContract = (data) => {
  return {
    type: CONTRACT_DELETE,
    payload: data
  };
}

export const deleteContractSuccess = () => {
  return {
    type: CONTRACT_DELETE_SUCCESS,
    message: 'Contract deleted successfully.'
  };
}

export const deleteContractFailed = () => {
  return {
    type: CONTRACT_DELETE_FAILED
  };
}

export const deleteAllContracts = (data) => {
  return {
    type: CONTRACT_DELETE_ALL,
    payload: data
  };
}

export const deleteAllContractsSuccess = () => {
  return {
    type: CONTRACT_DELETE_ALL_SUCCESS,
    message: 'Contract all deleted successfully.'
  };
}

export const deleteAllContractsFailed = () => {
  return {
    type: CONTRACT_DELETE_ALL_FAILED
  };
}

export const exportExcelContracts = (filter) => {
  return {
    type: CONTRACTS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelContractsSuccess = (data) => {
  return {
    type: CONTRACTS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelContractsFailed = () => {
  return {
    type: CONTRACTS_EXPORT_EXCEL_FAILED
  };
}