import {
  SHOW_LOADING,
  HIDE_LOADING,
  SAVE_EMPLOYEE_SUCCESS,
  SAVE_USER_SUCCESS,
  DIRECT_DATA,
  CLEAR_DIRECT_DATA
} from '../constants/ActionTypes';

const INIT_STATE = {
  hideGlobalLoading: true,
  user: localStorage._user___ ? JSON.parse(localStorage._user___) : {},
  employee: localStorage._employee___ ? JSON.parse(localStorage._employee___) : {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case HIDE_LOADING:
      return {
        ...state,
        hideGlobalLoading: true
      }
    case SHOW_LOADING:
      return {
        ...state,
        hideGlobalLoading: false
      }
    case SAVE_USER_SUCCESS:
      localStorage._user___ = JSON.stringify(action.payload);
      return {
        ...state,
        user: action.payload
      }
    case SAVE_EMPLOYEE_SUCCESS:
      localStorage._employee___ = JSON.stringify(action.payload);
      return {
        ...state,
        employee: action.payload
      }
    case DIRECT_DATA:
      localStorage.setItem('directData', JSON.stringify(action.payload));
      return {
        ...state,
        directData: action.payload
      }
    case CLEAR_DIRECT_DATA:
      localStorage.removeItem('directData');
      return {
        ...state,
        directData: null
      }
    default:
      return state;
  }
}
