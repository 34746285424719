import {
  SAMPLES_SELECT_ALL,
  SAMPLES_UNSELECT_ALL,
  SAMPLE_SELECTED,
  SAMPLES_LOAD,
  SAMPLES_LOAD_SUCCESS,
  SAMPLES_LOAD_FAILED,
  SAMPLE_LOAD,
  SAMPLE_LOAD_SUCCESS,
  SAMPLE_LOAD_FAILED,
  SAMPLE_ADD,
  SAMPLE_ADD_SUCCESS,
  SAMPLE_ADD_FAILED,
  SAMPLE_UPDATE,
  SAMPLE_UPDATE_SUCCESS,
  SAMPLE_UPDATE_FAILED,
  SAMPLE_DELETE,
  SAMPLE_DELETE_SUCCESS,
  SAMPLE_DELETE_FAILED,
  SAMPLE_DELETE_ALL,
  SAMPLE_DELETE_ALL_SUCCESS,
  SAMPLE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedSample: null,
  selectedSampleId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAMPLES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case SAMPLES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case SAMPLE_SELECTED:
      return {
        ...state,
        selectedSample: action.payload,
        selectedSampleId: action.payload.id
      };
    case SAMPLES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case SAMPLES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case SAMPLES_LOAD_FAILED:
      return {
        ...state
      };
    case SAMPLE_LOAD:
      return {
        ...state
      };
    case SAMPLE_LOAD_SUCCESS:
      return {
        ...state
      };
    case SAMPLE_LOAD_FAILED:
      return {
        ...state
      };
    case SAMPLE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case SAMPLE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SAMPLE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SAMPLE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case SAMPLE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SAMPLE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SAMPLE_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case SAMPLE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case SAMPLE_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SAMPLE_DELETE_ALL:
      return {
        ...state
      };
    case SAMPLE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SAMPLE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
