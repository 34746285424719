import {
  PROJECTS_SELECT_ALL,
  PROJECTS_UNSELECT_ALL,
  PROJECT_SELECTED,
  PROJECTS_LOAD,
  PROJECTS_LOAD_SUCCESS,
  PROJECTS_LOAD_FAILED,
  PROJECT_LOAD,
  PROJECT_LOAD_SUCCESS,
  PROJECT_LOAD_FAILED,
  PROJECT_ADD,
  PROJECT_ADD_SUCCESS,
  PROJECT_ADD_FAILED,
  PROJECT_UPDATE,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_UPDATE_FAILED,
  PROJECT_DELETE,
  PROJECT_DELETE_SUCCESS,
  PROJECT_DELETE_FAILED,
  PROJECT_DELETE_ALL,
  PROJECT_DELETE_ALL_SUCCESS,
  PROJECT_DELETE_ALL_FAILED,
  PROJECTS_EXPORT_EXCEL,
  PROJECTS_EXPORT_EXCEL_SUCCESS,
  PROJECTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllProjects = () => {
  return {
    type: PROJECTS_SELECT_ALL
  }
}

export const unselectAllProjects = () => {
  return {
    type: PROJECTS_UNSELECT_ALL
  }
}

export const selectProject = data => {
  return {
    type: PROJECT_SELECTED,
    payload: data
  }
}

export const loadProjects = (data) => {
  return {
    type: PROJECTS_LOAD,
    payload: data
  };
}

export const loadProjectsSuccess = (data) => {
  return {
    type: PROJECTS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadProjectsFailed = () => {
  return {
    type: PROJECTS_LOAD_FAILED
  };
}

export const loadProject = () => {
  return {
    type: PROJECT_LOAD
  };
}

export const loadProjectSuccess = (data) => {
  return {
    type: PROJECT_LOAD_SUCCESS,
    payload: data
  };
}


export const loadProjectFailed = () => {
  return {
    type: PROJECT_LOAD_FAILED
  };
}


export const addProject = (data) => {
  return {
    type: PROJECT_ADD,
    payload: data
  };
}


export const addProjectSuccess = (filter) => {
  return {
    type: PROJECT_ADD_SUCCESS,
    message: 'Project added successfully.',
    payload: filter
  };
}

export const addProjectFailed = () => {
  return {
    type: PROJECT_ADD_FAILED
  };
}

export const updateProject = (data) => {
  return {
    type: PROJECT_UPDATE,
    payload: data
  };
}


export const updateProjectSuccess = (data) => {
  return {
    type: PROJECT_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateProjectFailed = (data) => {
  return {
    type: PROJECT_UPDATE_FAILED,
    payload: data
  };
}

export const deleteProject = (data) => {
  return {
    type: PROJECT_DELETE,
    payload: data
  };
}

export const deleteProjectSuccess = () => {
  return {
    type: PROJECT_DELETE_SUCCESS,
    message: 'Project deleted successfully.'
  };
}

export const deleteProjectFailed = () => {
  return {
    type: PROJECT_DELETE_FAILED
  };
}

export const deleteAllProjects = (data) => {
  return {
    type: PROJECT_DELETE_ALL,
    payload: data
  };
}

export const deleteAllProjectsSuccess = () => {
  return {
    type: PROJECT_DELETE_ALL_SUCCESS,
    message: 'Project all deleted successfully.'
  };
}

export const deleteAllProjectsFailed = () => {
  return {
    type: PROJECT_DELETE_ALL_FAILED
  };
}

export const exportExcelProjects = (filter) => {
  return {
    type:PROJECTS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelProjectsSuccess = (data) => {
  return {
    type:PROJECTS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelProjectsFailed = () => {
  return {
    type:PROJECTS_EXPORT_EXCEL_FAILED
  };
}
