import {
  PAYMENTS_SELECT_ALL,
  PAYMENTS_UNSELECT_ALL,
  PAYMENT_SELECTED,
  PAYMENTS_LOAD,
  PAYMENTS_LOAD_SUCCESS,
  PAYMENTS_LOAD_FAILED,
  PAYMENT_LOAD,
  PAYMENT_LOAD_SUCCESS,
  PAYMENT_LOAD_FAILED,
  PAYMENT_ADD,
  PAYMENT_ADD_SUCCESS,
  PAYMENT_ADD_FAILED,
  PAYMENT_UPDATE,
  PAYMENT_UPDATE_SUCCESS,
  PAYMENT_UPDATE_FAILED,
  PAYMENT_DELETE,
  PAYMENT_DELETE_SUCCESS,
  PAYMENT_DELETE_FAILED,
  PAYMENT_DELETE_ALL,
  PAYMENT_DELETE_ALL_SUCCESS,
  PAYMENT_DELETE_ALL_FAILED,
  PAYMENTS_EXPORT_EXCEL,
  PAYMENTS_EXPORT_EXCEL_SUCCESS,
  PAYMENTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllPayments = () => {
  return {
    type: PAYMENTS_SELECT_ALL
  }
}

export const unselectAllPayments = () => {
  return {
    type: PAYMENTS_UNSELECT_ALL
  }
}

export const selectPayment = data => {
  return {
    type: PAYMENT_SELECTED,
    payload: data
  }
}

export const loadPayments = (data) => {
  return {
    type: PAYMENTS_LOAD,
    payload: data
  };
}

export const loadPaymentsSuccess = (data) => {
  return {
    type: PAYMENTS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadPaymentsFailed = () => {
  return {
    type: PAYMENTS_LOAD_FAILED
  };
}

export const loadPayment = () => {
  return {
    type: PAYMENT_LOAD
  };
}

export const loadPaymentSuccess = (data) => {
  return {
    type: PAYMENT_LOAD_SUCCESS,
    payload: data
  };
}

export const loadPaymentFailed = () => {
  return {
    type: PAYMENT_LOAD_FAILED
  };
}

export const addPayment = (data) => {
  return {
    type: PAYMENT_ADD,
    payload: data
  };
}

export const addPaymentSuccess = (filter) => {
  return {
    type: PAYMENT_ADD_SUCCESS,
    message: 'Payment added successfully.',
    payload: filter
  };
}

export const addPaymentFailed = () => {
  return {
    type: PAYMENT_ADD_FAILED
  };
}

export const updatePayment = (data) => {
  return {
    type: PAYMENT_UPDATE,
    payload: data
  };
}


export const updatePaymentSuccess = (data) => {
  return {
    type: PAYMENT_UPDATE_SUCCESS,
    payload: data
  };
}

export const updatePaymentFailed = (data) => {
  return {
    type: PAYMENT_UPDATE_FAILED,
    payload: data
  };
}

export const deletePayment = (data) => {
  return {
    type: PAYMENT_DELETE,
    payload: data
  };
}

export const deletePaymentSuccess = () => {
  return {
    type: PAYMENT_DELETE_SUCCESS,
    message: 'Payment deleted successfully.'
  };
}

export const deletePaymentFailed = () => {
  return {
    type: PAYMENT_DELETE_FAILED
  };
}

export const deleteAllPayments = (data) => {
  return {
    type: PAYMENT_DELETE_ALL,
    payload: data
  };
}

export const deleteAllPaymentsSuccess = () => {
  return {
    type: PAYMENT_DELETE_ALL_SUCCESS,
    message: 'Payment all deleted successfully.'
  };
}

export const deleteAllPaymentsFailed = () => {
  return {
    type: PAYMENT_DELETE_ALL_FAILED
  };
}

export const exportExcelPayments = (filter) => {
  return {
    type: PAYMENTS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelPaymentsSuccess = (data) => {
  return {
    type: PAYMENTS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelPaymentsFailed = () => {
  return {
    type: PAYMENTS_EXPORT_EXCEL_FAILED
  };
}
