import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadMails as loadMailsAction,
  loadMailsSuccess,
  updateMailSuccess,
  addMailSuccess,
  deleteMailSuccess,
  deleteAllMailsSuccess
} from '../actions/Mail';

import {
  MAILS_LOAD,
  MAIL_ADD,
  MAIL_UPDATE,
  MAIL_DELETE,
  MAIL_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getMailById = (id, callback) => {
  Api().get('mail/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllMails = (callback) => {
  Api().post('mail/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadMailsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`mail/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`mail`, { params })
    .then(data => data)
    .catch(error => error);
}

const addMailRequest = async (payload) =>
  await Api().post(`mail`, payload)
    .then(data => data)
    .catch(error => error);

const updateMailRequest = async (payload) =>
  await Api().patch(`mail/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteMailRequest = async (id) =>
  await Api().delete(`mail/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadMails({ payload }) {
  try {
    const data = yield call(loadMailsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadMailsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadMails() {
  yield takeEvery(MAILS_LOAD, loadMails);
}

function* loadMail({ selectedMailId }) {
  try {
    const data = yield call(loadMailsRequest, selectedMailId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadMailsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadMail() {
  yield takeEvery(MAILS_LOAD, loadMail);
}

function* addMail({ payload }) {
  try {
    const data = yield call(addMailRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addMailSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddMail() {
  yield takeEvery(MAIL_ADD, addMail);
}

function* updateMail({ payload }) {
  try {
    const data = yield call(updateMailRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateMailSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateMail() {
  yield takeEvery(MAIL_UPDATE, updateMail);
}

function* deleteMail({ payload }) {
  try {
    const data = yield call(deleteMailRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(deleteMailSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllMails({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteMailRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllMailsSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteMail() {
  yield takeEvery(MAIL_DELETE, deleteMail);
}

function* processDeleteAllMails() {
  yield takeEvery(MAIL_DELETE_ALL, deleteAllMails);
}

export default function* MailSagas() {
  yield all([fork(processLoadMails),
  fork(processLoadMail),
  fork(processAddMail),
  fork(processUpdateMail),
  fork(processDeleteAllMails),
  fork(processDeleteMail)]);
}
