import {
  TEMPLATES_SELECT_ALL,
  TEMPLATES_UNSELECT_ALL,
  TEMPLATE_SELECTED,
  TEMPLATES_LOAD,
  TEMPLATES_LOAD_SUCCESS,
  TEMPLATES_LOAD_FAILED,
  TEMPLATE_LOAD,
  TEMPLATE_LOAD_SUCCESS,
  TEMPLATE_LOAD_FAILED,
  TEMPLATE_ADD,
  TEMPLATE_ADD_SUCCESS,
  TEMPLATE_ADD_FAILED,
  TEMPLATE_UPDATE,
  TEMPLATE_UPDATE_SUCCESS,
  TEMPLATE_UPDATE_FAILED,
  TEMPLATE_DELETE,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_DELETE_FAILED,
  TEMPLATE_DELETE_ALL,
  TEMPLATE_DELETE_ALL_SUCCESS,
  TEMPLATE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedTemplate: null,
  selectedTemplateId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEMPLATES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case TEMPLATES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case TEMPLATE_SELECTED:
      return {
        ...state,
        selectedTemplate: action.payload,
        selectedTemplateId: action.payload.id
      };
    case TEMPLATES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case TEMPLATES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case TEMPLATES_LOAD_FAILED:
      return {
        ...state
      };
    case TEMPLATE_LOAD:
      return {
        ...state
      };
    case TEMPLATE_LOAD_SUCCESS:
      return {
        ...state
      };
    case TEMPLATE_LOAD_FAILED:
      return {
        ...state
      };
    case TEMPLATE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case TEMPLATE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case TEMPLATE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case TEMPLATE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case TEMPLATE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case TEMPLATE_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case TEMPLATE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case TEMPLATE_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case TEMPLATE_DELETE_ALL:
      return {
        ...state
      };
    case TEMPLATE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case TEMPLATE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
