import {
  NOTIFICATIONS_SELECT_ALL,
  NOTIFICATIONS_UNSELECT_ALL,
  NOTIFICATION_SELECTED,
  NOTIFICATIONS_LOAD,
  NOTIFICATIONS_LOAD_SUCCESS,
  NOTIFICATIONS_LOAD_FAILED,
  NOTIFICATION_LOAD,
  NOTIFICATION_LOAD_SUCCESS,
  NOTIFICATION_LOAD_FAILED,
  NOTIFICATION_ADD,
  NOTIFICATION_ADD_SUCCESS,
  NOTIFICATION_ADD_FAILED,
  NOTIFICATION_UPDATE,
  NOTIFICATION_UPDATE_SUCCESS,
  NOTIFICATION_UPDATE_FAILED,
  NOTIFICATION_DELETE,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_FAILED,
  NOTIFICATION_DELETE_ALL,
  NOTIFICATION_DELETE_ALL_SUCCESS,
  NOTIFICATION_DELETE_ALL_FAILED,
  NOTIFICATIONS_COUNT,
  NOTIFICATIONS_COUNT_SUCCESS
} from '../constants/ActionTypes';

export const selectAllNotifications = () => {
  return {
    type: NOTIFICATIONS_SELECT_ALL
  }
}

export const unselectAllNotifications = () => {
  return {
    type: NOTIFICATIONS_UNSELECT_ALL
  }
}

export const selectNotification = data => {
  return {
    type: NOTIFICATION_SELECTED,
    payload: data
  }
}

export const countNotifications = data => {
  return {
    type: NOTIFICATIONS_COUNT,
    payload: data
  }
}

export const countNotificationsSuccess = numberOfNotifications => {
  return {
    type: NOTIFICATIONS_COUNT_SUCCESS,
    payload: numberOfNotifications
  }
}

export const loadNotifications = (data, isNotify) => {
  return {
    type: NOTIFICATIONS_LOAD,
    payload: data,
    isNotify: isNotify
  };
}

export const loadNotificationsSuccess = (data, isNotify) => {
  return {
    type: NOTIFICATIONS_LOAD_SUCCESS,
    payload: data,
    isNotify: isNotify
  };
}

export const loadNotificationsFailed = () => {
  return {
    type: NOTIFICATIONS_LOAD_FAILED
  };
}

export const loadNotification = () => {
  return {
    type: NOTIFICATION_LOAD
  };
}

export const loadNotificationSuccess = (data) => {
  return {
    type: NOTIFICATION_LOAD_SUCCESS,
    payload: data
  };
}


export const loadNotificationFailed = () => {
  return {
    type: NOTIFICATION_LOAD_FAILED
  };
}


export const addNotification = (data) => {
  return {
    type: NOTIFICATION_ADD,
    payload: data
  };
}


export const addNotificationSuccess = (filter) => {
  return {
    type: NOTIFICATION_ADD_SUCCESS,
    message: 'Notification added successfully.',
    payload: filter
  };
}

export const addNotificationFailed = () => {
  return {
    type: NOTIFICATION_ADD_FAILED
  };
}

export const updateNotification = (data) => {
  return {
    type: NOTIFICATION_UPDATE,
    payload: data
  };
}


export const updateNotificationSuccess = (data) => {
  return {
    type: NOTIFICATION_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateNotificationFailed = (data) => {
  return {
    type: NOTIFICATION_UPDATE_FAILED,
    payload: data
  };
}

export const deleteNotification = (data) => {
  return {
    type: NOTIFICATION_DELETE,
    payload: data
  };
}

export const deleteNotificationSuccess = () => {
  return {
    type: NOTIFICATION_DELETE_SUCCESS,
    message: 'Notification deleted successfully.'
  };
}

export const deleteNotificationFailed = () => {
  return {
    type: NOTIFICATION_DELETE_FAILED
  };
}

export const deleteAllNotifications = (data) => {
  return {
    type: NOTIFICATION_DELETE_ALL,
    payload: data
  };
}

export const deleteAllNotificationsSuccess = () => {
  return {
    type: NOTIFICATION_DELETE_ALL_SUCCESS,
    message: 'Notification all deleted successfully.'
  };
}

export const deleteAllNotificationsFailed = () => {
  return {
    type: NOTIFICATION_DELETE_ALL_FAILED
  };
}
