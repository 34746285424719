import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
  loadPersonalPapers as loadPersonalPapersAction,
  loadPersonalPapersSuccess
} from '../actions/PersonalPaper';

import {
  PERSONALPAPERS_LOAD,
  PERSONALPAPER_ADD,
  PERSONALPAPER_UPDATE,
  PERSONALPAPER_DELETE,
  PERSONALPAPER_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

const loadPersonalPapersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`personalpaper/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`personalpaper`, { params })
    .then(data => data)
    .catch(error => error);
}


const addPersonalPaperRequest = async (payload) =>
  await Api().post(`personalpaper`, payload)
    .then(data => data)
    .catch(error => error);

const updatePersonalPaperRequest = async (payload) =>
  await Api().patch(`personalpaper/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deletePersonalPaperRequest = async (id) =>
  await Api().delete(`personalpaper/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadPersonalPapers({ payload }) {
  try {
    const data = yield call(loadPersonalPapersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPersonalPapersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}


function* processLoadPersonalPapers() {
  yield takeEvery(PERSONALPAPERS_LOAD, loadPersonalPapers);
}

function* loadPersonalPaper({ selectedPersonalPaperId }) {
  try {
    const data = yield call(loadPersonalPapersRequest, selectedPersonalPaperId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPersonalPapersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadPersonalPaper() {
  yield takeEvery(PERSONALPAPERS_LOAD, loadPersonalPaper);
}


function* addPersonalPaper({ payload }) {
  try {
    const data = yield call(addPersonalPaperRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPersonalPapersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddPersonalPaper() {
  yield takeEvery(PERSONALPAPER_ADD, addPersonalPaper);
}


function* updatePersonalPaper({ payload }) {
  try {
    const data = yield call(updatePersonalPaperRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPersonalPapersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdatePersonalPaper() {
  yield takeEvery(PERSONALPAPER_UPDATE, updatePersonalPaper);
}

function* deletePersonalPaper({ payload }) {
  try {
    const data = yield call(deletePersonalPaperRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPersonalPapersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllPersonalPapers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deletePersonalPaperRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadPersonalPapersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeletePersonalPaper() {
  yield takeEvery(PERSONALPAPER_DELETE, deletePersonalPaper);
}

function* processDeleteAllPersonalPapers() {
  yield takeEvery(PERSONALPAPER_DELETE_ALL, deleteAllPersonalPapers);
}

export default function* PersonalPaperSagas() {
  yield all([fork(processLoadPersonalPapers),
  fork(processLoadPersonalPaper),
  fork(processAddPersonalPaper),
  fork(processUpdatePersonalPaper),
  fork(processDeleteAllPersonalPapers),
  fork(processDeletePersonalPaper)]);
}
