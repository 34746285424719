import {
    ALBUMS_SELECT_ALL,
    ALBUMS_UNSELECT_ALL,
    ALBUM_SELECTED,
    ALBUMS_LOAD,
    ALBUMS_LOAD_SUCCESS,
    ALBUMS_LOAD_FAILED,
    ALBUM_LOAD,
    ALBUM_LOAD_SUCCESS,
    ALBUM_LOAD_FAILED,
    ALBUM_ADD,
    ALBUM_ADD_SUCCESS,
    ALBUM_ADD_FAILED,
    ALBUM_UPDATE,
    ALBUM_UPDATE_SUCCESS,
    ALBUM_UPDATE_FAILED,
    ALBUM_DELETE,
    ALBUM_DELETE_SUCCESS,
    ALBUM_DELETE_FAILED,
    ALBUM_DELETE_ALL,
    ALBUM_DELETE_ALL_SUCCESS,
    ALBUM_DELETE_ALL_FAILED
  } from '../constants/ActionTypes';
  import { PAGE_SIZES } from '../constants/AppConstant';
  
  const INIT_STATE = {
    paging: {
      records: [],
      offset: 0,
      limit: PAGE_SIZES,
      totalRecords: 0
    },
    selectedAlbum: null,
    selectedAlbumId: null,
    message: null,
    messageType: 'info',
    filter: {
      offset: 0,
      limit: PAGE_SIZES,
      orderBy: 'name',
      filterGroups: null
    },
    hideLoading: true
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case ALBUMS_SELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = true;
              return data;
            })
          }
        }
      case ALBUMS_UNSELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = false;
              return data;
            })
          }
        }
      case ALBUM_SELECTED:
        return {
          ...state,
          selectedAlbum: action.payload,
          selectedAlbumId: action.payload.id
        };
      case ALBUMS_LOAD:
        return {
          ...state,
          filter: action.payload ? action.payload : state.filter
        };
      case ALBUMS_LOAD_SUCCESS:
        return {
          ...state,
          paging: action.payload
        };
      case ALBUMS_LOAD_FAILED:
        return {
          ...state
        };
      case ALBUM_LOAD:
        return {
          ...state
        };
      case ALBUM_LOAD_SUCCESS:
        return {
          ...state,
          selectedAlbum: action.payload,
          selectedAlbumId: action.payload.id
        };
      case ALBUM_LOAD_FAILED:
        return {
          ...state,
          selectedAlbum: null,
          selectedAlbumId: null          
        };
      case ALBUM_ADD:
        return {
          ...state,
          hideLoading: false
        };
      case ALBUM_ADD_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case ALBUM_ADD_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case ALBUM_UPDATE:
        return {
          ...state,
          hideLoading: false
        };
      case ALBUM_UPDATE_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case ALBUM_UPDATE_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case ALBUM_DELETE:
        return {
          ...state
        };
      case ALBUM_DELETE_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case ALBUM_DELETE_FAILED:
        return {
          ...state
        };
      case ALBUM_DELETE_ALL:
        return {
          ...state
        };
      case ALBUM_DELETE_ALL_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case ALBUM_DELETE_ALL_FAILED:
        return {
          ...state
        };
      default:
        return state;
    }
  }
  