import {
  DEPARTMENTS_SELECT_ALL,
  DEPARTMENTS_UNSELECT_ALL,
  DEPARTMENT_SELECTED,
  DEPARTMENTS_LOAD,
  DEPARTMENTS_LOAD_SUCCESS,
  DEPARTMENTS_LOAD_FAILED,
  DEPARTMENT_LOAD,
  DEPARTMENT_LOAD_SUCCESS,
  DEPARTMENT_LOAD_FAILED,
  DEPARTMENT_ADD,
  DEPARTMENT_ADD_SUCCESS,
  DEPARTMENT_ADD_FAILED,
  DEPARTMENT_UPDATE,
  DEPARTMENT_UPDATE_SUCCESS,
  DEPARTMENT_UPDATE_FAILED,
  DEPARTMENT_DELETE,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_FAILED,
  DEPARTMENT_DELETE_ALL,
  DEPARTMENT_DELETE_ALL_SUCCESS,
  DEPARTMENT_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllDepartments = () => {
  return {
    type: DEPARTMENTS_SELECT_ALL
  }
}

export const unselectAllDepartments = () => {
  return {
    type: DEPARTMENTS_UNSELECT_ALL
  }
}

export const selectDepartment = data => {
  return {
    type: DEPARTMENT_SELECTED,
    payload: data
  }
}

export const loadDepartments = (data) => {
  return {
    type: DEPARTMENTS_LOAD,
    payload: data
  };
}

export const loadDepartmentsSuccess = (data) => {
  return {
    type: DEPARTMENTS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadDepartmentsFailed = () => {
  return {
    type: DEPARTMENTS_LOAD_FAILED
  };
}

export const loadDepartment = () => {
  return {
    type: DEPARTMENT_LOAD
  };
}

export const loadDepartmentSuccess = (data) => {
  return {
    type: DEPARTMENT_LOAD_SUCCESS,
    payload: data
  };
}


export const loadDepartmentFailed = () => {
  return {
    type: DEPARTMENT_LOAD_FAILED
  };
}


export const addDepartment = (data) => {
  return {
    type: DEPARTMENT_ADD,
    payload: data
  };
}


export const addDepartmentSuccess = (filter) => {
  return {
    type: DEPARTMENT_ADD_SUCCESS,
    message: 'Department added successfully.',
    payload: filter
  };
}

export const addDepartmentFailed = () => {
  return {
    type: DEPARTMENT_ADD_FAILED
  };
}

export const updateDepartment = (data) => {
  return {
    type: DEPARTMENT_UPDATE,
    payload: data
  };
}


export const updateDepartmentSuccess = (data) => {
  return {
    type: DEPARTMENT_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateDepartmentFailed = (data) => {
  return {
    type: DEPARTMENT_UPDATE_FAILED,
    payload: data
  };
}

export const deleteDepartment = (data) => {
  return {
    type: DEPARTMENT_DELETE,
    payload: data
  };
}

export const deleteDepartmentSuccess = () => {
  return {
    type: DEPARTMENT_DELETE_SUCCESS,
    message: 'Department deleted successfully.'
  };
}

export const deleteDepartmentFailed = () => {
  return {
    type: DEPARTMENT_DELETE_FAILED
  };
}

export const deleteAllDepartments = (data) => {
  return {
    type: DEPARTMENT_DELETE_ALL,
    payload: data
  };
}

export const deleteAllDepartmentsSuccess = () => {
  return {
    type: DEPARTMENT_DELETE_ALL_SUCCESS,
    message: 'Department all deleted successfully.'
  };
}

export const deleteAllDepartmentsFailed = () => {
  return {
    type: DEPARTMENT_DELETE_ALL_FAILED
  };
}
