import {
  LEAVETRACKS_SELECT_ALL,
  LEAVETRACKS_UNSELECT_ALL,
  LEAVETRACK_SELECTED,
  LEAVETRACKS_LOAD,
  LEAVETRACKS_LOAD_SUCCESS,
  LEAVETRACKS_LOAD_FAILED,
  LEAVETRACK_LOAD,
  LEAVETRACK_LOAD_SUCCESS,
  LEAVETRACK_LOAD_FAILED,
  LEAVETRACK_ADD,
  LEAVETRACK_ADD_SUCCESS,
  LEAVETRACK_ADD_FAILED,
  LEAVETRACK_UPDATE,
  LEAVETRACK_UPDATE_SUCCESS,
  LEAVETRACK_UPDATE_FAILED,
  LEAVETRACK_DELETE,
  LEAVETRACK_DELETE_SUCCESS,
  LEAVETRACK_DELETE_FAILED,
  LEAVETRACK_DELETE_ALL,
  LEAVETRACK_DELETE_ALL_SUCCESS,
  LEAVETRACK_DELETE_ALL_FAILED,
  LEAVETRACKS_EXPORT_EXCEL,
  LEAVETRACKS_EXPORT_EXCEL_SUCCESS,
  LEAVETRACKS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllLeaveTracks = () => {
  return {
    type: LEAVETRACKS_SELECT_ALL
  }
}

export const unselectAllLeaveTracks = () => {
  return {
    type: LEAVETRACKS_UNSELECT_ALL
  }
}

export const selectLeaveTrack = data => {
  return {
    type: LEAVETRACK_SELECTED,
    payload: data
  }
}

export const loadLeaveTracks = (data) => {
  return {
    type: LEAVETRACKS_LOAD,
    payload: data
  };
}

export const loadLeaveTracksSuccess = (data) => {
  return {
    type: LEAVETRACKS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadLeaveTracksFailed = () => {
  return {
    type: LEAVETRACKS_LOAD_FAILED
  };
}

export const loadLeaveTrack = () => {
  return {
    type: LEAVETRACK_LOAD
  };
}

export const loadLeaveTrackSuccess = (data) => {
  return {
    type: LEAVETRACK_LOAD_SUCCESS,
    payload: data
  };
}


export const loadLeaveTrackFailed = () => {
  return {
    type: LEAVETRACK_LOAD_FAILED
  };
}


export const addLeaveTrack = (data) => {
  return {
    type: LEAVETRACK_ADD,
    payload: data
  };
}


export const addLeaveTrackSuccess = (filter) => {
  return {
    type: LEAVETRACK_ADD_SUCCESS,
    message: 'LeaveTrack added successfully.',
    payload: filter
  };
}

export const addLeaveTrackFailed = () => {
  return {
    type: LEAVETRACK_ADD_FAILED
  };
}

export const updateLeaveTrack = (data) => {
  return {
    type: LEAVETRACK_UPDATE,
    payload: data
  };
}


export const updateLeaveTrackSuccess = (data) => {
  return {
    type: LEAVETRACK_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateLeaveTrackFailed = (data) => {
  return {
    type: LEAVETRACK_UPDATE_FAILED,
    payload: data
  };
}

export const deleteLeaveTrack = (data) => {
  return {
    type: LEAVETRACK_DELETE,
    payload: data
  };
}

export const deleteLeaveTrackSuccess = () => {
  return {
    type: LEAVETRACK_DELETE_SUCCESS,
    message: 'LeaveTrack deleted successfully.'
  };
}

export const deleteLeaveTrackFailed = () => {
  return {
    type: LEAVETRACK_DELETE_FAILED
  };
}

export const deleteAllLeaveTracks = (data) => {
  return {
    type: LEAVETRACK_DELETE_ALL,
    payload: data
  };
}

export const deleteAllLeaveTracksSuccess = () => {
  return {
    type: LEAVETRACK_DELETE_ALL_SUCCESS,
    message: 'LeaveTrack all deleted successfully.'
  };
}

export const deleteAllLeaveTracksFailed = () => {
  return {
    type: LEAVETRACK_DELETE_ALL_FAILED
  };
}

export const exportExcelLeaveTracks = (filter) => {
  return {
    type:LEAVETRACKS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelLeaveTracksSuccess = (data) => {
  return {
    type:LEAVETRACKS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelLeaveTracksFailed = () => {
  return {
    type:LEAVETRACKS_EXPORT_EXCEL_FAILED
  };
}
