import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadPartners as loadPartnersAction,
  loadPartnersSuccess,
  addPartnerSuccess,
  updatePartnerSuccess,
  exportExcelPartners,
  exportExcelPartnersSuccess,
  exportExcelPartnersFailed
} from '../actions/Partner';

import {
  PARTNERS_LOAD,
  PARTNER_ADD,
  PARTNER_UPDATE,
  PARTNER_DELETE,
  PARTNER_DELETE_ALL,
  PARTNERS_EXPORT_EXCEL,
  PARTNERS_EXPORT_EXCEL_SUCCESS,
  PARTNERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getPartnerById = (id, callback) => {
  Api().get('partner/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllPartners = (callback) => {
  Api().post('partner/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getPartnersByCompany = (type, company, callback) => {
  Api().post('partner/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxPartnerId = (callback) => {
  Api().post('partner/search', { offset: 0,
    limit: 1,
    orderBy: "id",
    descending: true
  })
    .then(response => {
      let id = 1;
      let records = response.data.records;
      if (records.length) {
        id = records[0].id;
      }
      callback(id);
    })
}

const loadPartnersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`partner/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`partner`, { params })
    .then(data => data)
    .catch(error => error);
}

const addPartnerRequest = async (payload) =>
  await Api().post(`partner`, payload)
    .then(data => data)
    .catch(error => error);

const updatePartnerRequest = async (payload) =>
  await Api().patch(`partner/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deletePartnerRequest = async (id) =>
  await Api().delete(`partner/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadPartners({ payload }) {
  try {
    const data = yield call(loadPartnersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPartnersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadPartners() {
  yield takeEvery(PARTNERS_LOAD, loadPartners);
}

function* loadPartner({ selectedPartnerId }) {
  try {
    const data = yield call(loadPartnersRequest, selectedPartnerId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPartnersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadPartner() {
  yield takeEvery(PARTNERS_LOAD, loadPartner);
}


function* addPartner({ payload }) {
  try {
    const data = yield call(addPartnerRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addPartnerSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddPartner() {
  yield takeEvery(PARTNER_ADD, addPartner);
}


function* updatePartner({ payload }) {
  try {
    const data = yield call(updatePartnerRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updatePartnerSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdatePartner() {
  yield takeEvery(PARTNER_UPDATE, updatePartner);
}

function* deletePartner({ payload }) {
  try {
    const data = yield call(deletePartnerRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPartnersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllPartners({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deletePartnerRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadPartnersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeletePartner() {
  yield takeEvery(PARTNER_DELETE, deletePartner);
}

function* processDeleteAllPartners() {
  yield takeEvery(PARTNER_DELETE_ALL, deleteAllPartners);
}


const exportPartnersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`partner/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportPartners({ payload }) {
  try {
    const data = yield call(exportPartnersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelPartnersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportPartners() {
  yield takeEvery(PARTNERS_EXPORT_EXCEL, exportPartners);
}

export default function* PartnerSagas() {
  yield all([fork(processLoadPartners),
  fork(processLoadPartner),
  fork(processAddPartner),
  fork(processUpdatePartner),
  fork(processDeleteAllPartners),
  fork(processDeletePartner),
  fork(processExportPartners)
]);
}
