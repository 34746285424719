import {
  CUSTOMERS_SELECT_ALL,
  CUSTOMERS_UNSELECT_ALL,
  CUSTOMER_SELECTED,
  CUSTOMERS_LOAD,
  CUSTOMERS_LOAD_SUCCESS,
  CUSTOMERS_LOAD_FAILED,
  CUSTOMER_LOAD,
  CUSTOMER_LOAD_SUCCESS,
  CUSTOMER_LOAD_FAILED,
  CUSTOMER_ADD,
  CUSTOMER_ADD_SUCCESS,
  CUSTOMER_ADD_FAILED,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAILED,
  CUSTOMER_DELETE,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAILED,
  CUSTOMER_DELETE_ALL,
  CUSTOMER_DELETE_ALL_SUCCESS,
  CUSTOMER_DELETE_ALL_FAILED,
  CUSTOMERS_EXPORT_EXCEL,
  CUSTOMERS_EXPORT_EXCEL_SUCCESS,
  CUSTOMERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedCustomer: null,
  selectedCustomerId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CUSTOMERS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case CUSTOMERS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case CUSTOMER_SELECTED:
      return {
        ...state,
        selectedCustomer: action.payload,
        selectedCustomerId: action.payload.id
      };
    case CUSTOMERS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case CUSTOMERS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case CUSTOMERS_LOAD_FAILED:
      return {
        ...state
      };
    case CUSTOMER_LOAD:
      return {
        ...state
      };
    case CUSTOMER_LOAD_SUCCESS:
      return {
        ...state
      };
    case CUSTOMER_LOAD_FAILED:
      return {
        ...state
      };
    case CUSTOMER_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case CUSTOMER_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case CUSTOMER_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case CUSTOMER_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case CUSTOMER_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case CUSTOMER_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case CUSTOMER_DELETE:
      return {
        ...state
      };
    case CUSTOMER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case CUSTOMER_DELETE_FAILED:
      return {
        ...state
      };
    case CUSTOMER_DELETE_ALL:
      return {
        ...state
      };
    case CUSTOMER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case CUSTOMER_DELETE_ALL_FAILED:
      return {
        ...state
      };
      case CUSTOMERS_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case CUSTOMERS_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case CUSTOMERS_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
    default:
      return state;
  }
}
