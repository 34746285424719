import {
    MEDIAS_SELECT_ALL,
    MEDIAS_UNSELECT_ALL,
    MEDIA_SELECTED,
    MEDIAS_LOAD,
    MEDIAS_LOAD_SUCCESS,
    MEDIAS_LOAD_FAILED,
    MEDIA_LOAD,
    MEDIA_LOAD_SUCCESS,
    MEDIA_LOAD_FAILED,
    MEDIA_ADD,
    MEDIA_ADD_SUCCESS,
    MEDIA_ADD_FAILED,
    MEDIA_UPDATE,
    MEDIA_UPDATE_SUCCESS,
    MEDIA_UPDATE_FAILED,
    MEDIA_DELETE,
    MEDIA_DELETE_SUCCESS,
    MEDIA_DELETE_FAILED,
    MEDIA_DELETE_ALL,
    MEDIA_DELETE_ALL_SUCCESS,
    MEDIA_DELETE_ALL_FAILED
  } from '../constants/ActionTypes';
  import { PAGE_SIZES } from '../constants/AppConstant';
  
  const INIT_STATE = {
    paging: {
      records: [],
      offset: 0,
      limit: PAGE_SIZES,
      totalRecords: 0
    },
    selectedMedia: null,
    selectedMediaId: null,
    message: null,
    messageType: 'info',
    filter: {
      offset: 0,
      limit: PAGE_SIZES,
      orderBy: 'code',
      filterGroups: null
    },
    hideLoading: true
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case MEDIAS_SELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = true;
              return data;
            })
          }
        }
      case MEDIAS_UNSELECT_ALL:
        return {
          ...state,
          paging: {
            ...state.paging,
            records: state.paging.records.map(data => {
              data.selected = false;
              return data;
            })
          }
        }
      case MEDIA_SELECTED:
        return {
          ...state,
          selectedMedia: action.payload,
          selectedMediaId: action.payload.id
        };
      case MEDIAS_LOAD:
        return {
          ...state,
          filter: action.payload ? action.payload : state.filter
        };
      case MEDIAS_LOAD_SUCCESS:
        return {
          ...state,
          paging: action.payload
        };
      case MEDIAS_LOAD_FAILED:
        return {
          ...state
        };
      case MEDIA_LOAD:
        return {
          ...state
        };
      case MEDIA_LOAD_SUCCESS:
        return {
          ...state
        };
      case MEDIA_LOAD_FAILED:
        return {
          ...state
        };
      case MEDIA_ADD:
        return {
          ...state,
          hideLoading: false
        };
      case MEDIA_ADD_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case MEDIA_ADD_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case MEDIA_UPDATE:
        return {
          ...state,
          hideLoading: false
        };
      case MEDIA_UPDATE_SUCCESS:
        return {
          ...state,
          hideLoading: true
        };
      case MEDIA_UPDATE_FAILED:
        return {
          ...state,
          hideLoading: true
        };
      case MEDIA_DELETE:
        return {
          ...state
        };
      case MEDIA_DELETE_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case MEDIA_DELETE_FAILED:
        return {
          ...state
        };
      case MEDIA_DELETE_ALL:
        return {
          ...state
        };
      case MEDIA_DELETE_ALL_SUCCESS:
        return {
          ...state,
          message: action.message
        };
      case MEDIA_DELETE_ALL_FAILED:
        return {
          ...state
        };
      default:
        return state;
    }
  }
  