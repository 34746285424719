import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSamples as loadSamplesAction,
  loadSamplesSuccess,
  addSampleSuccess,
  updateSampleSuccess
} from '../actions/Sample';

import {
  SAMPLES_LOAD,
  SAMPLE_ADD,
  SAMPLE_UPDATE,
  SAMPLE_DELETE,
  SAMPLE_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSampleById = (id, callback) => {
  Api().get('sample/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllSamples = (callback) => {
  Api().post('sample/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadSamplesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`sample/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`sample`, { params })
    .then(data => data)
    .catch(error => error);
}


const addSampleRequest = async (payload) =>
  await Api().post(`sample`, payload)
    .then(data => data)
    .catch(error => error);

const updateSampleRequest = async (payload) =>
  await Api().patch(`sample/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSampleRequest = async (id) =>
  await Api().delete(`sample/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadSamples({ payload }) {
  try {
    const data = yield call(loadSamplesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSamplesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSamples() {
  yield takeEvery(SAMPLES_LOAD, loadSamples);
}

function* loadSample({ selectedSampleId }) {
  try {
    const data = yield call(loadSamplesRequest, selectedSampleId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSamplesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSample() {
  yield takeEvery(SAMPLES_LOAD, loadSample);
}


function* addSample({ payload }) {
  try {
    const data = yield call(addSampleRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addSampleSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSample() {
  yield takeEvery(SAMPLE_ADD, addSample);
}


function* updateSample({ payload }) {
  try {
    const data = yield call(updateSampleRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateSampleSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSample() {
  yield takeEvery(SAMPLE_UPDATE, updateSample);
}

function* deleteSample({ payload }) {
  try {
    const data = yield call(deleteSampleRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSamplesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSamples({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSampleRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadSamplesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSample() {
  yield takeEvery(SAMPLE_DELETE, deleteSample);
}

function* processDeleteAllSamples() {
  yield takeEvery(SAMPLE_DELETE_ALL, deleteAllSamples);
}

export default function* SampleSagas() {
  yield all([fork(processLoadSamples),
  fork(processLoadSample),
  fork(processAddSample),
  fork(processUpdateSample),
  fork(processDeleteAllSamples),
  fork(processDeleteSample)]);
}
