// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE';
export const SAVE_EMPLOYEE_SUCCESS = 'SAVE_EMPLOYEE_SUCCESS';
export const DIRECT_DATA = 'DIRECT_DATA';
export const CLEAR_DIRECT_DATA = 'CLEAR_DIRECT_DATA';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

// Setting const
export const SETTINGS_SELECT_ALL = 'SETTINGS_SELECT_ALL';
export const SETTINGS_UNSELECT_ALL = 'SETTINGS_UNSELECT_ALL';
export const SETTING_SELECTED = 'SETTING_SELECTED';
export const SETTING_LOAD = 'SETTING_LOAD';
export const SETTING_LOAD_SUCCESS = 'SETTING_LOAD_SUCCESS';
export const SETTING_LOAD_FAILED = 'SETTING_LOAD_FAILED';
export const SETTINGS_LOAD = 'SETTINGS_LOAD';
export const SETTINGS_LOAD_SUCCESS = 'SETTINGS_LOAD_SUCCESS';
export const SETTINGS_LOAD_FAILED = 'SETTINGS_LOAD_FAILED';
export const SETTING_ADD = 'SETTING_ADD';
export const SETTING_ADD_SUCCESS = 'SETTING_ADD_SUCCESS';
export const SETTING_ADD_FAILED = 'SETTING_ADD_FAILED';
export const SETTING_UPDATE = 'SETTING_UPDATE';
export const SETTING_UPDATE_SUCCESS = 'SETTING_UPDATE_SUCCESS';
export const SETTING_UPDATE_FAILED = 'SETTING_UPDATE_FAILED';
export const SETTING_DELETE = 'SETTING_DELETE';
export const SETTING_DELETE_SUCCESS = 'SETTING_DELETE_SUCCESS';
export const SETTING_DELETE_FAILED = 'SETTING_DELETE_FAILED';
export const SETTING_DELETE_ALL = 'SETTING_DELETE_ALL';
export const SETTING_DELETE_ALL_SUCCESS = 'SETTING_DELETE_ALL_SUCCESS';
export const SETTING_DELETE_ALL_FAILED = 'SETTING_DELETE_ALL_FAILED';
export const SETTINGS_EXPORT_EXCEL = 'SETTINGS_EXPORT_EXCEL';
export const SETTINGS_EXPORT_EXCEL_SUCCESS = 'SETTINGS_EXPORT_EXCEL_SUCCESS';
export const SETTINGS_EXPORT_EXCEL_FAILED = 'SETTINGS_EXPORT_EXCEL_FAILED';

// Company const
export const COMPANIES_SELECT_ALL = 'COMPANIES_SELECT_ALL';
export const COMPANIES_UNSELECT_ALL = 'COMPANIES_UNSELECT_ALL';
export const COMPANY_SELECTED = 'COMPANY_SELECTED';
export const COMPANY_LOAD = 'COMPANY_LOAD';
export const COMPANY_LOAD_SUCCESS = 'COMPANY_LOAD_SUCCESS';
export const COMPANY_LOAD_FAILED = 'COMPANY_LOAD_FAILED';
export const COMPANIES_LOAD = 'COMPANIES_LOAD';
export const COMPANIES_LOAD_SUCCESS = 'COMPANIES_LOAD_SUCCESS';
export const COMPANIES_LOAD_FAILED = 'COMPANIES_LOAD_FAILED';
export const COMPANY_ADD = 'COMPANY_ADD';
export const COMPANY_ADD_SUCCESS = 'COMPANY_ADD_SUCCESS';
export const COMPANY_ADD_FAILED = 'COMPANY_ADD_FAILED';
export const COMPANY_UPDATE = 'COMPANY_UPDATE';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_FAILED = 'COMPANY_UPDATE_FAILED';
export const COMPANY_DELETE = 'COMPANY_DELETE';
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_FAILED = 'COMPANY_DELETE_FAILED';
export const COMPANY_DELETE_ALL = 'COMPANY_DELETE_ALL';
export const COMPANY_DELETE_ALL_SUCCESS = 'COMPANY_DELETE_ALL_SUCCESS';
export const COMPANY_DELETE_ALL_FAILED = 'COMPANY_DELETE_ALL_FAILED';
export const COMPANIES_EXPORT_EXCEL = 'COMPANIES_EXPORT_EXCEL';
export const COMPANIES_EXPORT_EXCEL_SUCCESS = 'COMPANIES_EXPORT_EXCEL_SUCCESS';
export const COMPANIES_EXPORT_EXCEL_FAILED = 'COMPANIES_EXPORT_EXCEL_FAILED';

// Department const
export const DEPARTMENTS_SELECT_ALL = 'DEPARTMENTS_SELECT_ALL';
export const DEPARTMENTS_UNSELECT_ALL = 'DEPARTMENTS_UNSELECT_ALL';
export const DEPARTMENT_SELECTED = 'DEPARTMENT_SELECTED';
export const DEPARTMENT_LOAD = 'DEPARTMENT_LOAD';
export const DEPARTMENT_LOAD_SUCCESS = 'DEPARTMENT_LOAD_SUCCESS';
export const DEPARTMENT_LOAD_FAILED = 'DEPARTMENT_LOAD_FAILED';
export const DEPARTMENTS_LOAD = 'DEPARTMENTS_LOAD';
export const DEPARTMENTS_LOAD_SUCCESS = 'DEPARTMENTS_LOAD_SUCCESS';
export const DEPARTMENTS_LOAD_FAILED = 'DEPARTMENTS_LOAD_FAILED';
export const DEPARTMENT_ADD = 'DEPARTMENT_ADD';
export const DEPARTMENT_ADD_SUCCESS = 'DEPARTMENT_ADD_SUCCESS';
export const DEPARTMENT_ADD_FAILED = 'DEPARTMENT_ADD_FAILED';
export const DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE';
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS';
export const DEPARTMENT_UPDATE_FAILED = 'DEPARTMENT_UPDATE_FAILED';
export const DEPARTMENT_DELETE = 'DEPARTMENT_DELETE';
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS';
export const DEPARTMENT_DELETE_FAILED = 'DEPARTMENT_DELETE_FAILED';
export const DEPARTMENT_DELETE_ALL = 'DEPARTMENT_DELETE_ALL';
export const DEPARTMENT_DELETE_ALL_SUCCESS = 'DEPARTMENT_DELETE_ALL_SUCCESS';
export const DEPARTMENT_DELETE_ALL_FAILED = 'DEPARTMENT_DELETE_ALL_FAILED';
export const DEPARTMENTS_EXPORT_EXCEL = 'DEPARTMENTS_EXPORT_EXCEL';
export const DEPARTMENTS_EXPORT_EXCEL_SUCCESS = 'DEPARTMENTS_EXPORT_EXCEL_SUCCESS';
export const DEPARTMENTS_EXPORT_EXCEL_FAILED = 'DEPARTMENTS_EXPORT_EXCEL_FAILED';

// Employee const
export const EMPLOYEES_SELECT_ALL = 'EMPLOYEES_SELECT_ALL';
export const EMPLOYEES_UNSELECT_ALL = 'EMPLOYEES_UNSELECT_ALL';
export const EMPLOYEE_SELECTED = 'EMPLOYEE_SELECTED';
export const EMPLOYEE_LOAD = 'EMPLOYEE_LOAD';
export const EMPLOYEE_LOAD_SUCCESS = 'EMPLOYEE_LOAD_SUCCESS';
export const EMPLOYEE_LOAD_FAILED = 'EMPLOYEE_LOAD_FAILED';
export const EMPLOYEES_LOAD = 'EMPLOYEES_LOAD';
export const EMPLOYEES_LOAD_SUCCESS = 'EMPLOYEES_LOAD_SUCCESS';
export const EMPLOYEES_LOAD_FAILED = 'EMPLOYEES_LOAD_FAILED';
export const EMPLOYEE_ADD = 'EMPLOYEE_ADD';
export const EMPLOYEE_ADD_SUCCESS = 'EMPLOYEE_ADD_SUCCESS';
export const EMPLOYEE_ADD_FAILED = 'EMPLOYEE_ADD_FAILED';
export const EMPLOYEE_UPDATE = 'EMPLOYEE_UPDATE';
export const EMPLOYEE_UPDATE_SUCCESS = 'EMPLOYEE_UPDATE_SUCCESS';
export const EMPLOYEE_UPDATE_FAILED = 'EMPLOYEE_UPDATE_FAILED';
export const EMPLOYEE_DELETE = 'EMPLOYEE_DELETE';
export const EMPLOYEE_DELETE_SUCCESS = 'EMPLOYEE_DELETE_SUCCESS';
export const EMPLOYEE_DELETE_FAILED = 'EMPLOYEE_DELETE_FAILED';
export const EMPLOYEE_DELETE_ALL = 'EMPLOYEE_DELETE_ALL';
export const EMPLOYEE_DELETE_ALL_SUCCESS = 'EMPLOYEE_DELETE_ALL_SUCCESS';
export const EMPLOYEE_DELETE_ALL_FAILED = 'EMPLOYEE_DELETE_ALL_FAILED';
export const EMPLOYEES_EXPORT_EXCEL = 'EMPLOYEES_EXPORT_EXCEL';
export const EMPLOYEES_EXPORT_EXCEL_SUCCESS = 'EMPLOYEES_EXPORT_EXCEL_SUCCESS';
export const EMPLOYEES_EXPORT_EXCEL_FAILED = 'EMPLOYEES_EXPORT_EXCEL_FAILED';

// Personal paper const
export const PERSONALPAPERS_SELECT_ALL = 'PERSONALPAPERS_SELECT_ALL';
export const PERSONALPAPERS_UNSELECT_ALL = 'PERSONALPAPERS_UNSELECT_ALL';
export const PERSONALPAPER_SELECTED = 'PERSONALPAPER_SELECTED';
export const PERSONALPAPER_LOAD = 'PERSONALPAPER_LOAD';
export const PERSONALPAPER_LOAD_SUCCESS = 'PERSONALPAPER_LOAD_SUCCESS';
export const PERSONALPAPER_LOAD_FAILED = 'PERSONALPAPER_LOAD_FAILED';
export const PERSONALPAPERS_LOAD = 'PERSONALPAPERS_LOAD';
export const PERSONALPAPERS_LOAD_SUCCESS = 'PERSONALPAPERS_LOAD_SUCCESS';
export const PERSONALPAPERS_LOAD_FAILED = 'PERSONALPAPERS_LOAD_FAILED';
export const PERSONALPAPER_ADD = 'PERSONALPAPER_ADD';
export const PERSONALPAPER_ADD_SUCCESS = 'PERSONALPAPER_ADD_SUCCESS';
export const PERSONALPAPER_ADD_FAILED = 'PERSONALPAPER_ADD_FAILED';
export const PERSONALPAPER_UPDATE = 'PERSONALPAPER_UPDATE';
export const PERSONALPAPER_UPDATE_SUCCESS = 'PERSONALPAPER_UPDATE_SUCCESS';
export const PERSONALPAPER_UPDATE_FAILED = 'PERSONALPAPER_UPDATE_FAILED';
export const PERSONALPAPER_DELETE = 'PERSONALPAPER_DELETE';
export const PERSONALPAPER_DELETE_SUCCESS = 'PERSONALPAPER_DELETE_SUCCESS';
export const PERSONALPAPER_DELETE_FAILED = 'PERSONALPAPER_DELETE_FAILED';
export const PERSONALPAPER_DELETE_ALL = 'PERSONALPAPER_DELETE_ALL';
export const PERSONALPAPER_DELETE_ALL_SUCCESS = 'PERSONALPAPER_DELETE_ALL_SUCCESS';
export const PERSONALPAPER_DELETE_ALL_FAILED = 'PERSONALPAPER_DELETE_ALL_FAILED';
export const PERSONALPAPERS_EXPORT_EXCEL = 'PERSONALPAPERS_EXPORT_EXCEL';
export const PERSONALPAPERS_EXPORT_EXCEL_SUCCESS = 'PERSONALPAPERS_EXPORT_EXCEL_SUCCESS';
export const PERSONALPAPERS_EXPORT_EXCEL_FAILED = 'PERSONALPAPERS_EXPORT_EXCEL_FAILED';

// Work location const
export const WORKLOCATIONS_SELECT_ALL = 'WORKLOCATIONS_SELECT_ALL';
export const WORKLOCATIONS_UNSELECT_ALL = 'WORKLOCATIONS_UNSELECT_ALL';
export const WORKLOCATION_SELECTED = 'WORKLOCATION_SELECTED';
export const WORKLOCATION_LOAD = 'WORKLOCATION_LOAD';
export const WORKLOCATION_LOAD_SUCCESS = 'WORKLOCATION_LOAD_SUCCESS';
export const WORKLOCATION_LOAD_FAILED = 'WORKLOCATION_LOAD_FAILED';
export const WORKLOCATIONS_LOAD = 'WORKLOCATIONS_LOAD';
export const WORKLOCATIONS_LOAD_SUCCESS = 'WORKLOCATIONS_LOAD_SUCCESS';
export const WORKLOCATIONS_LOAD_FAILED = 'WORKLOCATIONS_LOAD_FAILED';
export const WORKLOCATION_ADD = 'WORKLOCATION_ADD';
export const WORKLOCATION_ADD_SUCCESS = 'WORKLOCATION_ADD_SUCCESS';
export const WORKLOCATION_ADD_FAILED = 'WORKLOCATION_ADD_FAILED';
export const WORKLOCATION_UPDATE = 'WORKLOCATION_UPDATE';
export const WORKLOCATION_UPDATE_SUCCESS = 'WORKLOCATION_UPDATE_SUCCESS';
export const WORKLOCATION_UPDATE_FAILED = 'WORKLOCATION_UPDATE_FAILED';
export const WORKLOCATION_DELETE = 'WORKLOCATION_DELETE';
export const WORKLOCATION_DELETE_SUCCESS = 'WORKLOCATION_DELETE_SUCCESS';
export const WORKLOCATION_DELETE_FAILED = 'WORKLOCATION_DELETE_FAILED';
export const WORKLOCATION_DELETE_ALL = 'WORKLOCATION_DELETE_ALL';
export const WORKLOCATION_DELETE_ALL_SUCCESS = 'WORKLOCATION_DELETE_ALL_SUCCESS';
export const WORKLOCATION_DELETE_ALL_FAILED = 'WORKLOCATION_DELETE_ALL_FAILED';
export const WORKLOCATIONS_EXPORT_EXCEL = 'WORKLOCATIONS_EXPORT_EXCEL';
export const WORKLOCATIONS_EXPORT_EXCEL_SUCCESS = 'WORKLOCATIONS_EXPORT_EXCEL_SUCCESS';
export const WORKLOCATIONS_EXPORT_EXCEL_FAILED = 'WORKLOCATIONS_EXPORT_EXCEL_FAILED';

// Leave Track const
export const LEAVETRACKS_SELECT_ALL = 'LEAVETRACKS_SELECT_ALL';
export const LEAVETRACKS_UNSELECT_ALL = 'LEAVETRACKS_UNSELECT_ALL';
export const LEAVETRACK_SELECTED = 'LEAVETRACK_SELECTED';
export const LEAVETRACK_LOAD = 'LEAVETRACK_LOAD';
export const LEAVETRACK_LOAD_SUCCESS = 'LEAVETRACK_LOAD_SUCCESS';
export const LEAVETRACK_LOAD_FAILED = 'LEAVETRACK_LOAD_FAILED';
export const LEAVETRACKS_LOAD = 'LEAVETRACKS_LOAD';
export const LEAVETRACKS_LOAD_SUCCESS = 'LEAVETRACKS_LOAD_SUCCESS';
export const LEAVETRACKS_LOAD_FAILED = 'LEAVETRACKS_LOAD_FAILED';
export const LEAVETRACK_ADD = 'LEAVETRACK_ADD';
export const LEAVETRACK_ADD_SUCCESS = 'LEAVETRACK_ADD_SUCCESS';
export const LEAVETRACK_ADD_FAILED = 'LEAVETRACK_ADD_FAILED';
export const LEAVETRACK_UPDATE = 'LEAVETRACK_UPDATE';
export const LEAVETRACK_UPDATE_SUCCESS = 'LEAVETRACK_UPDATE_SUCCESS';
export const LEAVETRACK_UPDATE_FAILED = 'LEAVETRACK_UPDATE_FAILED';
export const LEAVETRACK_DELETE = 'LEAVETRACK_DELETE';
export const LEAVETRACK_DELETE_SUCCESS = 'LEAVETRACK_DELETE_SUCCESS';
export const LEAVETRACK_DELETE_FAILED = 'LEAVETRACK_DELETE_FAILED';
export const LEAVETRACK_DELETE_ALL = 'LEAVETRACK_DELETE_ALL';
export const LEAVETRACK_DELETE_ALL_SUCCESS = 'LEAVETRACK_DELETE_ALL_SUCCESS';
export const LEAVETRACK_DELETE_ALL_FAILED = 'LEAVETRACK_DELETE_ALL_FAILED';
export const LEAVETRACKS_EXPORT_EXCEL = 'LEAVETRACKS_EXPORT_EXCEL';
export const LEAVETRACKS_EXPORT_EXCEL_SUCCESS = 'LEAVETRACKS_EXPORT_EXCEL_SUCCESS';
export const LEAVETRACKS_EXPORT_EXCEL_FAILED = 'LEAVETRACKS_EXPORT_EXCEL_FAILED';

// Leave Track History const
export const LEAVETRACKHISTORIES_SELECT_ALL = 'LEAVETRACKHISTORIES_SELECT_ALL';
export const LEAVETRACKHISTORIES_UNSELECT_ALL = 'LEAVETRACKHISTORIES_UNSELECT_ALL';
export const LEAVETRACKHISTORY_SELECTED = 'LEAVETRACKHISTORY_SELECTED';
export const LEAVETRACKHISTORY_LOAD = 'LEAVETRACKHISTORY_LOAD';
export const LEAVETRACKHISTORY_LOAD_SUCCESS = 'LEAVETRACKHISTORY_LOAD_SUCCESS';
export const LEAVETRACKHISTORY_LOAD_FAILED = 'LEAVETRACKHISTORY_LOAD_FAILED';
export const LEAVETRACKHISTORIES_LOAD = 'LEAVETRACKHISTORIES_LOAD';
export const LEAVETRACKHISTORIES_LOAD_SUCCESS = 'LEAVETRACKHISTORIES_LOAD_SUCCESS';
export const LEAVETRACKHISTORIES_LOAD_FAILED = 'LEAVETRACKHISTORIES_LOAD_FAILED';
export const LEAVETRACKHISTORY_ADD = 'LEAVETRACKHISTORY_ADD';
export const LEAVETRACKHISTORY_ADD_SUCCESS = 'LEAVETRACKHISTORY_ADD_SUCCESS';
export const LEAVETRACKHISTORY_ADD_FAILED = 'LEAVETRACKHISTORY_ADD_FAILED';
export const LEAVETRACKHISTORY_UPDATE = 'LEAVETRACKHISTORY_UPDATE';
export const LEAVETRACKHISTORY_UPDATE_SUCCESS = 'LEAVETRACKHISTORY_UPDATE_SUCCESS';
export const LEAVETRACKHISTORY_UPDATE_FAILED = 'LEAVETRACKHISTORY_UPDATE_FAILED';
export const LEAVETRACKHISTORY_DELETE = 'LEAVETRACKHISTORY_DELETE';
export const LEAVETRACKHISTORY_DELETE_SUCCESS = 'LEAVETRACKHISTORY_DELETE_SUCCESS';
export const LEAVETRACKHISTORY_DELETE_FAILED = 'LEAVETRACKHISTORY_DELETE_FAILED';
export const LEAVETRACKHISTORY_DELETE_ALL = 'LEAVETRACKHISTORY_DELETE_ALL';
export const LEAVETRACKHISTORY_DELETE_ALL_SUCCESS = 'LEAVETRACKHISTORY_DELETE_ALL_SUCCESS';
export const LEAVETRACKHISTORY_DELETE_ALL_FAILED = 'LEAVETRACKHISTORY_DELETE_ALL_FAILED';
export const LEAVETRACKHISTORIES_EXPORT_EXCEL = 'LEAVETRACKHISTORIES_EXPORT_EXCEL';
export const LEAVETRACKHISTORIES_EXPORT_EXCEL_SUCCESS = 'LEAVETRACKHISTORIES_EXPORT_EXCEL_SUCCESS';
export const LEAVETRACKHISTORIES_EXPORT_EXCEL_FAILED = 'LEAVETRACKHISTORIES_EXPORT_EXCEL_FAILED';

// Customer const
export const CUSTOMERS_SELECT_ALL = 'CUSTOMERS_SELECT_ALL';
export const CUSTOMERS_UNSELECT_ALL = 'CUSTOMERS_UNSELECT_ALL';
export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED';
export const CUSTOMER_LOAD = 'CUSTOMER_LOAD';
export const CUSTOMER_LOAD_SUCCESS = 'CUSTOMER_LOAD_SUCCESS';
export const CUSTOMER_LOAD_FAILED = 'CUSTOMER_LOAD_FAILED';
export const CUSTOMERS_LOAD = 'CUSTOMERS_LOAD';
export const CUSTOMERS_LOAD_SUCCESS = 'CUSTOMERS_LOAD_SUCCESS';
export const CUSTOMERS_LOAD_FAILED = 'CUSTOMERS_LOAD_FAILED';
export const CUSTOMER_ADD = 'CUSTOMER_ADD';
export const CUSTOMER_ADD_SUCCESS = 'CUSTOMER_ADD_SUCCESS';
export const CUSTOMER_ADD_FAILED = 'CUSTOMER_ADD_FAILED';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_FAILED = 'CUSTOMER_UPDATE_FAILED';
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS';
export const CUSTOMER_DELETE_FAILED = 'CUSTOMER_DELETE_FAILED';
export const CUSTOMER_DELETE_ALL = 'CUSTOMER_DELETE_ALL';
export const CUSTOMER_DELETE_ALL_SUCCESS = 'CUSTOMER_DELETE_ALL_SUCCESS';
export const CUSTOMER_DELETE_ALL_FAILED = 'CUSTOMER_DELETE_ALL_FAILED';
export const CUSTOMERS_EXPORT_EXCEL = 'CUSTOMERS_EXPORT_EXCEL';
export const CUSTOMERS_EXPORT_EXCEL_SUCCESS = 'CUSTOMERS_EXPORT_EXCEL_SUCCESS';
export const CUSTOMERS_EXPORT_EXCEL_FAILED = 'CUSTOMERS_EXPORT_EXCEL_FAILED';

// Payment const
export const PAYMENTS_SELECT_ALL = 'PAYMENTS_SELECT_ALL';
export const PAYMENTS_UNSELECT_ALL = 'PAYMENTS_UNSELECT_ALL';
export const PAYMENT_SELECTED = 'PAYMENT_SELECTED';
export const PAYMENT_LOAD = 'PAYMENT_LOAD';
export const PAYMENT_LOAD_SUCCESS = 'PAYMENT_LOAD_SUCCESS';
export const PAYMENT_LOAD_FAILED = 'PAYMENT_LOAD_FAILED';
export const PAYMENTS_LOAD = 'PAYMENTS_LOAD';
export const PAYMENTS_LOAD_SUCCESS = 'PAYMENTS_LOAD_SUCCESS';
export const PAYMENTS_LOAD_FAILED = 'PAYMENTS_LOAD_FAILED';
export const PAYMENT_ADD = 'PAYMENT_ADD';
export const PAYMENT_ADD_SUCCESS = 'PAYMENT_ADD_SUCCESS';
export const PAYMENT_ADD_FAILED = 'PAYMENT_ADD_FAILED';
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE';
export const PAYMENT_UPDATE_SUCCESS = 'PAYMENT_UPDATE_SUCCESS';
export const PAYMENT_UPDATE_FAILED = 'PAYMENT_UPDATE_FAILED';
export const PAYMENT_DELETE = 'PAYMENT_DELETE';
export const PAYMENT_DELETE_SUCCESS = 'PAYMENT_DELETE_SUCCESS';
export const PAYMENT_DELETE_FAILED = 'PAYMENT_DELETE_FAILED';
export const PAYMENT_DELETE_ALL = 'PAYMENT_DELETE_ALL';
export const PAYMENT_DELETE_ALL_SUCCESS = 'PAYMENT_DELETE_ALL_SUCCESS';
export const PAYMENT_DELETE_ALL_FAILED = 'PAYMENT_DELETE_ALL_FAILED';
export const PAYMENTS_EXPORT_EXCEL = 'PAYMENTS_EXPORT_EXCEL';
export const PAYMENTS_EXPORT_EXCEL_SUCCESS = 'PAYMENTS_EXPORT_EXCEL_SUCCESS';
export const PAYMENTS_EXPORT_EXCEL_FAILED = 'PAYMENTS_EXPORT_EXCEL_FAILED';

// Contract const
export const CONTRACTS_SELECT_ALL = 'CONTRACTS_SELECT_ALL';
export const CONTRACTS_UNSELECT_ALL = 'CONTRACTS_UNSELECT_ALL';
export const CONTRACT_SELECTED = 'CONTRACT_SELECTED';
export const CONTRACT_LOAD = 'CONTRACT_LOAD';
export const CONTRACT_LOAD_SUCCESS = 'CONTRACT_LOAD_SUCCESS';
export const CONTRACT_LOAD_FAILED = 'CONTRACT_LOAD_FAILED';
export const CONTRACTS_LOAD = 'CONTRACTS_LOAD';
export const CONTRACTS_LOAD_SUCCESS = 'CONTRACTS_LOAD_SUCCESS';
export const CONTRACTS_LOAD_FAILED = 'CONTRACTS_LOAD_FAILED';
export const CONTRACT_ADD = 'CONTRACT_ADD';
export const CONTRACT_ADD_SUCCESS = 'CONTRACT_ADD_SUCCESS';
export const CONTRACT_ADD_FAILED = 'CONTRACT_ADD_FAILED';
export const CONTRACT_UPDATE = 'CONTRACT_UPDATE';
export const CONTRACT_UPDATE_SUCCESS = 'CONTRACT_UPDATE_SUCCESS';
export const CONTRACT_UPDATE_FAILED = 'CONTRACT_UPDATE_FAILED';
export const CONTRACT_DELETE = 'CONTRACT_DELETE';
export const CONTRACT_DELETE_SUCCESS = 'CONTRACT_DELETE_SUCCESS';
export const CONTRACT_DELETE_FAILED = 'CONTRACT_DELETE_FAILED';
export const CONTRACT_DELETE_ALL = 'CONTRACT_DELETE_ALL';
export const CONTRACT_DELETE_ALL_SUCCESS = 'CONTRACT_DELETE_ALL_SUCCESS';
export const CONTRACT_DELETE_ALL_FAILED = 'CONTRACT_DELETE_ALL_FAILED';
export const CONTRACTS_EXPORT_EXCEL = 'CONTRACTS_EXPORT_EXCEL';
export const CONTRACTS_EXPORT_EXCEL_SUCCESS = 'CONTRACTS_EXPORT_EXCEL_SUCCESS';
export const CONTRACTS_EXPORT_EXCEL_FAILED = 'CONTRACTS_EXPORT_EXCEL_FAILED';

// Quotation const
export const QUOTATIONS_SELECT_ALL = 'QUOTATIONS_SELECT_ALL';
export const QUOTATIONS_UNSELECT_ALL = 'QUOTATIONS_UNSELECT_ALL';
export const QUOTATION_SELECTED = 'QUOTATION_SELECTED';
export const QUOTATION_LOAD = 'QUOTATION_LOAD';
export const QUOTATION_LOAD_SUCCESS = 'QUOTATION_LOAD_SUCCESS';
export const QUOTATION_LOAD_FAILED = 'QUOTATION_LOAD_FAILED';
export const QUOTATIONS_LOAD = 'QUOTATIONS_LOAD';
export const QUOTATIONS_LOAD_SUCCESS = 'QUOTATIONS_LOAD_SUCCESS';
export const QUOTATIONS_LOAD_FAILED = 'QUOTATIONS_LOAD_FAILED';
export const QUOTATION_ADD = 'QUOTATION_ADD';
export const QUOTATION_ADD_SUCCESS = 'QUOTATION_ADD_SUCCESS';
export const QUOTATION_ADD_FAILED = 'QUOTATION_ADD_FAILED';
export const QUOTATION_UPDATE = 'QUOTATION_UPDATE';
export const QUOTATION_UPDATE_SUCCESS = 'QUOTATION_UPDATE_SUCCESS';
export const QUOTATION_UPDATE_FAILED = 'QUOTATION_UPDATE_FAILED';
export const QUOTATION_DELETE = 'QUOTATION_DELETE';
export const QUOTATION_DELETE_SUCCESS = 'QUOTATION_DELETE_SUCCESS';
export const QUOTATION_DELETE_FAILED = 'QUOTATION_DELETE_FAILED';
export const QUOTATION_DELETE_ALL = 'QUOTATION_DELETE_ALL';
export const QUOTATION_DELETE_ALL_SUCCESS = 'QUOTATION_DELETE_ALL_SUCCESS';
export const QUOTATION_DELETE_ALL_FAILED = 'QUOTATION_DELETE_ALL_FAILED';
export const QUOTATIONS_EXPORT_EXCEL = 'QUOTATIONS_EXPORT_EXCEL';
export const QUOTATIONS_EXPORT_EXCEL_SUCCESS = 'QUOTATIONS_EXPORT_EXCEL_SUCCESS';
export const QUOTATIONS_EXPORT_EXCEL_FAILED = 'QUOTATIONS_EXPORT_EXCEL_FAILED';

// Role const
export const ROLES_SELECT_ALL = 'ROLES_SELECT_ALL';
export const ROLES_UNSELECT_ALL = 'ROLES_UNSELECT_ALL';
export const ROLE_SELECTED = 'ROLE_SELECTED';
export const ROLE_LOAD = 'ROLE_LOAD';
export const ROLE_LOAD_SUCCESS = 'ROLE_LOAD_SUCCESS';
export const ROLE_LOAD_FAILED = 'ROLE_LOAD_FAILED';
export const ROLES_LOAD = 'ROLES_LOAD';
export const ROLES_LOAD_SUCCESS = 'ROLES_LOAD_SUCCESS';
export const ROLES_LOAD_FAILED = 'ROLES_LOAD_FAILED';
export const ROLE_ADD = 'ROLE_ADD';
export const ROLE_ADD_SUCCESS = 'ROLE_ADD_SUCCESS';
export const ROLE_ADD_FAILED = 'ROLE_ADD_FAILED';
export const ROLE_UPDATE = 'ROLE_UPDATE';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';
export const ROLE_UPDATE_FAILED = 'ROLE_UPDATE_FAILED';
export const ROLE_DELETE = 'ROLE_DELETE';
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS';
export const ROLE_DELETE_FAILED = 'ROLE_DELETE_FAILED';
export const ROLE_DELETE_ALL = 'ROLE_DELETE_ALL';
export const ROLE_DELETE_ALL_SUCCESS = 'ROLE_DELETE_ALL_SUCCESS';
export const ROLE_DELETE_ALL_FAILED = 'ROLE_DELETE_ALL_FAILED';
export const ROLES_EXPORT_EXCEL = 'ROLES_EXPORT_EXCEL';
export const ROLES_EXPORT_EXCEL_SUCCESS = 'ROLES_EXPORT_EXCEL_SUCCESS';
export const ROLES_EXPORT_EXCEL_FAILED = 'ROLES_EXPORT_EXCEL_FAILED';

// User const
export const USER_SEARCH = 'USER_SEARCH';
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS';
export const USER_ASSIGN_ROLES = 'USER_ASSIGN_ROLES';
export const USER_ASSIGN_ROLES_SUCCESS = 'USER_ASSIGN_ROLES_SUCCESS';
export const USER_ASSIGN_EMPLOYEE = 'USER_ASSIGN_EMPLOYEE';
export const USER_ASSIGN_EMPLOYEE_SUCCESS = 'USER_ASSIGN_EMPLOYEE_SUCCESS';
export const USER_LOCK = 'USER_LOCK';
export const USER_LOCK_SUCCESS = 'USER_LOCK_SUCCESS';
export const USER_UNLOCK = 'USER_UNLOCK';
export const USER_UNLOCK_SUCCESS = 'USER_UNLOCK_SUCCESS';
export const USER_CHANGE_LOCK_RELOAD = 'USER_CHANGE_LOCK_RELOAD';

// Job const
export const JOBS_SELECT_ALL = 'JOBS_SELECT_ALL';
export const JOBS_UNSELECT_ALL = 'JOBS_UNSELECT_ALL';
export const JOB_SELECTED = 'JOB_SELECTED';
export const JOB_LOAD = 'JOB_LOAD';
export const JOB_LOAD_SUCCESS = 'JOB_LOAD_SUCCESS';
export const JOB_LOAD_FAILED = 'JOB_LOAD_FAILED';
export const JOBS_LOAD = 'JOBS_LOAD';
export const JOBS_LOAD_SUCCESS = 'JOBS_LOAD_SUCCESS';
export const JOBS_LOAD_FAILED = 'JOBS_LOAD_FAILED';
export const JOB_ADD = 'JOB_ADD';
export const JOB_ADD_SUCCESS = 'JOB_ADD_SUCCESS';
export const JOB_ADD_FAILED = 'JOB_ADD_FAILED';
export const JOB_UPDATE = 'JOB_UPDATE';
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS';
export const JOB_UPDATE_FAILED = 'JOB_UPDATE_FAILED';
export const JOB_DELETE = 'JOB_DELETE';
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS';
export const JOB_DELETE_FAILED = 'JOB_DELETE_FAILED';
export const JOB_DELETE_ALL = 'JOB_DELETE_ALL';
export const JOB_DELETE_ALL_SUCCESS = 'JOB_DELETE_ALL_SUCCESS';
export const JOB_DELETE_ALL_FAILED = 'JOB_DELETE_ALL_FAILED';
export const JOBS_EXPORT_EXCEL = 'JOBS_EXPORT_EXCEL';
export const JOBS_EXPORT_EXCEL_SUCCESS = 'JOBS_EXPORT_EXCEL_SUCCESS';
export const JOBS_EXPORT_EXCEL_FAILED = 'JOBS_EXPORT_EXCEL_FAILED';

// Mail const
export const MAILS_SELECT_ALL = 'MAILS_SELECT_ALL';
export const MAILS_UNSELECT_ALL = 'MAILS_UNSELECT_ALL';
export const MAIL_SELECTED = 'MAIL_SELECTED';
export const MAIL_LOAD = 'MAIL_LOAD';
export const MAIL_LOAD_SUCCESS = 'MAIL_LOAD_SUCCESS';
export const MAIL_LOAD_FAILED = 'MAIL_LOAD_FAILED';
export const MAILS_LOAD = 'MAILS_LOAD';
export const MAILS_LOAD_SUCCESS = 'MAILS_LOAD_SUCCESS';
export const MAILS_LOAD_FAILED = 'MAILS_LOAD_FAILED';
export const MAIL_ADD = 'MAIL_ADD';
export const MAIL_ADD_SUCCESS = 'MAIL_ADD_SUCCESS';
export const MAIL_ADD_FAILED = 'MAIL_ADD_FAILED';
export const MAIL_UPDATE = 'MAIL_UPDATE';
export const MAIL_UPDATE_SUCCESS = 'MAIL_UPDATE_SUCCESS';
export const MAIL_UPDATE_FAILED = 'MAIL_UPDATE_FAILED';
export const MAIL_DELETE = 'MAIL_DELETE';
export const MAIL_DELETE_SUCCESS = 'MAIL_DELETE_SUCCESS';
export const MAIL_DELETE_FAILED = 'MAIL_DELETE_FAILED';
export const MAIL_DELETE_ALL = 'MAIL_DELETE_ALL';
export const MAIL_DELETE_ALL_SUCCESS = 'MAIL_DELETE_ALL_SUCCESS';
export const MAIL_DELETE_ALL_FAILED = 'MAIL_DELETE_ALL_FAILED';
export const MAILS_EXPORT_EXCEL = 'MAILS_EXPORT_EXCEL';
export const MAILS_EXPORT_EXCEL_SUCCESS = 'MAILS_EXPORT_EXCEL_SUCCESS';
export const MAILS_EXPORT_EXCEL_FAILED = 'MAILS_EXPORT_EXCEL_FAILED';

// MailToUser const
export const MAILTOUSERS_SELECT_ALL = 'MAILTOUSERS_SELECT_ALL';
export const MAILTOUSERS_UNSELECT_ALL = 'MAILTOUSERS_UNSELECT_ALL';
export const MAILTOUSER_SELECTED = 'MAILTOUSER_SELECTED';
export const MAILTOUSER_LOAD = 'MAILTOUSER_LOAD';
export const MAILTOUSER_LOAD_SUCCESS = 'MAILTOUSER_LOAD_SUCCESS';
export const MAILTOUSER_LOAD_FAILED = 'MAILTOUSER_LOAD_FAILED';
export const MAILTOUSERS_LOAD = 'MAILTOUSERS_LOAD';
export const MAILTOUSERS_LOAD_SUCCESS = 'MAILTOUSERS_LOAD_SUCCESS';
export const MAILTOUSERS_LOAD_FAILED = 'MAILTOUSERS_LOAD_FAILED';
export const MAILTOUSER_ADD = 'MAILTOUSER_ADD';
export const MAILTOUSER_ADD_SUCCESS = 'MAILTOUSER_ADD_SUCCESS';
export const MAILTOUSER_ADD_FAILED = 'MAILTOUSER_ADD_FAILED';
export const MAILTOUSER_UPDATE = 'MAILTOUSER_UPDATE';
export const MAILTOUSER_UPDATE_SUCCESS = 'MAILTOUSER_UPDATE_SUCCESS';
export const MAILTOUSER_UPDATE_FAILED = 'MAILTOUSER_UPDATE_FAILED';
export const MAILTOUSER_DELETE = 'MAILTOUSER_DELETE';
export const MAILTOUSER_DELETE_SUCCESS = 'MAILTOUSER_DELETE_SUCCESS';
export const MAILTOUSER_DELETE_FAILED = 'MAILTOUSER_DELETE_FAILED';
export const MAILTOUSER_DELETE_ALL = 'MAILTOUSER_DELETE_ALL';
export const MAILTOUSER_DELETE_ALL_SUCCESS = 'MAILTOUSER_DELETE_ALL_SUCCESS';
export const MAILTOUSER_DELETE_ALL_FAILED = 'MAILTOUSER_DELETE_ALL_FAILED';
export const MAILTOUSERS_EXPORT_EXCEL = 'MAILTOUSERS_EXPORT_EXCEL';
export const MAILTOUSERS_EXPORT_EXCEL_SUCCESS = 'MAILTOUSERS_EXPORT_EXCEL_SUCCESS';
export const MAILTOUSERS_EXPORT_EXCEL_FAILED = 'MAILTOUSERS_EXPORT_EXCEL_FAILED';

// Template const
export const TEMPLATES_SELECT_ALL = 'TEMPLATES_SELECT_ALL';
export const TEMPLATES_UNSELECT_ALL = 'TEMPLATES_UNSELECT_ALL';
export const TEMPLATE_SELECTED = 'TEMPLATE_SELECTED';
export const TEMPLATE_LOAD = 'TEMPLATE_LOAD';
export const TEMPLATE_LOAD_SUCCESS = 'TEMPLATE_LOAD_SUCCESS';
export const TEMPLATE_LOAD_FAILED = 'TEMPLATE_LOAD_FAILED';
export const TEMPLATES_LOAD = 'TEMPLATES_LOAD';
export const TEMPLATES_LOAD_SUCCESS = 'TEMPLATES_LOAD_SUCCESS';
export const TEMPLATES_LOAD_FAILED = 'TEMPLATES_LOAD_FAILED';
export const TEMPLATE_ADD = 'TEMPLATE_ADD';
export const TEMPLATE_ADD_SUCCESS = 'TEMPLATE_ADD_SUCCESS';
export const TEMPLATE_ADD_FAILED = 'TEMPLATE_ADD_FAILED';
export const TEMPLATE_UPDATE = 'TEMPLATE_UPDATE';
export const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS';
export const TEMPLATE_UPDATE_FAILED = 'TEMPLATE_UPDATE_FAILED';
export const TEMPLATE_DELETE = 'TEMPLATE_DELETE';
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS';
export const TEMPLATE_DELETE_FAILED = 'TEMPLATE_DELETE_FAILED';
export const TEMPLATE_DELETE_ALL = 'TEMPLATE_DELETE_ALL';
export const TEMPLATE_DELETE_ALL_SUCCESS = 'TEMPLATE_DELETE_ALL_SUCCESS';
export const TEMPLATE_DELETE_ALL_FAILED = 'TEMPLATE_DELETE_ALL_FAILED';
export const TEMPLATES_EXPORT_EXCEL = 'TEMPLATES_EXPORT_EXCEL';
export const TEMPLATES_EXPORT_EXCEL_SUCCESS = 'TEMPLATES_EXPORT_EXCEL_SUCCESS';
export const TEMPLATES_EXPORT_EXCEL_FAILED = 'TEMPLATES_EXPORT_EXCEL_FAILED';

// Invoice const
export const INVOICES_SELECT_ALL = 'INVOICES_SELECT_ALL';
export const INVOICES_UNSELECT_ALL = 'INVOICES_UNSELECT_ALL';
export const INVOICE_SELECTED = 'INVOICE_SELECTED';
export const INVOICE_LOAD = 'INVOICE_LOAD';
export const INVOICE_LOAD_SUCCESS = 'INVOICE_LOAD_SUCCESS';
export const INVOICE_LOAD_FAILED = 'INVOICE_LOAD_FAILED';
export const INVOICES_LOAD = 'INVOICES_LOAD';
export const INVOICES_LOAD_SUCCESS = 'INVOICES_LOAD_SUCCESS';
export const INVOICES_LOAD_FAILED = 'INVOICES_LOAD_FAILED';
export const INVOICE_ADD = 'INVOICE_ADD';
export const INVOICE_ADD_SUCCESS = 'INVOICE_ADD_SUCCESS';
export const INVOICE_ADD_FAILED = 'INVOICE_ADD_FAILED';
export const INVOICE_UPDATE = 'INVOICE_UPDATE';
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS';
export const INVOICE_UPDATE_FAILED = 'INVOICE_UPDATE_FAILED';
export const INVOICE_DELETE = 'INVOICE_DELETE';
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS';
export const INVOICE_DELETE_FAILED = 'INVOICE_DELETE_FAILED';
export const INVOICE_DELETE_ALL = 'INVOICE_DELETE_ALL';
export const INVOICE_DELETE_ALL_SUCCESS = 'INVOICE_DELETE_ALL_SUCCESS';
export const INVOICE_DELETE_ALL_FAILED = 'INVOICE_DELETE_ALL_FAILED';
export const INVOICES_EXPORT_EXCEL = 'INVOICES_EXPORT_EXCEL';
export const INVOICES_EXPORT_EXCEL_SUCCESS = 'INVOICES_EXPORT_EXCEL_SUCCESS';
export const INVOICES_EXPORT_EXCEL_FAILED = 'INVOICES_EXPORT_EXCEL_FAILED';

// Serial number const
export const SERIALNUMBERS_SELECT_ALL = 'SERIALNUMBERS_SELECT_ALL';
export const SERIALNUMBERS_UNSELECT_ALL = 'SERIALNUMBERS_UNSELECT_ALL';
export const SERIALNUMBER_SELECTED = 'SERIALNUMBER_SELECTED';
export const SERIALNUMBER_LOAD = 'SERIALNUMBER_LOAD';
export const SERIALNUMBER_LOAD_SUCCESS = 'SERIALNUMBER_LOAD_SUCCESS';
export const SERIALNUMBER_LOAD_FAILED = 'SERIALNUMBER_LOAD_FAILED';
export const SERIALNUMBERS_LOAD = 'SERIALNUMBERS_LOAD';
export const SERIALNUMBERS_LOAD_SUCCESS = 'SERIALNUMBERS_LOAD_SUCCESS';
export const SERIALNUMBERS_LOAD_FAILED = 'SERIALNUMBERS_LOAD_FAILED';
export const SERIALNUMBER_ADD = 'SERIALNUMBER_ADD';
export const SERIALNUMBER_ADD_SUCCESS = 'SERIALNUMBER_ADD_SUCCESS';
export const SERIALNUMBER_ADD_FAILED = 'SERIALNUMBER_ADD_FAILED';
export const SERIALNUMBER_UPDATE = 'SERIALNUMBER_UPDATE';
export const SERIALNUMBER_UPDATE_SUCCESS = 'SERIALNUMBER_UPDATE_SUCCESS';
export const SERIALNUMBER_UPDATE_FAILED = 'SERIALNUMBER_UPDATE_FAILED';
export const SERIALNUMBER_DELETE = 'SERIALNUMBER_DELETE';
export const SERIALNUMBER_DELETE_SUCCESS = 'SERIALNUMBER_DELETE_SUCCESS';
export const SERIALNUMBER_DELETE_FAILED = 'SERIALNUMBER_DELETE_FAILED';
export const SERIALNUMBER_DELETE_ALL = 'SERIALNUMBER_DELETE_ALL';
export const SERIALNUMBER_DELETE_ALL_SUCCESS = 'SERIALNUMBER_DELETE_ALL_SUCCESS';
export const SERIALNUMBER_DELETE_ALL_FAILED = 'SERIALNUMBER_DELETE_ALL_FAILED';
export const SERIALNUMBERS_EXPORT_EXCEL = 'SERIALNUMBERS_EXPORT_EXCEL';
export const SERIALNUMBERS_EXPORT_EXCEL_SUCCESS = 'SERIALNUMBERS_EXPORT_EXCEL_SUCCESS';
export const SERIALNUMBERS_EXPORT_EXCEL_FAILED = 'SERIALNUMBERS_EXPORT_EXCEL_FAILED';

// Temp serial number const
export const TEMPSERIALNUMBERS_SELECT_ALL = 'TEMPSERIALNUMBERS_SELECT_ALL';
export const TEMPSERIALNUMBERS_UNSELECT_ALL = 'TEMPSERIALNUMBERS_UNSELECT_ALL';
export const TEMPSERIALNUMBER_SELECTED = 'TEMPSERIALNUMBER_SELECTED';
export const TEMPSERIALNUMBER_LOAD = 'TEMPSERIALNUMBER_LOAD';
export const TEMPSERIALNUMBER_LOAD_SUCCESS = 'TEMPSERIALNUMBER_LOAD_SUCCESS';
export const TEMPSERIALNUMBER_LOAD_FAILED = 'TEMPSERIALNUMBER_LOAD_FAILED';
export const TEMPSERIALNUMBERS_LOAD = 'TEMPSERIALNUMBERS_LOAD';
export const TEMPSERIALNUMBERS_LOAD_SUCCESS = 'TEMPSERIALNUMBERS_LOAD_SUCCESS';
export const TEMPSERIALNUMBERS_LOAD_FAILED = 'TEMPSERIALNUMBERS_LOAD_FAILED';
export const TEMPSERIALNUMBER_ADD = 'TEMPSERIALNUMBER_ADD';
export const TEMPSERIALNUMBER_ADD_SUCCESS = 'TEMPSERIALNUMBER_ADD_SUCCESS';
export const TEMPSERIALNUMBER_ADD_FAILED = 'TEMPSERIALNUMBER_ADD_FAILED';
export const TEMPSERIALNUMBER_UPDATE = 'TEMPSERIALNUMBER_UPDATE';
export const TEMPSERIALNUMBER_UPDATE_SUCCESS = 'TEMPSERIALNUMBER_UPDATE_SUCCESS';
export const TEMPSERIALNUMBER_UPDATE_FAILED = 'TEMPSERIALNUMBER_UPDATE_FAILED';
export const TEMPSERIALNUMBER_DELETE = 'TEMPSERIALNUMBER_DELETE';
export const TEMPSERIALNUMBER_DELETE_SUCCESS = 'TEMPSERIALNUMBER_DELETE_SUCCESS';
export const TEMPSERIALNUMBER_DELETE_FAILED = 'TEMPSERIALNUMBER_DELETE_FAILED';
export const TEMPSERIALNUMBER_DELETE_ALL = 'TEMPSERIALNUMBER_DELETE_ALL';
export const TEMPSERIALNUMBER_DELETE_ALL_SUCCESS = 'TEMPSERIALNUMBER_DELETE_ALL_SUCCESS';
export const TEMPSERIALNUMBER_DELETE_ALL_FAILED = 'TEMPSERIALNUMBER_DELETE_ALL_FAILED';
export const TEMPSERIALNUMBERS_EXPORT_EXCEL = 'TEMPSERIALNUMBERS_EXPORT_EXCEL';
export const TEMPSERIALNUMBERS_EXPORT_EXCEL_SUCCESS = 'TEMPSERIALNUMBERS_EXPORT_EXCEL_SUCCESS';
export const TEMPSERIALNUMBERS_EXPORT_EXCEL_FAILED = 'TEMPSERIALNUMBERS_EXPORT_EXCEL_FAILED';

// Album const
export const ALBUMS_SELECT_ALL = 'ALBUMS_SELECT_ALL';
export const ALBUMS_UNSELECT_ALL = 'ALBUMS_UNSELECT_ALL';
export const ALBUM_SELECTED = 'ALBUM_SELECTED';
export const ALBUM_LOAD = 'ALBUM_LOAD';
export const ALBUM_LOAD_SUCCESS = 'ALBUM_LOAD_SUCCESS';
export const ALBUM_LOAD_FAILED = 'ALBUM_LOAD_FAILED';
export const ALBUMS_LOAD = 'ALBUMS_LOAD';
export const ALBUMS_LOAD_SUCCESS = 'ALBUMS_LOAD_SUCCESS';
export const ALBUMS_LOAD_FAILED = 'ALBUMS_LOAD_FAILED';
export const ALBUM_ADD = 'ALBUM_ADD';
export const ALBUM_ADD_SUCCESS = 'ALBUM_ADD_SUCCESS';
export const ALBUM_ADD_FAILED = 'ALBUM_ADD_FAILED';
export const ALBUM_UPDATE = 'ALBUM_UPDATE';
export const ALBUM_UPDATE_SUCCESS = 'ALBUM_UPDATE_SUCCESS';
export const ALBUM_UPDATE_FAILED = 'ALBUM_UPDATE_FAILED';
export const ALBUM_DELETE = 'ALBUM_DELETE';
export const ALBUM_DELETE_SUCCESS = 'ALBUM_DELETE_SUCCESS';
export const ALBUM_DELETE_FAILED = 'ALBUM_DELETE_FAILED';
export const ALBUM_DELETE_ALL = 'ALBUM_DELETE_ALL';
export const ALBUM_DELETE_ALL_SUCCESS = 'ALBUM_DELETE_ALL_SUCCESS';
export const ALBUM_DELETE_ALL_FAILED = 'ALBUM_DELETE_ALL_FAILED';
export const ALBUMS_EXPORT_EXCEL = 'ALBUMS_EXPORT_EXCEL';
export const ALBUMS_EXPORT_EXCEL_SUCCESS = 'ALBUMS_EXPORT_EXCEL_SUCCESS';
export const ALBUMS_EXPORT_EXCEL_FAILED = 'ALBUMS_EXPORT_EXCEL_FAILED';

// Media const
export const MEDIAS_SELECT_ALL = 'MEDIAS_SELECT_ALL';
export const MEDIAS_UNSELECT_ALL = 'MEDIAS_UNSELECT_ALL';
export const MEDIA_SELECTED = 'MEDIA_SELECTED';
export const MEDIA_LOAD = 'MEDIA_LOAD';
export const MEDIA_LOAD_SUCCESS = 'MEDIA_LOAD_SUCCESS';
export const MEDIA_LOAD_FAILED = 'MEDIA_LOAD_FAILED';
export const MEDIAS_LOAD = 'MEDIAS_LOAD';
export const MEDIAS_LOAD_SUCCESS = 'MEDIAS_LOAD_SUCCESS';
export const MEDIAS_LOAD_FAILED = 'MEDIAS_LOAD_FAILED';
export const MEDIA_ADD = 'MEDIA_ADD';
export const MEDIA_ADD_SUCCESS = 'MEDIA_ADD_SUCCESS';
export const MEDIA_ADD_FAILED = 'MEDIA_ADD_FAILED';
export const MEDIA_UPDATE = 'MEDIA_UPDATE';
export const MEDIA_UPDATE_SUCCESS = 'MEDIA_UPDATE_SUCCESS';
export const MEDIA_UPDATE_FAILED = 'MEDIA_UPDATE_FAILED';
export const MEDIA_DELETE = 'MEDIA_DELETE';
export const MEDIA_DELETE_SUCCESS = 'MEDIA_DELETE_SUCCESS';
export const MEDIA_DELETE_FAILED = 'MEDIA_DELETE_FAILED';
export const MEDIA_DELETE_ALL = 'MEDIA_DELETE_ALL';
export const MEDIA_DELETE_ALL_SUCCESS = 'MEDIA_DELETE_ALL_SUCCESS';
export const MEDIA_DELETE_ALL_FAILED = 'MEDIA_DELETE_ALL_FAILED';
export const MEDIAS_EXPORT_EXCEL = 'MEDIAS_EXPORT_EXCEL';
export const MEDIAS_EXPORT_EXCEL_SUCCESS = 'MEDIAS_EXPORT_EXCEL_SUCCESS';
export const MEDIAS_EXPORT_EXCEL_FAILED = 'MEDIAS_EXPORT_EXCEL_FAILED';

// Library const
export const LIBRARIES_SELECT_ALL = 'LIBRARIES_SELECT_ALL';
export const LIBRARIES_UNSELECT_ALL = 'LIBRARIES_UNSELECT_ALL';
export const LIBRARY_SELECTED = 'LIBRARY_SELECTED';
export const LIBRARY_LOAD = 'LIBRARY_LOAD';
export const LIBRARY_LOAD_SUCCESS = 'LIBRARY_LOAD_SUCCESS';
export const LIBRARY_LOAD_FAILED = 'LIBRARY_LOAD_FAILED';
export const LIBRARIES_LOAD = 'LIBRARIES_LOAD';
export const LIBRARIES_LOAD_SUCCESS = 'LIBRARIES_LOAD_SUCCESS';
export const LIBRARIES_LOAD_FAILED = 'LIBRARIES_LOAD_FAILED';
export const LIBRARY_ADD = 'LIBRARY_ADD';
export const LIBRARY_ADD_SUCCESS = 'LIBRARY_ADD_SUCCESS';
export const LIBRARY_ADD_FAILED = 'LIBRARY_ADD_FAILED';
export const LIBRARY_UPDATE = 'LIBRARY_UPDATE';
export const LIBRARY_UPDATE_SUCCESS = 'LIBRARY_UPDATE_SUCCESS';
export const LIBRARY_UPDATE_FAILED = 'LIBRARY_UPDATE_FAILED';
export const LIBRARY_DELETE = 'LIBRARY_DELETE';
export const LIBRARY_DELETE_SUCCESS = 'LIBRARY_DELETE_SUCCESS';
export const LIBRARY_DELETE_FAILED = 'LIBRARY_DELETE_FAILED';
export const LIBRARY_DELETE_ALL = 'LIBRARY_DELETE_ALL';
export const LIBRARY_DELETE_ALL_SUCCESS = 'LIBRARY_DELETE_ALL_SUCCESS';
export const LIBRARY_DELETE_ALL_FAILED = 'LIBRARY_DELETE_ALL_FAILED';
export const LIBRARIES_EXPORT_EXCEL = 'LIBRARIES_EXPORT_EXCEL';
export const LIBRARIES_EXPORT_EXCEL_SUCCESS = 'LIBRARIES_EXPORT_EXCEL_SUCCESS';
export const LIBRARIES_EXPORT_EXCEL_FAILED = 'LIBRARIES_EXPORT_EXCEL_FAILED';

// Notification const
export const NOTIFICATIONS_SELECT_ALL = 'NOTIFICATIONS_SELECT_ALL';
export const NOTIFICATIONS_UNSELECT_ALL = 'NOTIFICATIONS_UNSELECT_ALL';
export const NOTIFICATION_SELECTED = 'NOTIFICATION_SELECTED';
export const NOTIFICATION_LOAD = 'NOTIFICATION_LOAD';
export const NOTIFICATION_LOAD_SUCCESS = 'NOTIFICATION_LOAD_SUCCESS';
export const NOTIFICATION_LOAD_FAILED = 'NOTIFICATION_LOAD_FAILED';
export const NOTIFICATIONS_LOAD = 'NOTIFICATIONS_LOAD';
export const NOTIFICATIONS_LOAD_SUCCESS = 'NOTIFICATIONS_LOAD_SUCCESS';
export const NOTIFICATIONS_LOAD_FAILED = 'NOTIFICATIONS_LOAD_FAILED';
export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_ADD_SUCCESS = 'NOTIFICATION_ADD_SUCCESS';
export const NOTIFICATION_ADD_FAILED = 'NOTIFICATION_ADD_FAILED';
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
export const NOTIFICATION_UPDATE_SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS';
export const NOTIFICATION_UPDATE_FAILED = 'NOTIFICATION_UPDATE_FAILED';
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';
export const NOTIFICATION_DELETE_SUCCESS = 'NOTIFICATION_DELETE_SUCCESS';
export const NOTIFICATION_DELETE_FAILED = 'NOTIFICATION_DELETE_FAILED';
export const NOTIFICATION_DELETE_ALL = 'NOTIFICATION_DELETE_ALL';
export const NOTIFICATION_DELETE_ALL_SUCCESS = 'NOTIFICATION_DELETE_ALL_SUCCESS';
export const NOTIFICATION_DELETE_ALL_FAILED = 'NOTIFICATION_DELETE_ALL_FAILED';
export const NOTIFICATIONS_EXPORT_EXCEL = 'NOTIFICATIONS_EXPORT_EXCEL';
export const NOTIFICATIONS_EXPORT_EXCEL_SUCCESS = 'NOTIFICATIONS_EXPORT_EXCEL_SUCCESS';
export const NOTIFICATIONS_EXPORT_EXCEL_FAILED = 'NOTIFICATIONS_EXPORT_EXCEL_FAILED';
export const NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT';
export const NOTIFICATIONS_COUNT_SUCCESS = 'NOTIFICATIONS_COUNT_SUCCESS';

// Partner const
export const PARTNERS_SELECT_ALL = 'PARTNERS_SELECT_ALL';
export const PARTNERS_UNSELECT_ALL = 'PARTNERS_UNSELECT_ALL';
export const PARTNER_SELECTED = 'PARTNER_SELECTED';
export const PARTNER_LOAD = 'PARTNER_LOAD';
export const PARTNER_LOAD_SUCCESS = 'PARTNER_LOAD_SUCCESS';
export const PARTNER_LOAD_FAILED = 'PARTNER_LOAD_FAILED';
export const PARTNERS_LOAD = 'PARTNERS_LOAD';
export const PARTNERS_LOAD_SUCCESS = 'PARTNERS_LOAD_SUCCESS';
export const PARTNERS_LOAD_FAILED = 'PARTNERS_LOAD_FAILED';
export const PARTNER_ADD = 'PARTNER_ADD';
export const PARTNER_ADD_SUCCESS = 'PARTNER_ADD_SUCCESS';
export const PARTNER_ADD_FAILED = 'PARTNER_ADD_FAILED';
export const PARTNER_UPDATE = 'PARTNER_UPDATE';
export const PARTNER_UPDATE_SUCCESS = 'PARTNER_UPDATE_SUCCESS';
export const PARTNER_UPDATE_FAILED = 'PARTNER_UPDATE_FAILED';
export const PARTNER_DELETE = 'PARTNER_DELETE';
export const PARTNER_DELETE_SUCCESS = 'PARTNER_DELETE_SUCCESS';
export const PARTNER_DELETE_FAILED = 'PARTNER_DELETE_FAILED';
export const PARTNER_DELETE_ALL = 'PARTNER_DELETE_ALL';
export const PARTNER_DELETE_ALL_SUCCESS = 'PARTNER_DELETE_ALL_SUCCESS';
export const PARTNER_DELETE_ALL_FAILED = 'PARTNER_DELETE_ALL_FAILED';
export const PARTNERS_EXPORT_EXCEL = 'PARTNERS_EXPORT_EXCEL';
export const PARTNERS_EXPORT_EXCEL_SUCCESS = 'PARTNERS_EXPORT_EXCEL_SUCCESS';
export const PARTNERS_EXPORT_EXCEL_FAILED = 'PARTNERS_EXPORT_EXCEL_FAILED';

// Project const
export const PROJECTS_SELECT_ALL = 'PROJECTS_SELECT_ALL';
export const PROJECTS_UNSELECT_ALL = 'PROJECTS_UNSELECT_ALL';
export const PROJECT_SELECTED = 'PROJECT_SELECTED';
export const PROJECT_LOAD = 'PROJECT_LOAD';
export const PROJECT_LOAD_SUCCESS = 'PROJECT_LOAD_SUCCESS';
export const PROJECT_LOAD_FAILED = 'PROJECT_LOAD_FAILED';
export const PROJECTS_LOAD = 'PROJECTS_LOAD';
export const PROJECTS_LOAD_SUCCESS = 'PROJECTS_LOAD_SUCCESS';
export const PROJECTS_LOAD_FAILED = 'PROJECTS_LOAD_FAILED';
export const PROJECT_ADD = 'PROJECT_ADD';
export const PROJECT_ADD_SUCCESS = 'PROJECT_ADD_SUCCESS';
export const PROJECT_ADD_FAILED = 'PROJECT_ADD_FAILED';
export const PROJECT_UPDATE = 'PROJECT_UPDATE';
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const PROJECT_UPDATE_FAILED = 'PROJECT_UPDATE_FAILED';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS';
export const PROJECT_DELETE_FAILED = 'PROJECT_DELETE_FAILED';
export const PROJECT_DELETE_ALL = 'PROJECT_DELETE_ALL';
export const PROJECT_DELETE_ALL_SUCCESS = 'PROJECT_DELETE_ALL_SUCCESS';
export const PROJECT_DELETE_ALL_FAILED = 'PROJECT_DELETE_ALL_FAILED';
export const PROJECTS_EXPORT_EXCEL = 'PROJECTS_EXPORT_EXCEL';
export const PROJECTS_EXPORT_EXCEL_SUCCESS = 'PROJECTS_EXPORT_EXCEL_SUCCESS';
export const PROJECTS_EXPORT_EXCEL_FAILED = 'PROJECTS_EXPORT_EXCEL_FAILED';

// Document const
export const DOCUMENTS_SELECT_ALL = 'DOCUMENTS_SELECT_ALL';
export const DOCUMENTS_UNSELECT_ALL = 'DOCUMENTS_UNSELECT_ALL';
export const DOCUMENT_SELECTED = 'DOCUMENT_SELECTED';
export const DOCUMENT_LOAD = 'DOCUMENT_LOAD';
export const DOCUMENT_LOAD_SUCCESS = 'DOCUMENT_LOAD_SUCCESS';
export const DOCUMENT_LOAD_FAILED = 'DOCUMENT_LOAD_FAILED';
export const DOCUMENTS_LOAD = 'DOCUMENTS_LOAD';
export const DOCUMENTS_LOAD_SUCCESS = 'DOCUMENTS_LOAD_SUCCESS';
export const DOCUMENTS_LOAD_FAILED = 'DOCUMENTS_LOAD_FAILED';
export const DOCUMENT_ADD = 'DOCUMENT_ADD';
export const DOCUMENT_ADD_SUCCESS = 'DOCUMENT_ADD_SUCCESS';
export const DOCUMENT_ADD_FAILED = 'DOCUMENT_ADD_FAILED';
export const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE';
export const DOCUMENT_UPDATE_SUCCESS = 'DOCUMENT_UPDATE_SUCCESS';
export const DOCUMENT_UPDATE_FAILED = 'DOCUMENT_UPDATE_FAILED';
export const DOCUMENT_DELETE = 'DOCUMENT_DELETE';
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS';
export const DOCUMENT_DELETE_FAILED = 'DOCUMENT_DELETE_FAILED';
export const DOCUMENT_DELETE_ALL = 'DOCUMENT_DELETE_ALL';
export const DOCUMENT_DELETE_ALL_SUCCESS = 'DOCUMENT_DELETE_ALL_SUCCESS';
export const DOCUMENT_DELETE_ALL_FAILED = 'DOCUMENT_DELETE_ALL_FAILED';
export const DOCUMENTS_EXPORT_EXCEL = 'DOCUMENTS_EXPORT_EXCEL';
export const DOCUMENTS_EXPORT_EXCEL_SUCCESS = 'DOCUMENTS_EXPORT_EXCEL_SUCCESS';
export const DOCUMENTS_EXPORT_EXCEL_FAILED = 'DOCUMENTS_EXPORT_EXCEL_FAILED';

// Personal mail const
export const PERSONALMAILS_SELECT_ALL = 'PERSONALMAILS_SELECT_ALL';
export const PERSONALMAILS_UNSELECT_ALL = 'PERSONALMAILS_UNSELECT_ALL';
export const PERSONALMAIL_SELECTED = 'PERSONALMAIL_SELECTED';
export const PERSONALMAIL_LOAD = 'PERSONALMAIL_LOAD';
export const PERSONALMAIL_LOAD_SUCCESS = 'PERSONALMAIL_LOAD_SUCCESS';
export const PERSONALMAIL_LOAD_FAILED = 'PERSONALMAIL_LOAD_FAILED';
export const PERSONALMAILS_LOAD = 'PERSONALMAILS_LOAD';
export const PERSONALMAILS_LOAD_SUCCESS = 'PERSONALMAILS_LOAD_SUCCESS';
export const PERSONALMAILS_LOAD_FAILED = 'PERSONALMAILS_LOAD_FAILED';
export const PERSONALMAIL_ADD = 'PERSONALMAIL_ADD';
export const PERSONALMAIL_ADD_SUCCESS = 'PERSONALMAIL_ADD_SUCCESS';
export const PERSONALMAIL_ADD_FAILED = 'PERSONALMAIL_ADD_FAILED';
export const PERSONALMAIL_UPDATE = 'PERSONALMAIL_UPDATE';
export const PERSONALMAIL_UPDATE_SUCCESS = 'PERSONALMAIL_UPDATE_SUCCESS';
export const PERSONALMAIL_UPDATE_FAILED = 'PERSONALMAIL_UPDATE_FAILED';
export const PERSONALMAIL_DELETE = 'PERSONALMAIL_DELETE';
export const PERSONALMAIL_DELETE_SUCCESS = 'PERSONALMAIL_DELETE_SUCCESS';
export const PERSONALMAIL_DELETE_FAILED = 'PERSONALMAIL_DELETE_FAILED';
export const PERSONALMAIL_DELETE_ALL = 'PERSONALMAIL_DELETE_ALL';
export const PERSONALMAIL_DELETE_ALL_SUCCESS = 'PERSONALMAIL_DELETE_ALL_SUCCESS';
export const PERSONALMAIL_DELETE_ALL_FAILED = 'PERSONALMAIL_DELETE_ALL_FAILED';
export const PERSONALMAILS_EXPORT_EXCEL = 'PERSONALMAILS_EXPORT_EXCEL';
export const PERSONALMAILS_EXPORT_EXCEL_SUCCESS = 'PERSONALMAILS_EXPORT_EXCEL_SUCCESS';
export const PERSONALMAILS_EXPORT_EXCEL_FAILED = 'PERSONALMAILS_EXPORT_EXCEL_FAILED';
export const PERSONALMAIL_SENT = 'PERSONALMAIL_SENT';
export const PERSONALMAIL_SENT_SUCCESS = 'PERSONALMAIL_SENT_SUCCESS';
export const PERSONALMAIL_SENT_FAILED = 'PERSONALMAIL_SENT_FAILED';

// AgencyFee const
export const AGENCYFEES_SELECT_ALL = 'AGENCYFEES_SELECT_ALL';
export const AGENCYFEES_UNSELECT_ALL = 'AGENCYFEES_UNSELECT_ALL';
export const AGENCYFEE_SELECTED = 'AGENCYFEE_SELECTED';
export const AGENCYFEE_LOAD = 'AGENCYFEE_LOAD';
export const AGENCYFEE_LOAD_SUCCESS = 'AGENCYFEE_LOAD_SUCCESS';
export const AGENCYFEE_LOAD_FAILED = 'AGENCYFEE_LOAD_FAILED';
export const AGENCYFEES_LOAD = 'AGENCYFEES_LOAD';
export const AGENCYFEES_LOAD_SUCCESS = 'AGENCYFEES_LOAD_SUCCESS';
export const AGENCYFEES_LOAD_FAILED = 'AGENCYFEES_LOAD_FAILED';
export const AGENCYFEE_ADD = 'AGENCYFEE_ADD';
export const AGENCYFEE_ADD_SUCCESS = 'AGENCYFEE_ADD_SUCCESS';
export const AGENCYFEE_ADD_FAILED = 'AGENCYFEE_ADD_FAILED';
export const AGENCYFEE_UPDATE = 'AGENCYFEE_UPDATE';
export const AGENCYFEE_UPDATE_SUCCESS = 'AGENCYFEE_UPDATE_SUCCESS';
export const AGENCYFEE_UPDATE_FAILED = 'AGENCYFEE_UPDATE_FAILED';
export const AGENCYFEE_DELETE = 'AGENCYFEE_DELETE';
export const AGENCYFEE_DELETE_SUCCESS = 'AGENCYFEE_DELETE_SUCCESS';
export const AGENCYFEE_DELETE_FAILED = 'AGENCYFEE_DELETE_FAILED';
export const AGENCYFEE_DELETE_ALL = 'AGENCYFEE_DELETE_ALL';
export const AGENCYFEE_DELETE_ALL_SUCCESS = 'AGENCYFEE_DELETE_ALL_SUCCESS';
export const AGENCYFEE_DELETE_ALL_FAILED = 'AGENCYFEE_DELETE_ALL_FAILED';
export const AGENCYFEES_EXPORT_EXCEL = 'AGENCYFEES_EXPORT_EXCEL';
export const AGENCYFEES_EXPORT_EXCEL_SUCCESS = 'AGENCYFEES_EXPORT_EXCEL_SUCCESS';
export const AGENCYFEES_EXPORT_EXCEL_FAILED = 'AGENCYFEES_EXPORT_EXCEL_FAILED';

// Approval const
export const APPROVALS_SELECT_ALL = 'APPROVALS_SELECT_ALL';
export const APPROVALS_UNSELECT_ALL = 'APPROVALS_UNSELECT_ALL';
export const APPROVAL_SELECTED = 'APPROVAL_SELECTED';
export const APPROVAL_LOAD = 'APPROVAL_LOAD';
export const APPROVAL_LOAD_SUCCESS = 'APPROVAL_LOAD_SUCCESS';
export const APPROVAL_LOAD_FAILED = 'APPROVAL_LOAD_FAILED';
export const APPROVALS_LOAD = 'APPROVALS_LOAD';
export const APPROVALS_LOAD_SUCCESS = 'APPROVALS_LOAD_SUCCESS';
export const APPROVALS_LOAD_FAILED = 'APPROVALS_LOAD_FAILED';
export const APPROVAL_ADD = 'APPROVAL_ADD';
export const APPROVAL_ADD_SUCCESS = 'APPROVAL_ADD_SUCCESS';
export const APPROVAL_ADD_FAILED = 'APPROVAL_ADD_FAILED';
export const APPROVAL_UPDATE = 'APPROVAL_UPDATE';
export const APPROVAL_UPDATE_SUCCESS = 'APPROVAL_UPDATE_SUCCESS';
export const APPROVAL_UPDATE_FAILED = 'APPROVAL_UPDATE_FAILED';
export const APPROVAL_DELETE = 'APPROVAL_DELETE';
export const APPROVAL_DELETE_SUCCESS = 'APPROVAL_DELETE_SUCCESS';
export const APPROVAL_DELETE_FAILED = 'APPROVAL_DELETE_FAILED';
export const APPROVAL_DELETE_ALL = 'APPROVAL_DELETE_ALL';
export const APPROVAL_DELETE_ALL_SUCCESS = 'APPROVAL_DELETE_ALL_SUCCESS';
export const APPROVAL_DELETE_ALL_FAILED = 'APPROVAL_DELETE_ALL_FAILED';
export const APPROVALS_EXPORT_EXCEL = 'APPROVALS_EXPORT_EXCEL';
export const APPROVALS_EXPORT_EXCEL_SUCCESS = 'APPROVALS_EXPORT_EXCEL_SUCCESS';
export const APPROVALS_EXPORT_EXCEL_FAILED = 'APPROVALS_EXPORT_EXCEL_FAILED';

// Sample const
export const SAMPLES_SELECT_ALL = 'SAMPLES_SELECT_ALL';
export const SAMPLES_UNSELECT_ALL = 'SAMPLES_UNSELECT_ALL';
export const SAMPLE_SELECTED = 'SAMPLE_SELECTED';
export const SAMPLE_LOAD = 'SAMPLE_LOAD';
export const SAMPLE_LOAD_SUCCESS = 'SAMPLE_LOAD_SUCCESS';
export const SAMPLE_LOAD_FAILED = 'SAMPLE_LOAD_FAILED';
export const SAMPLES_LOAD = 'SAMPLES_LOAD';
export const SAMPLES_LOAD_SUCCESS = 'SAMPLES_LOAD_SUCCESS';
export const SAMPLES_LOAD_FAILED = 'SAMPLES_LOAD_FAILED';
export const SAMPLE_ADD = 'SAMPLE_ADD';
export const SAMPLE_ADD_SUCCESS = 'SAMPLE_ADD_SUCCESS';
export const SAMPLE_ADD_FAILED = 'SAMPLE_ADD_FAILED';
export const SAMPLE_UPDATE = 'SAMPLE_UPDATE';
export const SAMPLE_UPDATE_SUCCESS = 'SAMPLE_UPDATE_SUCCESS';
export const SAMPLE_UPDATE_FAILED = 'SAMPLE_UPDATE_FAILED';
export const SAMPLE_DELETE = 'SAMPLE_DELETE';
export const SAMPLE_DELETE_SUCCESS = 'SAMPLE_DELETE_SUCCESS';
export const SAMPLE_DELETE_FAILED = 'SAMPLE_DELETE_FAILED';
export const SAMPLE_DELETE_ALL = 'SAMPLE_DELETE_ALL';
export const SAMPLE_DELETE_ALL_SUCCESS = 'SAMPLE_DELETE_ALL_SUCCESS';
export const SAMPLE_DELETE_ALL_FAILED = 'SAMPLE_DELETE_ALL_FAILED';
export const SAMPLES_EXPORT_EXCEL = 'SAMPLES_EXPORT_EXCEL';
export const SAMPLES_EXPORT_EXCEL_SUCCESS = 'SAMPLES_EXPORT_EXCEL_SUCCESS';
export const SAMPLES_EXPORT_EXCEL_FAILED = 'SAMPLES_EXPORT_EXCEL_FAILED';