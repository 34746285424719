import {
  PERSONALPAPERS_SELECT_ALL,
  PERSONALPAPERS_UNSELECT_ALL,
  PERSONALPAPER_SELECTED,
  PERSONALPAPERS_LOAD,
  PERSONALPAPERS_LOAD_SUCCESS,
  PERSONALPAPERS_LOAD_FAILED,
  PERSONALPAPER_LOAD,
  PERSONALPAPER_LOAD_SUCCESS,
  PERSONALPAPER_LOAD_FAILED,
  PERSONALPAPER_ADD,
  PERSONALPAPER_ADD_SUCCESS,
  PERSONALPAPER_ADD_FAILED,
  PERSONALPAPER_UPDATE,
  PERSONALPAPER_UPDATE_SUCCESS,
  PERSONALPAPER_UPDATE_FAILED,
  PERSONALPAPER_DELETE,
  PERSONALPAPER_DELETE_SUCCESS,
  PERSONALPAPER_DELETE_FAILED,
  PERSONALPAPER_DELETE_ALL,
  PERSONALPAPER_DELETE_ALL_SUCCESS,
  PERSONALPAPER_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllPersonalPapers = () => {
  return {
    type: PERSONALPAPERS_SELECT_ALL
  }
}

export const unselectAllPersonalPapers = () => {
  return {
    type: PERSONALPAPERS_UNSELECT_ALL
  }
}

export const selectPersonalPaper = data => {
  return {
    type: PERSONALPAPER_SELECTED,
    payload: data
  }
}

export const loadPersonalPapers = (data) => {
  return {
    type: PERSONALPAPERS_LOAD,
    payload: data
  };
}

export const loadPersonalPapersSuccess = (data) => {
  return {
    type: PERSONALPAPERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadPersonalPapersFailed = () => {
  return {
    type: PERSONALPAPERS_LOAD_FAILED
  };
}

export const loadPersonalPaper = () => {
  return {
    type: PERSONALPAPER_LOAD
  };
}

export const loadPersonalPaperSuccess = (data) => {
  return {
    type: PERSONALPAPER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadPersonalPaperFailed = () => {
  return {
    type: PERSONALPAPER_LOAD_FAILED
  };
}


export const addPersonalPaper = (data) => {
  return {
    type: PERSONALPAPER_ADD,
    payload: data
  };
}


export const addPersonalPaperSuccess = (filter) => {
  return {
    type: PERSONALPAPER_ADD_SUCCESS,
    message: 'PersonalPaper added successfully.',
    payload: filter
  };
}

export const addPersonalPaperFailed = () => {
  return {
    type: PERSONALPAPER_ADD_FAILED
  };
}

export const updatePersonalPaper = (data) => {
  return {
    type: PERSONALPAPER_UPDATE,
    payload: data
  };
}


export const updatePersonalPaperSuccess = (data) => {
  return {
    type: PERSONALPAPER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updatePersonalPaperFailed = (data) => {
  return {
    type: PERSONALPAPER_UPDATE_FAILED,
    payload: data
  };
}

export const deletePersonalPaper = (data) => {
  return {
    type: PERSONALPAPER_DELETE,
    payload: data
  };
}

export const deletePersonalPaperSuccess = () => {
  return {
    type: PERSONALPAPER_DELETE_SUCCESS,
    message: 'PersonalPaper deleted successfully.'
  };
}

export const deletePersonalPaperFailed = () => {
  return {
    type: PERSONALPAPER_DELETE_FAILED
  };
}

export const deleteAllPersonalPapers = (data) => {
  return {
    type: PERSONALPAPER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllPersonalPapersSuccess = () => {
  return {
    type: PERSONALPAPER_DELETE_ALL_SUCCESS,
    message: 'PersonalPaper all deleted successfully.'
  };
}

export const deleteAllPersonalPapersFailed = () => {
  return {
    type: PERSONALPAPER_DELETE_ALL_FAILED
  };
}
