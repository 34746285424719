import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadEmployees as loadEmployeesAction,
  loadEmployeesSuccess,
  addEmployeeSuccess,
  updateEmployeeSuccess
} from '../actions/Employee';

import {
  EMPLOYEES_LOAD,
  EMPLOYEE_ADD,
  EMPLOYEE_UPDATE,
  EMPLOYEE_DELETE,
  EMPLOYEE_DELETE_ALL,
  SAVE_EMPLOYEE,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';
import { saveEmployeeSuccess } from "../actions/Common";

export const getEmployeeById = (id, callback) => {
  Api().get('employee/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllEmployees = (callback) => {
  Api().post('employee/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getMaxEmployeeId = (callback) => {
  Api().post('employee/search', { offset: 0,
    limit: 1,
    orderBy: "id",
    descending: true
  })
    .then(response => {
      let id = 1;
      let records = response.data.records;
      if (records.length) {
        id = records[0].id;
      }
      callback(id);
    })
}

const getEmployeeByUserIdRequest = async (userId) => {
  let filterGroups = [{
    filters: [{
      property: 'userId',
      operator: 'equal',
      value: userId
    }]
  }];
  return await Api().post(`employee/search`, { filterGroups: filterGroups })
    .then(response => response)
    .catch(error => error);
}

export const getEmployeesByWorkLocation = (reportTos, callback) => {
  let filterGroups = [];
  if (reportTos) {
    reportTos.map(reportTo => {
      filterGroups.push({ filters: [{
        property: 'workLocationId',
        operator: 'equal',
        value: reportTo
      }]})
    });
  }
  if (filterGroups.length) {
    Api().post(`employee/search`, { filterGroups: filterGroups })
      .then(response => {
        callback(response.data.records);
      })
      .catch(error => error);
  } else {
    callback([]);
  }
}

const loadEmployeesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`employee/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`employee`, { params })
    .then(data => data)
    .catch(error => error);
}


const addEmployeeRequest = async (payload) =>
  await Api().post(`employee`, payload)
    .then(data => data)
    .catch(error => error);

const updateEmployeeRequest = async (payload) =>
  await Api().patch(`employee/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteEmployeeRequest = async (id) =>
  await Api().delete(`employee/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadEmployees({ payload }) {
  try {
    const data = yield call(loadEmployeesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadEmployeesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}


function* processLoadEmployees() {
  yield takeEvery(EMPLOYEES_LOAD, loadEmployees);
}

function* loadEmployee({ selectedEmployeeId }) {
  try {
    const data = yield call(loadEmployeesRequest, selectedEmployeeId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadEmployeesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadEmployee() {
  yield takeEvery(EMPLOYEES_LOAD, loadEmployee);
}


function* addEmployee({ payload }) {
  try {
    const data = yield call(addEmployeeRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addEmployeeSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddEmployee() {
  yield takeEvery(EMPLOYEE_ADD, addEmployee);
}


function* updateEmployee({ payload }) {
  try {
    const data = yield call(updateEmployeeRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateEmployeeSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateEmployee() {
  yield takeEvery(EMPLOYEE_UPDATE, updateEmployee);
}

function* deleteEmployee({ payload }) {
  try {
    const data = yield call(deleteEmployeeRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadEmployeesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllEmployees({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteEmployeeRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadEmployeesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteEmployee() {
  yield takeEvery(EMPLOYEE_DELETE, deleteEmployee);
}

function* processDeleteAllEmployees() {
  yield takeEvery(EMPLOYEE_DELETE_ALL, deleteAllEmployees);
}

function* getEmployeeByUserId({ payload }) {
  try {
    const data = yield call(getEmployeeByUserIdRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(saveEmployeeSuccess(data.data.records.length ? data.data.records[0] : {}));
  } catch (errors) {}
}

function* processGetEmployeeByUserId() {
  yield takeEvery(SAVE_EMPLOYEE, getEmployeeByUserId);
}

export default function* EmployeeSagas() {
  yield all([fork(processLoadEmployees),
  fork(processLoadEmployee),
  fork(processAddEmployee),
  fork(processUpdateEmployee),
  fork(processDeleteAllEmployees),
  fork(processDeleteEmployee),
  fork(processGetEmployeeByUserId)]);
}
