import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadQuotations as loadQuotationsAction,
  loadQuotationsSuccess,
  addQuotationSuccess,
  updateQuotationSuccess,
  exportExcelQuotations,
  exportExcelQuotationsSuccess,
  exportExcelQuotationsFailed
} from '../actions/Quotation';

import {
  QUOTATIONS_LOAD,
  QUOTATION_ADD,
  QUOTATION_UPDATE,
  QUOTATION_DELETE,
  QUOTATION_DELETE_ALL,
  QUOTATIONS_EXPORT_EXCEL,
  QUOTATIONS_EXPORT_EXCEL_SUCCESS,
  QUOTATIONS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getQuotationById = (id, callback) => {
  Api().get('quotation/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllQuotations = (callback) => {
  Api().post('quotation/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getMaxQuotationCodeSorted = (callback) => {
  Api().post('quotation/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadQuotationsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`quotation/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`quotation`, { params })
    .then(data => data)
    .catch(error => error);
}


const addQuotationRequest = async (payload) =>
  await Api().post(`quotation`, payload)
    .then(data => data)
    .catch(error => error);

const updateQuotationRequest = async (payload) =>
  await Api().patch(`quotation/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteQuotationRequest = async (id) =>
  await Api().delete(`quotation/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadQuotations({ payload }) {
  try {
    const data = yield call(loadQuotationsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadQuotationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadQuotations() {
  yield takeEvery(QUOTATIONS_LOAD, loadQuotations);
}

function* loadQuotation({ selectedQuotationId }) {
  try {
    const data = yield call(loadQuotationsRequest, selectedQuotationId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadQuotationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadQuotation() {
  yield takeEvery(QUOTATIONS_LOAD, loadQuotation);
}


function* addQuotation({ payload }) {
  try {
    const data = yield call(addQuotationRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addQuotationSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddQuotation() {
  yield takeEvery(QUOTATION_ADD, addQuotation);
}


function* updateQuotation({ payload }) {
  try {
    const data = yield call(updateQuotationRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateQuotationSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateQuotation() {
  yield takeEvery(QUOTATION_UPDATE, updateQuotation);
}

function* deleteQuotation({ payload }) {
  try {
    const data = yield call(deleteQuotationRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadQuotationsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllQuotations({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteQuotationRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadQuotationsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteQuotation() {
  yield takeEvery(QUOTATION_DELETE, deleteQuotation);
}

function* processDeleteAllQuotations() {
  yield takeEvery(QUOTATION_DELETE_ALL, deleteAllQuotations);
}

const exportQuotationsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`quotation/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportQuotations({ payload }) {
  try {
    const data = yield call(exportQuotationsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelQuotationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportQuotations() {
  yield takeEvery(QUOTATIONS_EXPORT_EXCEL, exportQuotations);
}

export default function* QuotationSagas() {
  yield all([fork(processLoadQuotations),
  fork(processLoadQuotation),
  fork(processAddQuotation),
  fork(processUpdateQuotation),
  fork(processDeleteAllQuotations),
  fork(processDeleteQuotation),
  fork(processExportQuotations)
]);
}
