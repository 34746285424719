import {
  SETTINGS_SELECT_ALL,
  SETTINGS_UNSELECT_ALL,
  SETTING_SELECTED,
  SETTINGS_LOAD,
  SETTINGS_LOAD_SUCCESS,
  SETTINGS_LOAD_FAILED,
  SETTING_LOAD,
  SETTING_LOAD_SUCCESS,
  SETTING_LOAD_FAILED,
  SETTING_ADD,
  SETTING_ADD_SUCCESS,
  SETTING_ADD_FAILED,
  SETTING_UPDATE,
  SETTING_UPDATE_SUCCESS,
  SETTING_UPDATE_FAILED,
  SETTING_DELETE,
  SETTING_DELETE_SUCCESS,
  SETTING_DELETE_FAILED,
  SETTING_DELETE_ALL,
  SETTING_DELETE_ALL_SUCCESS,
  SETTING_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllSettings = () => {
  return {
    type: SETTINGS_SELECT_ALL
  }
}

export const unselectAllSettings = () => {
  return {
    type: SETTINGS_UNSELECT_ALL
  }
}

export const selectSetting = data => {
  return {
    type: SETTING_SELECTED,
    payload: data
  }
}

export const loadSettings = (data) => {
  return {
    type: SETTINGS_LOAD,
    payload: data
  };
}

export const loadSettingsSuccess = (data) => {
  return {
    type: SETTINGS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSettingsFailed = () => {
  return {
    type: SETTINGS_LOAD_FAILED
  };
}

export const loadSetting = () => {
  return {
    type: SETTING_LOAD
  };
}

export const loadSettingSuccess = (data) => {
  return {
    type: SETTING_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSettingFailed = () => {
  return {
    type: SETTING_LOAD_FAILED
  };
}


export const addSetting = (data) => {
  return {
    type: SETTING_ADD,
    payload: data
  };
}


export const addSettingSuccess = (filter) => {
  return {
    type: SETTING_ADD_SUCCESS,
    message: 'Setting added successfully.',
    payload: filter
  };
}

export const addSettingFailed = () => {
  return {
    type: SETTING_ADD_FAILED
  };
}

export const updateSetting = (data) => {
  return {
    type: SETTING_UPDATE,
    payload: data
  };
}


export const updateSettingSuccess = (data) => {
  return {
    type: SETTING_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSettingFailed = (data) => {
  return {
    type: SETTING_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSetting = (data) => {
  return {
    type: SETTING_DELETE,
    payload: data
  };
}

export const deleteSettingSuccess = () => {
  return {
    type: SETTING_DELETE_SUCCESS,
    message: 'Setting deleted successfully.'
  };
}

export const deleteSettingFailed = () => {
  return {
    type: SETTING_DELETE_FAILED
  };
}

export const deleteAllSettings = (data) => {
  return {
    type: SETTING_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSettingsSuccess = () => {
  return {
    type: SETTING_DELETE_ALL_SUCCESS,
    message: 'Setting all deleted successfully.'
  };
}

export const deleteAllSettingsFailed = () => {
  return {
    type: SETTING_DELETE_ALL_FAILED
  };
}
