import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadCompanies as loadCompaniesAction,
  loadCompaniesSuccess,
  updateCompanySuccess,
  addCompanySuccess,
  deleteCompanySuccess,
  deleteAllCompaniesSuccess
} from '../actions/Company';

import {
  COMPANIES_LOAD,
  COMPANY_ADD,
  COMPANY_UPDATE,
  COMPANY_DELETE,
  COMPANY_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getCompanyById = (id, callback) => {
  Api().get('company/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllCompanies = (callback) => {
  Api().post('company/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadCompaniesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`company/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`company`, { params })
    .then(data => data)
    .catch(error => error);
}

const addCompanyRequest = async (payload) =>
  await Api().post(`company`, payload)
    .then(data => data)
    .catch(error => error);

const updateCompanyRequest = async (payload) =>
  await Api().patch(`company/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteCompanyRequest = async (id) =>
  await Api().delete(`company/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadCompanies({ payload }) {
  try {
    const data = yield call(loadCompaniesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadCompaniesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadCompanies() {
  yield takeEvery(COMPANIES_LOAD, loadCompanies);
}

function* loadCompany({ selectedCompanyId }) {
  try {
    const data = yield call(loadCompaniesRequest, selectedCompanyId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadCompaniesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadCompany() {
  yield takeEvery(COMPANIES_LOAD, loadCompany);
}

function* addCompany({ payload }) {
  try {
    const data = yield call(addCompanyRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addCompanySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddCompany() {
  yield takeEvery(COMPANY_ADD, addCompany);
}

function* updateCompany({ payload }) {
  try {
    const data = yield call(updateCompanyRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateCompanySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateCompany() {
  yield takeEvery(COMPANY_UPDATE, updateCompany);
}

function* deleteCompany({ payload }) {
  try {
    const data = yield call(deleteCompanyRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(deleteCompanySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllCompanies({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteCompanyRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllCompaniesSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteCompany() {
  yield takeEvery(COMPANY_DELETE, deleteCompany);
}

function* processDeleteAllCompanies() {
  yield takeEvery(COMPANY_DELETE_ALL, deleteAllCompanies);
}

export default function* CompanySagas() {
  yield all([fork(processLoadCompanies),
  fork(processLoadCompany),
  fork(processAddCompany),
  fork(processUpdateCompany),
  fork(processDeleteAllCompanies),
  fork(processDeleteCompany)]);
}
