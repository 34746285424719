import {
  DEPARTMENTS_SELECT_ALL,
  DEPARTMENTS_UNSELECT_ALL,
  DEPARTMENT_SELECTED,
  DEPARTMENTS_LOAD,
  DEPARTMENTS_LOAD_SUCCESS,
  DEPARTMENTS_LOAD_FAILED,
  DEPARTMENT_LOAD,
  DEPARTMENT_LOAD_SUCCESS,
  DEPARTMENT_LOAD_FAILED,
  DEPARTMENT_ADD,
  DEPARTMENT_ADD_SUCCESS,
  DEPARTMENT_ADD_FAILED,
  DEPARTMENT_UPDATE,
  DEPARTMENT_UPDATE_SUCCESS,
  DEPARTMENT_UPDATE_FAILED,
  DEPARTMENT_DELETE,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_FAILED,
  DEPARTMENT_DELETE_ALL,
  DEPARTMENT_DELETE_ALL_SUCCESS,
  DEPARTMENT_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedDepartment: null,
  selectedDepartmentId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DEPARTMENTS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case DEPARTMENTS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case DEPARTMENT_SELECTED:
      return {
        ...state,
        selectedDepartment: action.payload,
        selectedDepartmentId: action.payload.id
      };
    case DEPARTMENTS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter,
        hideLoading: false
      };
    case DEPARTMENTS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload,
        hideLoading: true
      };
    case DEPARTMENTS_LOAD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case DEPARTMENT_LOAD:
      return {
        ...state,
        hideLoading: false
      };
    case DEPARTMENT_LOAD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case DEPARTMENT_LOAD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case DEPARTMENT_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case DEPARTMENT_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case DEPARTMENT_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case DEPARTMENT_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case DEPARTMENT_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case DEPARTMENT_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case DEPARTMENT_DELETE:
      return {
        ...state
      };
    case DEPARTMENT_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case DEPARTMENT_DELETE_FAILED:
      return {
        ...state
      };
    case DEPARTMENT_DELETE_ALL:
      return {
        ...state
      };
    case DEPARTMENT_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case DEPARTMENT_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
