import {
  LEAVETRACKHISTORIES_SELECT_ALL,
  LEAVETRACKHISTORIES_UNSELECT_ALL,
  LEAVETRACKHISTORY_SELECTED,
  LEAVETRACKHISTORIES_LOAD,
  LEAVETRACKHISTORIES_LOAD_SUCCESS,
  LEAVETRACKHISTORIES_LOAD_FAILED,
  LEAVETRACKHISTORY_LOAD,
  LEAVETRACKHISTORY_LOAD_SUCCESS,
  LEAVETRACKHISTORY_LOAD_FAILED,
  LEAVETRACKHISTORY_ADD,
  LEAVETRACKHISTORY_ADD_SUCCESS,
  LEAVETRACKHISTORY_ADD_FAILED,
  LEAVETRACKHISTORY_UPDATE,
  LEAVETRACKHISTORY_UPDATE_SUCCESS,
  LEAVETRACKHISTORY_UPDATE_FAILED,
  LEAVETRACKHISTORY_DELETE,
  LEAVETRACKHISTORY_DELETE_SUCCESS,
  LEAVETRACKHISTORY_DELETE_FAILED,
  LEAVETRACKHISTORY_DELETE_ALL,
  LEAVETRACKHISTORY_DELETE_ALL_SUCCESS,
  LEAVETRACKHISTORY_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllLeaveTrackHistories = () => {
  return {
    type: LEAVETRACKHISTORIES_SELECT_ALL
  }
}

export const unselectAllLeaveTrackHistories = () => {
  return {
    type: LEAVETRACKHISTORIES_UNSELECT_ALL
  }
}

export const selectLeaveTrackHistory = data => {
  return {
    type: LEAVETRACKHISTORY_SELECTED,
    payload: data
  }
}

export const loadLeaveTrackHistories = (data) => {
  return {
    type: LEAVETRACKHISTORIES_LOAD,
    payload: data
  };
}

export const loadLeaveTrackHistoriesSuccess = (data) => {
  return {
    type: LEAVETRACKHISTORIES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadLeaveTrackHistoriesFailed = () => {
  return {
    type: LEAVETRACKHISTORIES_LOAD_FAILED
  };
}

export const loadLeaveTrackHistory = () => {
  return {
    type: LEAVETRACKHISTORY_LOAD
  };
}

export const loadLeaveTrackHistorySuccess = (data) => {
  return {
    type: LEAVETRACKHISTORY_LOAD_SUCCESS,
    payload: data
  };
}


export const loadLeaveTrackHistoryFailed = () => {
  return {
    type: LEAVETRACKHISTORY_LOAD_FAILED
  };
}


export const addLeaveTrackHistory = (data) => {
  return {
    type: LEAVETRACKHISTORY_ADD,
    payload: data
  };
}


export const addLeaveTrackHistorySuccess = (filter) => {
  return {
    type: LEAVETRACKHISTORY_ADD_SUCCESS,
    message: 'LeaveTrackHistory added successfully.',
    payload: filter
  };
}

export const addLeaveTrackHistoryFailed = () => {
  return {
    type: LEAVETRACKHISTORY_ADD_FAILED
  };
}

export const updateLeaveTrackHistory = (data) => {
  return {
    type: LEAVETRACKHISTORY_UPDATE,
    payload: data
  };
}


export const updateLeaveTrackHistorySuccess = (data) => {
  return {
    type: LEAVETRACKHISTORY_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateLeaveTrackHistoryFailed = (data) => {
  return {
    type: LEAVETRACKHISTORY_UPDATE_FAILED,
    payload: data
  };
}

export const deleteLeaveTrackHistory = (data) => {
  return {
    type: LEAVETRACKHISTORY_DELETE,
    payload: data
  };
}

export const deleteLeaveTrackHistorySuccess = () => {
  return {
    type: LEAVETRACKHISTORY_DELETE_SUCCESS,
    message: 'LeaveTrackHistory deleted successfully.'
  };
}

export const deleteLeaveTrackHistoryFailed = () => {
  return {
    type: LEAVETRACKHISTORY_DELETE_FAILED
  };
}

export const deleteAllLeaveTrackHistories = (data) => {
  return {
    type: LEAVETRACKHISTORY_DELETE_ALL,
    payload: data
  };
}

export const deleteAllLeaveTrackHistoriesSuccess = () => {
  return {
    type: LEAVETRACKHISTORY_DELETE_ALL_SUCCESS,
    message: 'LeaveTrackHistory all deleted successfully.'
  };
}

export const deleteAllLeaveTrackHistoriesFailed = () => {
  return {
    type: LEAVETRACKHISTORY_DELETE_ALL_FAILED
  };
}
