import {
  COMPANIES_SELECT_ALL,
  COMPANIES_UNSELECT_ALL,
  COMPANY_SELECTED,
  COMPANIES_LOAD,
  COMPANIES_LOAD_SUCCESS,
  COMPANIES_LOAD_FAILED,
  COMPANY_LOAD,
  COMPANY_LOAD_SUCCESS,
  COMPANY_LOAD_FAILED,
  COMPANY_ADD,
  COMPANY_ADD_SUCCESS,
  COMPANY_ADD_FAILED,
  COMPANY_UPDATE,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAILED,
  COMPANY_DELETE,
  COMPANY_DELETE_SUCCESS,
  COMPANY_DELETE_FAILED,
  COMPANY_DELETE_ALL,
  COMPANY_DELETE_ALL_SUCCESS,
  COMPANY_DELETE_ALL_FAILED,
  COMPANIES_EXPORT_EXCEL,
  COMPANIES_EXPORT_EXCEL_SUCCESS,
  COMPANIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllCompanies = () => {
  return {
    type: COMPANIES_SELECT_ALL
  }
}

export const unselectAllCompanies = () => {
  return {
    type: COMPANIES_UNSELECT_ALL
  }
}

export const selectCompany = data => {
  return {
    type: COMPANY_SELECTED,
    payload: data
  }
}

export const loadCompanies = (data) => {
  return {
    type: COMPANIES_LOAD,
    payload: data
  };
}

export const loadCompaniesSuccess = (data) => {
  return {
    type: COMPANIES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadCompaniesFailed = () => {
  return {
    type: COMPANIES_LOAD_FAILED
  };
}

export const loadCompany = () => {
  return {
    type: COMPANY_LOAD
  };
}

export const loadCompanySuccess = (data) => {
  return {
    type: COMPANY_LOAD_SUCCESS,
    payload: data
  };
}


export const loadCompanyFailed = () => {
  return {
    type: COMPANY_LOAD_FAILED
  };
}


export const addCompany = (data) => {
  return {
    type: COMPANY_ADD,
    payload: data
  };
}


export const addCompanySuccess = (filter) => {
  return {
    type: COMPANY_ADD_SUCCESS,
    message: 'Company added successfully.',
    payload: filter
  };
}

export const addCompanyFailed = () => {
  return {
    type: COMPANY_ADD_FAILED
  };
}

export const updateCompany = (data) => {
  return {
    type: COMPANY_UPDATE,
    payload: data
  };
}


export const updateCompanySuccess = (data) => {
  return {
    type: COMPANY_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateCompanyFailed = (data) => {
  return {
    type: COMPANY_UPDATE_FAILED,
    payload: data
  };
}

export const deleteCompany = (data) => {
  return {
    type: COMPANY_DELETE,
    payload: data
  };
}

export const deleteCompanySuccess = () => {
  return {
    type: COMPANY_DELETE_SUCCESS,
    message: 'Company deleted successfully.'
  };
}

export const deleteCompanyFailed = () => {
  return {
    type: COMPANY_DELETE_FAILED
  };
}

export const deleteAllCompanies = (data) => {
  return {
    type: COMPANY_DELETE_ALL,
    payload: data
  };
}

export const deleteAllCompaniesSuccess = () => {
  return {
    type: COMPANY_DELETE_ALL_SUCCESS,
    message: 'Company all deleted successfully.'
  };
}

export const deleteAllCompaniesFailed = () => {
  return {
    type: COMPANY_DELETE_ALL_FAILED
  };
}

export const exportExcelCompanies = (filter) => {
  return {
    type: COMPANIES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelCompaniesSuccess = (data) => {
  return {
    type: COMPANIES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelCompaniesFailed = () => {
  return {
    type: COMPANIES_EXPORT_EXCEL_FAILED
  };
}