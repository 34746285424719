import {
  PERSONALPAPERS_SELECT_ALL,
  PERSONALPAPERS_UNSELECT_ALL,
  PERSONALPAPER_SELECTED,
  PERSONALPAPERS_LOAD,
  PERSONALPAPERS_LOAD_SUCCESS,
  PERSONALPAPERS_LOAD_FAILED,
  PERSONALPAPER_LOAD,
  PERSONALPAPER_LOAD_SUCCESS,
  PERSONALPAPER_LOAD_FAILED,
  PERSONALPAPER_ADD,
  PERSONALPAPER_ADD_SUCCESS,
  PERSONALPAPER_ADD_FAILED,
  PERSONALPAPER_UPDATE,
  PERSONALPAPER_UPDATE_SUCCESS,
  PERSONALPAPER_UPDATE_FAILED,
  PERSONALPAPER_DELETE,
  PERSONALPAPER_DELETE_SUCCESS,
  PERSONALPAPER_DELETE_FAILED,
  PERSONALPAPER_DELETE_ALL,
  PERSONALPAPER_DELETE_ALL_SUCCESS,
  PERSONALPAPER_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedPersonalPaper: null,
  selectedPersonalPaperId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'name',
    filterGroups: null
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PERSONALPAPERS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case PERSONALPAPERS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case PERSONALPAPER_SELECTED:
      return {
        ...state,
        selectedPersonalPaper: action.payload,
        selectedPersonalPaperId: action.payload.id
      };
    case PERSONALPAPERS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case PERSONALPAPERS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case PERSONALPAPERS_LOAD_FAILED:
      return {
        ...state
      };
    case PERSONALPAPER_LOAD:
      return {
        ...state
      };
    case PERSONALPAPER_LOAD_SUCCESS:
      return {
        ...state
      };
    case PERSONALPAPER_LOAD_FAILED:
      return {
        ...state
      };
    case PERSONALPAPER_ADD:
      return {
        ...state
      };
    case PERSONALPAPER_ADD_SUCCESS:
      return {
        ...state
      };
    case PERSONALPAPER_ADD_FAILED:
      return {
        ...state
      };
    case PERSONALPAPER_UPDATE:
      return {
        ...state
      };
    case PERSONALPAPER_UPDATE_SUCCESS:
      return {
        ...state
      };
    case PERSONALPAPER_UPDATE_FAILED:
      return {
        ...state
      };
    case PERSONALPAPER_DELETE:
      return {
        ...state
      };
    case PERSONALPAPER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case PERSONALPAPER_DELETE_FAILED:
      return {
        ...state
      };
    case PERSONALPAPER_DELETE_ALL:
      return {
        ...state
      };
    case PERSONALPAPER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case PERSONALPAPER_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
