import {
    TEMPSERIALNUMBERS_SELECT_ALL,
    TEMPSERIALNUMBERS_UNSELECT_ALL,
    TEMPSERIALNUMBER_SELECTED,
    TEMPSERIALNUMBERS_LOAD,
    TEMPSERIALNUMBERS_LOAD_SUCCESS,
    TEMPSERIALNUMBERS_LOAD_FAILED,
    TEMPSERIALNUMBER_LOAD,
    TEMPSERIALNUMBER_LOAD_SUCCESS,
    TEMPSERIALNUMBER_LOAD_FAILED,
    TEMPSERIALNUMBER_ADD,
    TEMPSERIALNUMBER_ADD_SUCCESS,
    TEMPSERIALNUMBER_ADD_FAILED,
    TEMPSERIALNUMBER_UPDATE,
    TEMPSERIALNUMBER_UPDATE_SUCCESS,
    TEMPSERIALNUMBER_UPDATE_FAILED,
    TEMPSERIALNUMBER_DELETE,
    TEMPSERIALNUMBER_DELETE_SUCCESS,
    TEMPSERIALNUMBER_DELETE_FAILED,
    TEMPSERIALNUMBER_DELETE_ALL,
    TEMPSERIALNUMBER_DELETE_ALL_SUCCESS,
    TEMPSERIALNUMBER_DELETE_ALL_FAILED,
    TEMPSERIALNUMBERS_EXPORT_EXCEL,
    TEMPSERIALNUMBERS_EXPORT_EXCEL_SUCCESS,
    TEMPSERIALNUMBERS_EXPORT_EXCEL_FAILED
  } from '../constants/ActionTypes';
  
  export const selectAllTempSerialNumbers = () => {
    return {
      type: TEMPSERIALNUMBERS_SELECT_ALL
    }
  }
  
  export const unselectAllTempSerialNumbers = () => {
    return {
      type: TEMPSERIALNUMBERS_UNSELECT_ALL
    }
  }
  
  export const selectTempSerialNumber = data => {
    return {
      type: TEMPSERIALNUMBER_SELECTED,
      payload: data
    }
  }
  
  export const loadTempSerialNumbers = (data) => {
    return {
      type: TEMPSERIALNUMBERS_LOAD,
      payload: data
    };
  }
  
  export const loadTempSerialNumbersSuccess = (data) => {
    return {
      type: TEMPSERIALNUMBERS_LOAD_SUCCESS,
      payload: data
    };
  }
  
  export const loadTempSerialNumbersFailed = () => {
    return {
      type: TEMPSERIALNUMBERS_LOAD_FAILED
    };
  }
  
  export const loadTempSerialNumber = () => {
    return {
      type: TEMPSERIALNUMBER_LOAD
    };
  }
  
  export const loadTempSerialNumberSuccess = (data) => {
    return {
      type: TEMPSERIALNUMBER_LOAD_SUCCESS,
      payload: data
    };
  }
  
  
  export const loadTempSerialNumberFailed = () => {
    return {
      type: TEMPSERIALNUMBER_LOAD_FAILED
    };
  }
  
  
  export const addTempSerialNumber = (data) => {
    return {
      type: TEMPSERIALNUMBER_ADD,
      payload: data
    };
  }
  
  
  export const addTempSerialNumberSuccess = (filter) => {
    return {
      type: TEMPSERIALNUMBER_ADD_SUCCESS,
      message: 'TempSerialNumber added successfully.',
      payload: filter
    };
  }
  
  export const addTempSerialNumberFailed = () => {
    return {
      type: TEMPSERIALNUMBER_ADD_FAILED
    };
  }
  
  export const updateTempSerialNumber = (data) => {
    return {
      type: TEMPSERIALNUMBER_UPDATE,
      payload: data
    };
  }
  
  
  export const updateTempSerialNumberSuccess = (data) => {
    return {
      type: TEMPSERIALNUMBER_UPDATE_SUCCESS,
      payload: data
    };
  }
  
  export const updateTempSerialNumberFailed = (data) => {
    return {
      type: TEMPSERIALNUMBER_UPDATE_FAILED,
      payload: data
    };
  }
  
  export const deleteTempSerialNumber = (data) => {
    return {
      type: TEMPSERIALNUMBER_DELETE,
      payload: data
    };
  }
  
  export const deleteTempSerialNumberSuccess = () => {
    return {
      type: TEMPSERIALNUMBER_DELETE_SUCCESS,
      message: 'TempSerialNumber deleted successfully.'
    };
  }
  
  export const deleteTempSerialNumberFailed = () => {
    return {
      type: TEMPSERIALNUMBER_DELETE_FAILED
    };
  }
  
  export const deleteAllTempSerialNumbers = (data) => {
    return {
      type: TEMPSERIALNUMBER_DELETE_ALL,
      payload: data
    };
  }
  
  export const deleteAllTempSerialNumbersSuccess = () => {
    return {
      type: TEMPSERIALNUMBER_DELETE_ALL_SUCCESS,
      message: 'TempSerialNumber all deleted successfully.'
    };
  }
  
  export const deleteAllTempSerialNumbersFailed = () => {
    return {
      type: TEMPSERIALNUMBER_DELETE_ALL_FAILED
    };
  }
  
  export const exportExcelTempSerialNumbers = (filter) => {
    return {
      type: TEMPSERIALNUMBERS_EXPORT_EXCEL,
      payload: filter
    } 
  }
  
  export const exportExcelTempSerialNumbersSuccess = (data) => {
    return {
      type: TEMPSERIALNUMBERS_EXPORT_EXCEL_SUCCESS,
      payload: data
    };
  }
  
  export const exportExcelTempSerialNumbersFailed = () => {
    return {
      type: TEMPSERIALNUMBERS_EXPORT_EXCEL_FAILED
    };
  }