import {
  ROLES_SELECT_ALL,
  ROLES_UNSELECT_ALL,
  ROLE_SELECTED,
  ROLES_LOAD,
  ROLES_LOAD_SUCCESS,
  ROLES_LOAD_FAILED,
  ROLE_LOAD,
  ROLE_LOAD_SUCCESS,
  ROLE_LOAD_FAILED,
  ROLE_ADD,
  ROLE_ADD_SUCCESS,
  ROLE_ADD_FAILED,
  ROLE_UPDATE,
  ROLE_UPDATE_SUCCESS,
  ROLE_UPDATE_FAILED,
  ROLE_DELETE,
  ROLE_DELETE_SUCCESS,
  ROLE_DELETE_FAILED,
  ROLE_DELETE_ALL,
  ROLE_DELETE_ALL_SUCCESS,
  ROLE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedRole: null,
  selectedRoleId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'name',
    filterGroups: [{
      filters: [{
        property: 'name',
        operator: 'contains',
        value: ''
      }]
    }]
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROLES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case ROLES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case ROLE_SELECTED:
      return {
        ...state,
        selectedRole: action.payload,
        selectedRoleId: action.payload.id
      };
    case ROLES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case ROLES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case ROLES_LOAD_FAILED:
      return {
        ...state
      };
    case ROLE_LOAD:
      return {
        ...state
      };
    case ROLE_LOAD_SUCCESS:
      return {
        ...state
      };
    case ROLE_LOAD_FAILED:
      return {
        ...state
      };
    case ROLE_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case ROLE_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case ROLE_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case ROLE_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case ROLE_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case ROLE_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case ROLE_DELETE:
      return {
        ...state
      };
    case ROLE_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case ROLE_DELETE_FAILED:
      return {
        ...state
      };
    case ROLE_DELETE_ALL:
      return {
        ...state
      };
    case ROLE_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case ROLE_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
