import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadNotifications as loadNotificationsAction,
  loadNotificationsSuccess,
  addNotificationSuccess,
  updateNotificationSuccess,
  countNotificationsSuccess
} from '../actions/Notification';

import {
  NOTIFICATIONS_LOAD,
  NOTIFICATION_ADD,
  NOTIFICATION_UPDATE,
  NOTIFICATION_DELETE,
  NOTIFICATION_DELETE_ALL,
  NOTIFICATIONS_COUNT,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getNotificationById = (id, callback) => {
  Api().get('notification/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllNotifications = (callback) => {
  Api().post('notification/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

const loadNotificationsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`notification/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`notification`, { params })
    .then(data => data)
    .catch(error => error);
}

const countNotificationsRequest = async (params) => {
  return await Api().post(`notification/count`, params)
    .then(data => data)
    .catch(error => error);
}

const addNotificationRequest = async (payload) =>
  await Api().post(`notification`, payload)
    .then(data => data)
    .catch(error => error);

const updateNotificationRequest = async (payload) =>
  await Api().patch(`notification/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteNotificationRequest = async (id) =>
  await Api().delete(`notification/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadNotifications({ payload, isNotify }) {
  try {
    const data = yield call(loadNotificationsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadNotificationsSuccess(data.data, isNotify));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadNotifications() {
  yield takeEvery(NOTIFICATIONS_LOAD, loadNotifications);
}

function* countNotifications({ payload }) {
  try {
    const data = yield call(countNotificationsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(countNotificationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processCountNotifications() {
  yield takeEvery(NOTIFICATIONS_COUNT, countNotifications);
}

function* loadNotification({ selectedNotificationId }) {
  try {
    const data = yield call(loadNotificationsRequest, selectedNotificationId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadNotificationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadNotification() {
  yield takeEvery(NOTIFICATIONS_LOAD, loadNotification);
}


function* addNotification({ payload }) {
  try {
    const data = yield call(addNotificationRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(addNotificationSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddNotification() {
  yield takeEvery(NOTIFICATION_ADD, addNotification);
}


function* updateNotification({ payload }) {
  try {
    const data = yield call(updateNotificationRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(updateNotificationSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateNotification() {
  yield takeEvery(NOTIFICATION_UPDATE, updateNotification);
}

function* deleteNotification({ payload }) {
  try {
    const data = yield call(deleteNotificationRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
      yield put(loadNotificationsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllNotifications({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteNotificationRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadNotificationsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteNotification() {
  yield takeEvery(NOTIFICATION_DELETE, deleteNotification);
}

function* processDeleteAllNotifications() {
  yield takeEvery(NOTIFICATION_DELETE_ALL, deleteAllNotifications);
}

export default function* NotificationSagas() {
  yield all([fork(processLoadNotifications),
  fork(processCountNotifications),
  fork(processLoadNotification),
  fork(processAddNotification),
  fork(processUpdateNotification),
  fork(processDeleteAllNotifications),
  fork(processDeleteNotification)]);
}
