import {
	USER_SEARCH,
	USER_SEARCH_SUCCESS,
	USER_ASSIGN_ROLES,
	USER_ASSIGN_ROLES_SUCCESS,
	USER_LOCK,
	USER_LOCK_SUCCESS,
	USER_UNLOCK,
	USER_UNLOCK_SUCCESS,
	USER_CHANGE_LOCK_RELOAD,
	USER_ASSIGN_EMPLOYEE,
	USER_ASSIGN_EMPLOYEE_SUCCESS
} from '../constants/ActionTypes';

export const searchUsers = (data) => {
	return {
		type: USER_SEARCH,
		payload: data
	};
}


export const searchUsersSuccess = (data) => {
	return {
		type: USER_SEARCH_SUCCESS,
		payload: data
	};
}

export const assignUserToRoles = (data) => {
	return {
		type: USER_ASSIGN_ROLES,
		payload: data
	};
}

export const assignUserToRolesSuccess = () => {
	return {
		type: USER_ASSIGN_ROLES_SUCCESS
	};
}

export const assignEmployeeToUser = (data) => {
	return {
		type: USER_ASSIGN_EMPLOYEE,
		payload: data
	};
}

export const assignEmployeeToUserSuccess = () => {
	return {
		type: USER_ASSIGN_EMPLOYEE_SUCCESS
	};
}

export const lockUser = (data) => {
	return {
		type: USER_LOCK,
		payload: data
	};
}

export const lockUserSuccess = () => {
	return {
		type: USER_LOCK_SUCCESS
	};
}

export const unlockUser = (data) => {
	return {
		type: USER_UNLOCK,
		payload: data
	};
}

export const unlockUserSuccess = () => {
	return {
		type: USER_UNLOCK_SUCCESS
	};
}

export const changeLockReload = () => {
	return {
		type: USER_CHANGE_LOCK_RELOAD
	}
}

export const changeAssignEmployee = () => {
	return {
		type: USER_ASSIGN_EMPLOYEE
	}
}