import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadWorkLocations as loadWorkLocationsAction,
  loadWorkLocationsSuccess,
  updateWorkLocationSuccess,
  addWorkLocationSuccess,
  deleteWorkLocationSuccess,
  deleteAllWorkLocationsSuccess
} from '../actions/WorkLocation';

import {
  WORKLOCATIONS_LOAD,
  WORKLOCATION_ADD,
  WORKLOCATION_UPDATE,
  WORKLOCATION_DELETE,
  WORKLOCATION_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getWorkLocationById = (id, callback) => {
  Api().get('work-location/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllWorkLocations = (callback) => {
  Api().post('work-location/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getWorkLocationsByDepartment = (type, department, callback) => {
  Api().post('work-location/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'departmentId',
        operator: 'equal',
        value: type === 'firstLoad' ? department.id : department.departmentId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getWorkLocationByReportTo = (reportTo, callback) => {
  let filterGroups = [{
    filters: [{
      property: 'reportTo',
      operator: 'equal',
      value: reportTo
    }]
  }]
  Api().post(`work-location/search`, { filterGroups: filterGroups })
    .then(response => {
      callback(response.data.records);
    })
    .catch(error => error);
}

const loadWorkLocationsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`work-location/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`work-location`, { params })
    .then(data => data)
    .catch(error => error);
}

const addWorkLocationRequest = async (payload) =>
  await Api().post(`work-location`, payload)
    .then(data => data)
    .catch(error => error);

const updateWorkLocationRequest = async (payload) =>
  await Api().patch(`work-location/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteWorkLocationRequest = async (id) =>
  await Api().delete(`work-location/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadWorkLocations({ payload }) {
  try {
    const data = yield call(loadWorkLocationsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadWorkLocationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadWorkLocations() {
  yield takeEvery(WORKLOCATIONS_LOAD, loadWorkLocations);
}

function* loadWorkLocation({ selectedWorkLocationId }) {
  try {
    const data = yield call(loadWorkLocationsRequest, selectedWorkLocationId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadWorkLocationsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadWorkLocation() {
  yield takeEvery(WORKLOCATIONS_LOAD, loadWorkLocation);
}

function* addWorkLocation({ payload }) {
  try {
    const data = yield call(addWorkLocationRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addWorkLocationSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddWorkLocation() {
  yield takeEvery(WORKLOCATION_ADD, addWorkLocation);
}

function* updateWorkLocation({ payload }) {
  try {
    const data = yield call(updateWorkLocationRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateWorkLocationSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateWorkLocation() {
  yield takeEvery(WORKLOCATION_UPDATE, updateWorkLocation);
}

function* deleteWorkLocation({ payload }) {
  try {
    const data = yield call(deleteWorkLocationRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(deleteWorkLocationSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllWorkLocations({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteWorkLocationRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllWorkLocationsSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteWorkLocation() {
  yield takeEvery(WORKLOCATION_DELETE, deleteWorkLocation);
}

function* processDeleteAllWorkLocations() {
  yield takeEvery(WORKLOCATION_DELETE_ALL, deleteAllWorkLocations);
}

export default function* WorkLocationSagas() {
  yield all([fork(processLoadWorkLocations),
  fork(processLoadWorkLocation),
  fork(processAddWorkLocation),
  fork(processUpdateWorkLocation),
  fork(processDeleteAllWorkLocations),
  fork(processDeleteWorkLocation)]);
}
