import {
  AGENCYFEES_SELECT_ALL,
  AGENCYFEES_UNSELECT_ALL,
  AGENCYFEE_SELECTED,
  AGENCYFEES_LOAD,
  AGENCYFEES_LOAD_SUCCESS,
  AGENCYFEES_LOAD_FAILED,
  AGENCYFEE_LOAD,
  AGENCYFEE_LOAD_SUCCESS,
  AGENCYFEE_LOAD_FAILED,
  AGENCYFEE_ADD,
  AGENCYFEE_ADD_SUCCESS,
  AGENCYFEE_ADD_FAILED,
  AGENCYFEE_UPDATE,
  AGENCYFEE_UPDATE_SUCCESS,
  AGENCYFEE_UPDATE_FAILED,
  AGENCYFEE_DELETE,
  AGENCYFEE_DELETE_SUCCESS,
  AGENCYFEE_DELETE_FAILED,
  AGENCYFEE_DELETE_ALL,
  AGENCYFEE_DELETE_ALL_SUCCESS,
  AGENCYFEE_DELETE_ALL_FAILED,
  AGENCYFEES_EXPORT_EXCEL,
  AGENCYFEES_EXPORT_EXCEL_SUCCESS,
  AGENCYFEES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllAgencyFees = () => {
  return {
    type: AGENCYFEES_SELECT_ALL
  }
}

export const unselectAllAgencyFees = () => {
  return {
    type: AGENCYFEES_UNSELECT_ALL
  }
}

export const selectAgencyFee = data => {
  return {
    type: AGENCYFEE_SELECTED,
    payload: data
  }
}

export const loadAgencyFees = (data) => {
  return {
    type: AGENCYFEES_LOAD,
    payload: data
  };
}

export const loadAgencyFeesSuccess = (data) => {
  return {
    type: AGENCYFEES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadAgencyFeesFailed = () => {
  return {
    type: AGENCYFEES_LOAD_FAILED
  };
}

export const loadAgencyFee = () => {
  return {
    type: AGENCYFEE_LOAD
  };
}

export const loadAgencyFeeSuccess = (data) => {
  return {
    type: AGENCYFEE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadAgencyFeeFailed = () => {
  return {
    type: AGENCYFEE_LOAD_FAILED
  };
}


export const addAgencyFee = (data) => {
  return {
    type: AGENCYFEE_ADD,
    payload: data
  };
}


export const addAgencyFeeSuccess = (filter) => {
  return {
    type: AGENCYFEE_ADD_SUCCESS,
    message: 'AgencyFee added successfully.',
    payload: filter
  };
}

export const addAgencyFeeFailed = () => {
  return {
    type: AGENCYFEE_ADD_FAILED
  };
}

export const updateAgencyFee = (data) => {
  return {
    type: AGENCYFEE_UPDATE,
    payload: data
  };
}


export const updateAgencyFeeSuccess = (data) => {
  return {
    type: AGENCYFEE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateAgencyFeeFailed = (data) => {
  return {
    type: AGENCYFEE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteAgencyFee = (data) => {
  return {
    type: AGENCYFEE_DELETE,
    payload: data
  };
}

export const deleteAgencyFeeSuccess = () => {
  return {
    type: AGENCYFEE_DELETE_SUCCESS,
    message: 'AgencyFee deleted successfully.'
  };
}

export const deleteAgencyFeeFailed = () => {
  return {
    type: AGENCYFEE_DELETE_FAILED
  };
}

export const deleteAllAgencyFees = (data) => {
  return {
    type: AGENCYFEE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllAgencyFeesSuccess = () => {
  return {
    type: AGENCYFEE_DELETE_ALL_SUCCESS,
    message: 'AgencyFee all deleted successfully.'
  };
}

export const deleteAllAgencyFeesFailed = () => {
  return {
    type: AGENCYFEE_DELETE_ALL_FAILED
  };
}

export const exportExcelAgencyFees = (filter) => {
  return {
    type:AGENCYFEES_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelAgencyFeesSuccess = (data) => {
  return {
    type:AGENCYFEES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelAgencyFeesFailed = () => {
  return {
    type:AGENCYFEES_EXPORT_EXCEL_FAILED
  };
}
