import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadPayments as loadPaymentsAction,
  loadPaymentsSuccess,
  addPaymentSuccess,
  updatePaymentSuccess,
  exportExcelPayments,
  exportExcelPaymentsSuccess,
  exportExcelPaymentsFailed
} from '../actions/Payment';

import {
  PAYMENTS_LOAD,
  PAYMENT_ADD,
  PAYMENT_UPDATE,
  PAYMENT_DELETE,
  PAYMENT_DELETE_ALL,
  PAYMENTS_EXPORT_EXCEL,
  PAYMENTS_EXPORT_EXCEL_SUCCESS,
  PAYMENTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getPaymentById = (id, callback) => {
  Api().get('payment/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllPayments = (callback) => {
  Api().post('payment/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getMaxPaymentCodeSorted = (callback) => {
  Api().post('payment/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadPaymentsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`payment/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`payment`, { params })
    .then(data => data)
    .catch(error => error);
}

const addPaymentRequest = async (payload) =>
  await Api().post(`payment`, payload)
    .then(data => data)
    .catch(error => error);

const updatePaymentRequest = async (payload) =>
  await Api().patch(`payment/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deletePaymentRequest = async (id) =>
  await Api().delete(`payment/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadPayments({ payload }) {
  try {
    const data = yield call(loadPaymentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPaymentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadPayments() {
  yield takeEvery(PAYMENTS_LOAD, loadPayments);
}

function* loadPayment({ selectedPaymentId }) {
  try {
    const data = yield call(loadPaymentsRequest, selectedPaymentId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPaymentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadPayment() {
  yield takeEvery(PAYMENTS_LOAD, loadPayment);
}


function* addPayment({ payload }) {
  try {
    const data = yield call(addPaymentRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addPaymentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddPayment() {
  yield takeEvery(PAYMENT_ADD, addPayment);
}


function* updatePayment({ payload }) {
  try {
    const data = yield call(updatePaymentRequest, payload.model);
    
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updatePaymentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdatePayment() {
  yield takeEvery(PAYMENT_UPDATE, updatePayment);
}

function* deletePayment({ payload }) {
  try {
    const data = yield call(deletePaymentRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadPaymentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllPayments({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deletePaymentRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadPaymentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeletePayment() {
  yield takeEvery(PAYMENT_DELETE, deletePayment);
}

function* processDeleteAllPayments() {
  yield takeEvery(PAYMENT_DELETE_ALL, deleteAllPayments);
}



const exportPaymentsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`payment/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* exportPayments({ payload }) {
  try {
    const data = yield call(exportPaymentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelPaymentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportPayments() {
  yield takeEvery(PAYMENTS_EXPORT_EXCEL, exportPayments);
}

export default function* PaymentSagas() {
  yield all([fork(processLoadPayments),
  fork(processLoadPayment),
  fork(processAddPayment),
  fork(processUpdatePayment),
  fork(processDeleteAllPayments),
  fork(processDeletePayment),
  fork(processExportPayments)
]);
}
