import {
  SHOW_LOADING,
  HIDE_LOADING,
	SAVE_USER,
	SAVE_USER_SUCCESS,
	SAVE_EMPLOYEE,
	SAVE_EMPLOYEE_SUCCESS,
	DIRECT_DATA,
  CLEAR_DIRECT_DATA
} from '../constants/ActionTypes';

export const showLoading = () => {
	return {
		type: SHOW_LOADING
	}
}

export const hideLoading = () => {
	return {
		type: HIDE_LOADING
	}
}

export const saveUser = (userId) => {
	return {
		type: SAVE_USER,
		payload: userId
	}
}

export const saveUserSuccess = (user) => {
	return {
		type: SAVE_USER_SUCCESS,
		payload: user
	}
}

export const saveEmployee = (userId) => {
	return {
		type: SAVE_EMPLOYEE,
		payload: userId
	}
}

export const saveEmployeeSuccess = (employee) => {
	return {
		type: SAVE_EMPLOYEE_SUCCESS,
		payload: employee
	}
}

export const directData = (data) => {
	return {
		type: DIRECT_DATA,
		payload: data
	}
}

export const clearDirectData = () => {
	return {
		type: CLEAR_DIRECT_DATA
	}
}
