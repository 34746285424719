import {
  LIBRARIES_SELECT_ALL,
  LIBRARIES_UNSELECT_ALL,
  LIBRARY_SELECTED,
  LIBRARIES_LOAD,
  LIBRARIES_LOAD_SUCCESS,
  LIBRARIES_LOAD_FAILED,
  LIBRARY_LOAD,
  LIBRARY_LOAD_SUCCESS,
  LIBRARY_LOAD_FAILED,
  LIBRARY_ADD,
  LIBRARY_ADD_SUCCESS,
  LIBRARY_ADD_FAILED,
  LIBRARY_UPDATE,
  LIBRARY_UPDATE_SUCCESS,
  LIBRARY_UPDATE_FAILED,
  LIBRARY_DELETE,
  LIBRARY_DELETE_SUCCESS,
  LIBRARY_DELETE_FAILED,
  LIBRARY_DELETE_ALL,
  LIBRARY_DELETE_ALL_SUCCESS,
  LIBRARY_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedLibrary: null,
  selectedLibraryId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'codeSorted',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIBRARIES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case LIBRARIES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case LIBRARY_SELECTED:
      return {
        ...state,
        selectedLibrary: action.payload,
        selectedLibraryId: action.payload.id
      };
    case LIBRARIES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case LIBRARIES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case LIBRARIES_LOAD_FAILED:
      return {
        ...state
      };
    case LIBRARY_LOAD:
      return {
        ...state
      };
    case LIBRARY_LOAD_SUCCESS:
      return {
        ...state
      };
    case LIBRARY_LOAD_FAILED:
      return {
        ...state
      };
    case LIBRARY_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case LIBRARY_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case LIBRARY_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case LIBRARY_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case LIBRARY_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case LIBRARY_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case LIBRARY_DELETE:
      return {
        ...state
      };
    case LIBRARY_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case LIBRARY_DELETE_FAILED:
      return {
        ...state
      };
    case LIBRARY_DELETE_ALL:
      return {
        ...state
      };
    case LIBRARY_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case LIBRARY_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
