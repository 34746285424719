import {
  SETTINGS_SELECT_ALL,
  SETTINGS_UNSELECT_ALL,
  SETTING_SELECTED,
  SETTINGS_LOAD,
  SETTINGS_LOAD_SUCCESS,
  SETTINGS_LOAD_FAILED,
  SETTING_LOAD,
  SETTING_LOAD_SUCCESS,
  SETTING_LOAD_FAILED,
  SETTING_ADD,
  SETTING_ADD_SUCCESS,
  SETTING_ADD_FAILED,
  SETTING_UPDATE,
  SETTING_UPDATE_SUCCESS,
  SETTING_UPDATE_FAILED,
  SETTING_DELETE,
  SETTING_DELETE_SUCCESS,
  SETTING_DELETE_FAILED,
  SETTING_DELETE_ALL,
  SETTING_DELETE_ALL_SUCCESS,
  SETTING_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedSetting: null,
  selectedSettingId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'name',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SETTINGS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case SETTINGS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case SETTING_SELECTED:
      return {
        ...state,
        selectedSetting: action.payload,
        selectedSettingId: action.payload.id
      };
    case SETTINGS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case SETTINGS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case SETTINGS_LOAD_FAILED:
      return {
        ...state
      };
    case SETTING_LOAD:
      return {
        ...state
      };
    case SETTING_LOAD_SUCCESS:
      return {
        ...state
      };
    case SETTING_LOAD_FAILED:
      return {
        ...state
      };
    case SETTING_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case SETTING_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SETTING_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SETTING_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case SETTING_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SETTING_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SETTING_DELETE:
      return {
        ...state
      };
    case SETTING_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SETTING_DELETE_FAILED:
      return {
        ...state
      };
    case SETTING_DELETE_ALL:
      return {
        ...state
      };
    case SETTING_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SETTING_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
