import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadAgencyFees as loadAgencyFeesAction,
  loadAgencyFeesSuccess,
  addAgencyFeeSuccess,
  updateAgencyFeeSuccess,
  exportExcelAgencyFees,
  exportExcelAgencyFeesSuccess,
  exportExcelAgencyFeesFailed
} from '../actions/AgencyFee';

import {
  AGENCYFEES_LOAD,
  AGENCYFEE_ADD,
  AGENCYFEE_UPDATE,
  AGENCYFEE_DELETE,
  AGENCYFEE_DELETE_ALL,
  AGENCYFEES_EXPORT_EXCEL,
  AGENCYFEES_EXPORT_EXCEL_SUCCESS,
  AGENCYFEES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getAgencyFeeById = (id, callback) => {
  Api().get('agencyFee/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllAgencyFees = (callback) => {
  Api().post('agencyFee/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getAgencyFeesByCompany = (type, company, callback) => {
  Api().post('agencyFee/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxAgencyFeeId = (callback) => {
  Api().post('agencyFee/search', { offset: 0,
    limit: 1,
    orderBy: "id",
    descending: true
  })
    .then(response => {
      let id = 1;
      let records = response.data.records;
      if (records.length) {
        id = records[0].id;
      }
      callback(id);
    })
}

const loadAgencyFeesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`agencyFee/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`agencyFee`, { params })
    .then(data => data)
    .catch(error => error);
}

const addAgencyFeeRequest = async (payload) =>
  await Api().post(`agencyFee`, payload)
    .then(data => data)
    .catch(error => error);

const updateAgencyFeeRequest = async (payload) =>
  await Api().patch(`agencyFee/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteAgencyFeeRequest = async (id) =>
  await Api().delete(`agencyFee/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadAgencyFees({ payload }) {
  try {
    const data = yield call(loadAgencyFeesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadAgencyFeesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadAgencyFees() {
  yield takeEvery(AGENCYFEES_LOAD, loadAgencyFees);
}

function* loadAgencyFee({ selectedAgencyFeeId }) {
  try {
    const data = yield call(loadAgencyFeesRequest, selectedAgencyFeeId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadAgencyFeesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadAgencyFee() {
  yield takeEvery(AGENCYFEES_LOAD, loadAgencyFee);
}


function* addAgencyFee({ payload }) {
  try {
    const data = yield call(addAgencyFeeRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addAgencyFeeSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddAgencyFee() {
  yield takeEvery(AGENCYFEE_ADD, addAgencyFee);
}


function* updateAgencyFee({ payload }) {
  try {
    const data = yield call(updateAgencyFeeRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateAgencyFeeSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateAgencyFee() {
  yield takeEvery(AGENCYFEE_UPDATE, updateAgencyFee);
}

function* deleteAgencyFee({ payload }) {
  try {
    const data = yield call(deleteAgencyFeeRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadAgencyFeesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllAgencyFees({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteAgencyFeeRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadAgencyFeesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteAgencyFee() {
  yield takeEvery(AGENCYFEE_DELETE, deleteAgencyFee);
}

function* processDeleteAllAgencyFees() {
  yield takeEvery(AGENCYFEE_DELETE_ALL, deleteAllAgencyFees);
}


const exportAgencyFeesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`agencyFee/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportAgencyFees({ payload }) {
  try {
    const data = yield call(exportAgencyFeesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelAgencyFeesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportAgencyFees() {
  yield takeEvery(AGENCYFEES_EXPORT_EXCEL, exportAgencyFees);
}

export default function* AgencyFeeSagas() {
  yield all([fork(processLoadAgencyFees),
  fork(processLoadAgencyFee),
  fork(processAddAgencyFee),
  fork(processUpdateAgencyFee),
  fork(processDeleteAllAgencyFees),
  fork(processDeleteAgencyFee),
  fork(processExportAgencyFees)
]);
}
