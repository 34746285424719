import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
  loadLeaveTrackHistories as loadLeaveTrackHistoriesAction,
  loadLeaveTrackHistoriesSuccess,
  updateLeaveTrackHistorySuccess,
  addLeaveTrackHistorySuccess,
  deleteLeaveTrackHistorySuccess,
  deleteAllLeaveTrackHistoriesSuccess
} from '../actions/LeaveTrackHistory';

import {
  LEAVETRACKHISTORIES_LOAD,
  LEAVETRACKHISTORY_ADD,
  LEAVETRACKHISTORY_UPDATE,
  LEAVETRACKHISTORY_DELETE,
  LEAVETRACKHISTORY_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getLeaveTrackHistoryById = (id, callback) => {
  Api().get('leave-track-history/' + id).then(response => {
    callback(response.data);
  })
}

export const changeLeaveTrackHistoryStatus = (model, callback) => {
  Api().post('leave-track-history/change-status', model).then(response => {
    callback(response);
  })
}

const loadLeaveTrackHistoriesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`leave-track-history/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`leave-track-history`, { params })
    .then(data => data)
    .catch(error => error);
}

const addLeaveTrackHistoryRequest = async (payload) =>
  await Api().post(`leave-track-history`, payload)
    .then(data => data)
    .catch(error => error);

const updateLeaveTrackHistoryRequest = async (payload) =>
  await Api().patch(`leave-track-history/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteLeaveTrackHistoryRequest = async (id) =>
  await Api().delete(`leave-track-history/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadLeaveTrackHistories({ payload }) {
  try {
    const data = yield call(loadLeaveTrackHistoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadLeaveTrackHistoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadLeaveTrackHistories() {
  yield takeEvery(LEAVETRACKHISTORIES_LOAD, loadLeaveTrackHistories);
}

function* loadLeaveTrackHistory({ selectedLeaveTrackHistoryId }) {
  try {
    const data = yield call(loadLeaveTrackHistoriesRequest, selectedLeaveTrackHistoryId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadLeaveTrackHistoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadLeaveTrackHistory() {
  yield takeEvery(LEAVETRACKHISTORIES_LOAD, loadLeaveTrackHistory);
}

function* addLeaveTrackHistory({ payload }) {
  try {
    const data = yield call(addLeaveTrackHistoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addLeaveTrackHistorySuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddLeaveTrackHistory() {
  yield takeEvery(LEAVETRACKHISTORY_ADD, addLeaveTrackHistory);
}

function* updateLeaveTrackHistory({ payload }) {
  try {
    const data = yield call(updateLeaveTrackHistoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateLeaveTrackHistorySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateLeaveTrackHistory() {
  yield takeEvery(LEAVETRACKHISTORY_UPDATE, updateLeaveTrackHistory);
}

function* deleteLeaveTrackHistory({ payload }) {
  try {
    const data = yield call(deleteLeaveTrackHistoryRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(deleteLeaveTrackHistorySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllLeaveTrackHistories({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteLeaveTrackHistoryRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllLeaveTrackHistoriesSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteLeaveTrackHistory() {
  yield takeEvery(LEAVETRACKHISTORY_DELETE, deleteLeaveTrackHistory);
}

function* processDeleteAllLeaveTrackHistories() {
  yield takeEvery(LEAVETRACKHISTORY_DELETE_ALL, deleteAllLeaveTrackHistories);
}

export default function* LeaveTrackHistorySagas() {
  yield all([fork(processLoadLeaveTrackHistories),
  fork(processLoadLeaveTrackHistory),
  fork(processAddLeaveTrackHistory),
  fork(processUpdateLeaveTrackHistory),
  fork(processDeleteAllLeaveTrackHistories),
  fork(processDeleteLeaveTrackHistory)]);
}
