import {
  JOBS_SELECT_ALL,
  JOBS_UNSELECT_ALL,
  JOB_SELECTED,
  JOBS_LOAD,
  JOBS_LOAD_SUCCESS,
  JOBS_LOAD_FAILED,
  JOB_LOAD,
  JOB_LOAD_SUCCESS,
  JOB_LOAD_FAILED,
  JOB_ADD,
  JOB_ADD_SUCCESS,
  JOB_ADD_FAILED,
  JOB_UPDATE,
  JOB_UPDATE_SUCCESS,
  JOB_UPDATE_FAILED,
  JOB_DELETE,
  JOB_DELETE_SUCCESS,
  JOB_DELETE_FAILED,
  JOB_DELETE_ALL,
  JOB_DELETE_ALL_SUCCESS,
  JOB_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedJob: null,
  selectedJobId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case JOBS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case JOBS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case JOB_SELECTED:
      return {
        ...state,
        selectedJob: action.payload,
        selectedJobId: action.payload.id
      };
    case JOBS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case JOBS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case JOBS_LOAD_FAILED:
      return {
        ...state
      };
    case JOB_LOAD:
      return {
        ...state
      };
    case JOB_LOAD_SUCCESS:
      return {
        ...state
      };
    case JOB_LOAD_FAILED:
      return {
        ...state
      };
    case JOB_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case JOB_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case JOB_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case JOB_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case JOB_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case JOB_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case JOB_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case JOB_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case JOB_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case JOB_DELETE_ALL:
      return {
        ...state
      };
    case JOB_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case JOB_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
