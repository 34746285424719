import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadJobs as loadJobsAction,
  loadJobsSuccess,
  updateJobSuccess,
  addJobSuccess,
  deleteJobSuccess,
  deleteAllJobsSuccess
} from '../actions/Job';

import {
  JOBS_LOAD,
  JOB_ADD,
  JOB_UPDATE,
  JOB_DELETE,
  JOB_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getJobById = (id, callback) => {
  Api().get('job/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllJobs = (callback) => {
  Api().post('job/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadJobsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`job/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`job`, { params })
    .then(data => data)
    .catch(error => error);
}

const addJobRequest = async (payload) =>
  await Api().post(`job`, payload)
    .then(data => data)
    .catch(error => error);

const updateJobRequest = async (payload) =>
  await Api().patch(`job/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteJobRequest = async (id) =>
  await Api().delete(`job/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadJobs({ payload }) {
  try {
    const data = yield call(loadJobsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadJobsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadJobs() {
  yield takeEvery(JOBS_LOAD, loadJobs);
}

function* loadJob({ selectedJobId }) {
  try {
    const data = yield call(loadJobsRequest, selectedJobId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadJobsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadJob() {
  yield takeEvery(JOBS_LOAD, loadJob);
}

function* addJob({ payload }) {
  try {
    const data = yield call(addJobRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addJobSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddJob() {
  yield takeEvery(JOB_ADD, addJob);
}

function* updateJob({ payload }) {
  try {
    const data = yield call(updateJobRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateJobSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateJob() {
  yield takeEvery(JOB_UPDATE, updateJob);
}

function* deleteJob({ payload }) {
  try {
    const data = yield call(deleteJobRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(deleteJobSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllJobs({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteJobRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllJobsSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteJob() {
  yield takeEvery(JOB_DELETE, deleteJob);
}

function* processDeleteAllJobs() {
  yield takeEvery(JOB_DELETE_ALL, deleteAllJobs);
}

export default function* JobSagas() {
  yield all([fork(processLoadJobs),
  fork(processLoadJob),
  fork(processAddJob),
  fork(processUpdateJob),
  fork(processDeleteAllJobs),
  fork(processDeleteJob)]);
}
