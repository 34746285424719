import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadDepartments as loadDepartmentsAction,
  loadDepartmentsSuccess,
  addDepartmentSuccess,
  updateDepartmentSuccess
} from '../actions/Department';

import {
  DEPARTMENTS_LOAD,
  DEPARTMENT_ADD,
  DEPARTMENT_UPDATE,
  DEPARTMENT_DELETE,
  DEPARTMENT_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getDepartmentById = (id, callback) => {
  Api().get('department/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllDepartments = (callback) => {
  Api().post('department/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getDepartmentsByCompany = (type, company, callback) => {
  Api().post('department/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

const loadDepartmentsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`department/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`department`, { params })
    .then(data => data)
    .catch(error => error);
}


const addDepartmentRequest = async (payload) =>
  await Api().post(`department`, payload)
    .then(data => data)
    .catch(error => error);

const updateDepartmentRequest = async (payload) =>
  await Api().patch(`department/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteDepartmentRequest = async (id) =>
  await Api().delete(`department/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadDepartments({ payload }) {
  try {
    const data = yield call(loadDepartmentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadDepartmentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}


function* processLoadDepartments() {
  yield takeEvery(DEPARTMENTS_LOAD, loadDepartments);
}

function* loadDepartment({ selectedDepartmentId }) {
  try {
    const data = yield call(loadDepartmentsRequest, selectedDepartmentId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadDepartmentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadDepartment() {
  yield takeEvery(DEPARTMENTS_LOAD, loadDepartment);
}


function* addDepartment({ payload }) {
  try {
    const data = yield call(addDepartmentRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addDepartmentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddDepartment() {
  yield takeEvery(DEPARTMENT_ADD, addDepartment);
}


function* updateDepartment({ payload }) {
  try {
    const data = yield call(updateDepartmentRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateDepartmentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateDepartment() {
  yield takeEvery(DEPARTMENT_UPDATE, updateDepartment);
}

function* deleteDepartment({ payload }) {
  try {
    const data = yield call(deleteDepartmentRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadDepartmentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllDepartments({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteDepartmentRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadDepartmentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteDepartment() {
  yield takeEvery(DEPARTMENT_DELETE, deleteDepartment);
}

function* processDeleteAllDepartments() {
  yield takeEvery(DEPARTMENT_DELETE_ALL, deleteAllDepartments);
}

export default function* DepartmentSagas() {
  yield all([fork(processLoadDepartments),
  fork(processLoadDepartment),
  fork(processAddDepartment),
  fork(processUpdateDepartment),
  fork(processDeleteAllDepartments),
  fork(processDeleteDepartment)]);
}
