import {
  APPROVALS_SELECT_ALL,
  APPROVALS_UNSELECT_ALL,
  APPROVAL_SELECTED,
  APPROVALS_LOAD,
  APPROVALS_LOAD_SUCCESS,
  APPROVALS_LOAD_FAILED,
  APPROVAL_LOAD,
  APPROVAL_LOAD_SUCCESS,
  APPROVAL_LOAD_FAILED,
  APPROVAL_ADD,
  APPROVAL_ADD_SUCCESS,
  APPROVAL_ADD_FAILED,
  APPROVAL_UPDATE,
  APPROVAL_UPDATE_SUCCESS,
  APPROVAL_UPDATE_FAILED,
  APPROVAL_DELETE,
  APPROVAL_DELETE_SUCCESS,
  APPROVAL_DELETE_FAILED,
  APPROVAL_DELETE_ALL,
  APPROVAL_DELETE_ALL_SUCCESS,
  APPROVAL_DELETE_ALL_FAILED,
  APPROVALS_EXPORT_EXCEL,
  APPROVALS_EXPORT_EXCEL_SUCCESS,
  APPROVALS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllApprovals = () => {
  return {
    type: APPROVALS_SELECT_ALL
  }
}

export const unselectAllApprovals = () => {
  return {
    type: APPROVALS_UNSELECT_ALL
  }
}

export const selectApproval = data => {
  return {
    type: APPROVAL_SELECTED,
    payload: data
  }
}

export const loadApprovals = (data) => {
  return {
    type: APPROVALS_LOAD,
    payload: data
  };
}

export const loadApprovalsSuccess = (data) => {
  return {
    type: APPROVALS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadApprovalsFailed = () => {
  return {
    type: APPROVALS_LOAD_FAILED
  };
}

export const loadApproval = () => {
  return {
    type: APPROVAL_LOAD
  };
}

export const loadApprovalSuccess = (data) => {
  return {
    type: APPROVAL_LOAD_SUCCESS,
    payload: data
  };
}


export const loadApprovalFailed = () => {
  return {
    type: APPROVAL_LOAD_FAILED
  };
}


export const addApproval = (data) => {
  return {
    type: APPROVAL_ADD,
    payload: data
  };
}


export const addApprovalSuccess = (filter) => {
  return {
    type: APPROVAL_ADD_SUCCESS,
    message: 'Approval added successfully.',
    payload: filter
  };
}

export const addApprovalFailed = () => {
  return {
    type: APPROVAL_ADD_FAILED
  };
}

export const updateApproval = (data) => {
  return {
    type: APPROVAL_UPDATE,
    payload: data
  };
}


export const updateApprovalSuccess = (data) => {
  return {
    type: APPROVAL_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateApprovalFailed = (data) => {
  return {
    type: APPROVAL_UPDATE_FAILED,
    payload: data
  };
}

export const deleteApproval = (data) => {
  return {
    type: APPROVAL_DELETE,
    payload: data
  };
}

export const deleteApprovalSuccess = () => {
  return {
    type: APPROVAL_DELETE_SUCCESS,
    message: 'Approval deleted successfully.'
  };
}

export const deleteApprovalFailed = () => {
  return {
    type: APPROVAL_DELETE_FAILED
  };
}

export const deleteAllApprovals = (data) => {
  return {
    type: APPROVAL_DELETE_ALL,
    payload: data
  };
}

export const deleteAllApprovalsSuccess = () => {
  return {
    type: APPROVAL_DELETE_ALL_SUCCESS,
    message: 'Approval all deleted successfully.'
  };
}

export const deleteAllApprovalsFailed = () => {
  return {
    type: APPROVAL_DELETE_ALL_FAILED
  };
}

export const exportExcelApprovals = (filter) => {
  return {
    type:APPROVALS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelApprovalsSuccess = (data) => {
  return {
    type:APPROVALS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelApprovalsFailed = () => {
  return {
    type:APPROVALS_EXPORT_EXCEL_FAILED
  };
}
