import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadSerialNumbers as loadSerialNumbersAction,
  loadSerialNumbersSuccess,
  addSerialNumberSuccess,
  updateSerialNumberSuccess,
  exportExcelSerialNumbersSuccess
} from '../actions/SerialNumber';

import {
  SERIALNUMBERS_LOAD,
  SERIALNUMBER_ADD,
  SERIALNUMBER_UPDATE,
  SERIALNUMBER_DELETE,
  SERIALNUMBER_DELETE_ALL,
  SERIALNUMBERS_EXPORT_EXCEL
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getSerialNumberById = (id, callback) => {
  Api().get('serial-number/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllSerialNumbers = (callback) => {
  Api().post('serial-number/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadSerialNumbersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`serial-number/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`serial-number`, { params })
    .then(data => data)
    .catch(error => error);
}


const addSerialNumberRequest = async (payload) =>
  await Api().post(`serial-number`, payload)
    .then(data => data)
    .catch(error => error);

const updateSerialNumberRequest = async (payload) =>
  await Api().patch(`serial-number/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteSerialNumberRequest = async (id) =>
  await Api().delete(`serial-number/${id}`)
    .then(data => data)
    .catch(error => error);

const exportSerialNumbersRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`serial-number/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* loadSerialNumbers({ payload }) {
  try {
    
    const data = yield call(loadSerialNumbersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSerialNumbersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSerialNumbers() {
  yield takeEvery(SERIALNUMBERS_LOAD, loadSerialNumbers);
}

function* loadSerialNumber({ selectedSerialNumberId }) {
  try {
    
    const data = yield call(loadSerialNumbersRequest, selectedSerialNumberId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSerialNumbersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadSerialNumber() {
  yield takeEvery(SERIALNUMBERS_LOAD, loadSerialNumber);
}


function* addSerialNumber({ payload }) {
  try {
    const data = yield call(addSerialNumberRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addSerialNumberSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddSerialNumber() {
  yield takeEvery(SERIALNUMBER_ADD, addSerialNumber);
}


function* updateSerialNumber({ payload }) {
  try {
    const data = yield call(updateSerialNumberRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateSerialNumberSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateSerialNumber() {
  yield takeEvery(SERIALNUMBER_UPDATE, updateSerialNumber);
}

function* deleteSerialNumber({ payload }) {
  try {
    const data = yield call(deleteSerialNumberRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadSerialNumbersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllSerialNumbers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteSerialNumberRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadSerialNumbersAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteSerialNumber() {
  yield takeEvery(SERIALNUMBER_DELETE, deleteSerialNumber);
}

function* processDeleteAllSerialNumbers() {
  yield takeEvery(SERIALNUMBER_DELETE_ALL, deleteAllSerialNumbers);
}

function* exportSerialNumbers({ payload }) {
  try {
    const data = yield call(exportSerialNumbersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelSerialNumbersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportSerialNumbers() {
  yield takeEvery(SERIALNUMBERS_EXPORT_EXCEL, exportSerialNumbers);
}

export default function* SerialNumberSagas() {
  yield all([fork(processLoadSerialNumbers),
  fork(processLoadSerialNumber),
  fork(processAddSerialNumber),
  fork(processUpdateSerialNumber),
  fork(processDeleteAllSerialNumbers),
  fork(processDeleteSerialNumber),
  fork(processExportSerialNumbers)]);
}
