import {
  JOBS_SELECT_ALL,
  JOBS_UNSELECT_ALL,
  JOB_SELECTED,
  JOBS_LOAD,
  JOBS_LOAD_SUCCESS,
  JOBS_LOAD_FAILED,
  JOB_LOAD,
  JOB_LOAD_SUCCESS,
  JOB_LOAD_FAILED,
  JOB_ADD,
  JOB_ADD_SUCCESS,
  JOB_ADD_FAILED,
  JOB_UPDATE,
  JOB_UPDATE_SUCCESS,
  JOB_UPDATE_FAILED,
  JOB_DELETE,
  JOB_DELETE_SUCCESS,
  JOB_DELETE_FAILED,
  JOB_DELETE_ALL,
  JOB_DELETE_ALL_SUCCESS,
  JOB_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllJobs = () => {
  return {
    type: JOBS_SELECT_ALL
  }
}

export const unselectAllJobs = () => {
  return {
    type: JOBS_UNSELECT_ALL
  }
}

export const selectJob = data => {
  return {
    type: JOB_SELECTED,
    payload: data
  }
}

export const loadJobs = (data) => {
  return {
    type: JOBS_LOAD,
    payload: data
  };
}

export const loadJobsSuccess = (data) => {
  return {
    type: JOBS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadJobsFailed = () => {
  return {
    type: JOBS_LOAD_FAILED
  };
}

export const loadJob = () => {
  return {
    type: JOB_LOAD
  };
}

export const loadJobSuccess = (data) => {
  return {
    type: JOB_LOAD_SUCCESS,
    payload: data
  };
}


export const loadJobFailed = () => {
  return {
    type: JOB_LOAD_FAILED
  };
}


export const addJob = (data) => {
  return {
    type: JOB_ADD,
    payload: data
  };
}


export const addJobSuccess = (filter) => {
  return {
    type: JOB_ADD_SUCCESS,
    message: 'Job added successfully.',
    payload: filter
  };
}

export const addJobFailed = () => {
  return {
    type: JOB_ADD_FAILED
  };
}

export const updateJob = (data) => {
  return {
    type: JOB_UPDATE,
    payload: data
  };
}


export const updateJobSuccess = (data) => {
  return {
    type: JOB_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateJobFailed = (data) => {
  return {
    type: JOB_UPDATE_FAILED,
    payload: data
  };
}

export const deleteJob = (data) => {
  return {
    type: JOB_DELETE,
    payload: data
  };
}

export const deleteJobSuccess = () => {
  return {
    type: JOB_DELETE_SUCCESS,
    message: 'Job deleted successfully.'
  };
}

export const deleteJobFailed = () => {
  return {
    type: JOB_DELETE_FAILED
  };
}

export const deleteAllJobs = (data) => {
  return {
    type: JOB_DELETE_ALL,
    payload: data
  };
}

export const deleteAllJobsSuccess = () => {
  return {
    type: JOB_DELETE_ALL_SUCCESS,
    message: 'Job all deleted successfully.'
  };
}

export const deleteAllJobsFailed = () => {
  return {
    type: JOB_DELETE_ALL_FAILED
  };
}
