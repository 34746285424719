import {
  WORKLOCATIONS_SELECT_ALL,
  WORKLOCATIONS_UNSELECT_ALL,
  WORKLOCATION_SELECTED,
  WORKLOCATIONS_LOAD,
  WORKLOCATIONS_LOAD_SUCCESS,
  WORKLOCATIONS_LOAD_FAILED,
  WORKLOCATION_LOAD,
  WORKLOCATION_LOAD_SUCCESS,
  WORKLOCATION_LOAD_FAILED,
  WORKLOCATION_ADD,
  WORKLOCATION_ADD_SUCCESS,
  WORKLOCATION_ADD_FAILED,
  WORKLOCATION_UPDATE,
  WORKLOCATION_UPDATE_SUCCESS,
  WORKLOCATION_UPDATE_FAILED,
  WORKLOCATION_DELETE,
  WORKLOCATION_DELETE_SUCCESS,
  WORKLOCATION_DELETE_FAILED,
  WORKLOCATION_DELETE_ALL,
  WORKLOCATION_DELETE_ALL_SUCCESS,
  WORKLOCATION_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedWorkLocation: null,
  selectedWorkLocationId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WORKLOCATIONS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case WORKLOCATIONS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case WORKLOCATION_SELECTED:
      return {
        ...state,
        selectedWorkLocation: action.payload,
        selectedWorkLocationId: action.payload.id
      };
    case WORKLOCATIONS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case WORKLOCATIONS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case WORKLOCATIONS_LOAD_FAILED:
      return {
        ...state
      };
    case WORKLOCATION_LOAD:
      return {
        ...state
      };
    case WORKLOCATION_LOAD_SUCCESS:
      return {
        ...state
      };
    case WORKLOCATION_LOAD_FAILED:
      return {
        ...state
      };
    case WORKLOCATION_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case WORKLOCATION_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case WORKLOCATION_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case WORKLOCATION_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case WORKLOCATION_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case WORKLOCATION_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case WORKLOCATION_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case WORKLOCATION_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case WORKLOCATION_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case WORKLOCATION_DELETE_ALL:
      return {
        ...state,
        hideLoading: false
      };
    case WORKLOCATION_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case WORKLOCATION_DELETE_ALL_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    default:
      return state;
  }
}
