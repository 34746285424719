import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadApprovals as loadApprovalsAction,
  loadApprovalsSuccess,
  addApprovalSuccess,
  updateApprovalSuccess,
  exportExcelApprovals,
  exportExcelApprovalsSuccess,
  exportExcelApprovalsFailed
} from '../actions/Approval';

import {
  APPROVALS_LOAD,
  APPROVAL_ADD,
  APPROVAL_UPDATE,
  APPROVAL_DELETE,
  APPROVAL_DELETE_ALL,
  APPROVALS_EXPORT_EXCEL,
  APPROVALS_EXPORT_EXCEL_SUCCESS,
  APPROVALS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getApprovalById = (id, callback) => {
  Api().get('approval/' + id).then(response => {
    callback(response.data);
  })
}

export const changeApprovalStatus = (model, callback) => {
  Api().post('approval/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllApprovals = (callback) => {
  Api().post('approval/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getApprovalsByTableId = (tableId, tableName, callback) => {
  Api().post('approval/search', {
    limit: GET_ALL_SIZE,
    orderBy: 'id',
    filterGroups: [{
      filters: [{
        property: 'tableId',
        operator: 'equal',
        value: tableId
      }, {
        property: 'tableName',
        operator: 'equal',
        value: tableName
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getApprovalsByCompany = (type, company, callback) => {
  Api().post('approval/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxApprovalCodeSorted = (callback) => {
  Api().post('approval/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadApprovalsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`approval/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`approval`, { params })
    .then(data => data)
    .catch(error => error);
}

const addApprovalRequest = async (payload) =>
  await Api().post(`approval`, payload)
    .then(data => data)
    .catch(error => error);

const updateApprovalRequest = async (payload) =>
  await Api().patch(`approval/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteApprovalRequest = async (id) =>
  await Api().delete(`approval/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadApprovals({ payload }) {
  try {
    const data = yield call(loadApprovalsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadApprovalsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadApprovals() {
  yield takeEvery(APPROVALS_LOAD, loadApprovals);
}

function* loadApproval({ selectedApprovalId }) {
  try {
    const data = yield call(loadApprovalsRequest, selectedApprovalId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadApprovalsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadApproval() {
  yield takeEvery(APPROVALS_LOAD, loadApproval);
}


function* addApproval({ payload }) {
  try {
    const data = yield call(addApprovalRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addApprovalSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddApproval() {
  yield takeEvery(APPROVAL_ADD, addApproval);
}


function* updateApproval({ payload }) {
  try {
    const data = yield call(updateApprovalRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateApprovalSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateApproval() {
  yield takeEvery(APPROVAL_UPDATE, updateApproval);
}

function* deleteApproval({ payload }) {
  try {
    const data = yield call(deleteApprovalRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadApprovalsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllApprovals({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteApprovalRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadApprovalsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteApproval() {
  yield takeEvery(APPROVAL_DELETE, deleteApproval);
}

function* processDeleteAllApprovals() {
  yield takeEvery(APPROVAL_DELETE_ALL, deleteAllApprovals);
}


const exportApprovalsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`approval/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportApprovals({ payload }) {
  try {
    const data = yield call(exportApprovalsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelApprovalsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportApprovals() {
  yield takeEvery(APPROVALS_EXPORT_EXCEL, exportApprovals);
}

export default function* ApprovalSagas() {
  yield all([fork(processLoadApprovals),
  fork(processLoadApproval),
  fork(processAddApproval),
  fork(processUpdateApproval),
  fork(processDeleteAllApprovals),
  fork(processDeleteApproval),
  fork(processExportApprovals)
]);
}
