import {
  MAILTOUSERS_SELECT_ALL,
  MAILTOUSERS_UNSELECT_ALL,
  MAILTOUSER_SELECTED,
  MAILTOUSERS_LOAD,
  MAILTOUSERS_LOAD_SUCCESS,
  MAILTOUSERS_LOAD_FAILED,
  MAILTOUSER_LOAD,
  MAILTOUSER_LOAD_SUCCESS,
  MAILTOUSER_LOAD_FAILED,
  MAILTOUSER_ADD,
  MAILTOUSER_ADD_SUCCESS,
  MAILTOUSER_ADD_FAILED,
  MAILTOUSER_UPDATE,
  MAILTOUSER_UPDATE_SUCCESS,
  MAILTOUSER_UPDATE_FAILED,
  MAILTOUSER_DELETE,
  MAILTOUSER_DELETE_SUCCESS,
  MAILTOUSER_DELETE_FAILED,
  MAILTOUSER_DELETE_ALL,
  MAILTOUSER_DELETE_ALL_SUCCESS,
  MAILTOUSER_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllMailToUsers = () => {
  return {
    type: MAILTOUSERS_SELECT_ALL
  }
}

export const unselectAllMailToUsers = () => {
  return {
    type: MAILTOUSERS_UNSELECT_ALL
  }
}

export const selectMailToUser = data => {
  return {
    type: MAILTOUSER_SELECTED,
    payload: data
  }
}

export const loadMailToUsers = (data) => {
  return {
    type: MAILTOUSERS_LOAD,
    payload: data
  };
}

export const loadMailToUsersSuccess = (data) => {
  return {
    type: MAILTOUSERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadMailToUsersFailed = () => {
  return {
    type: MAILTOUSERS_LOAD_FAILED
  };
}

export const loadMailToUser = () => {
  return {
    type: MAILTOUSER_LOAD
  };
}

export const loadMailToUserSuccess = (data) => {
  return {
    type: MAILTOUSER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadMailToUserFailed = () => {
  return {
    type: MAILTOUSER_LOAD_FAILED
  };
}


export const addMailToUser = (data) => {
  return {
    type: MAILTOUSER_ADD,
    payload: data
  };
}


export const addMailToUserSuccess = (filter) => {
  return {
    type: MAILTOUSER_ADD_SUCCESS,
    message: 'MailToUser added successfully.',
    payload: filter
  };
}

export const addMailToUserFailed = () => {
  return {
    type: MAILTOUSER_ADD_FAILED
  };
}

export const updateMailToUser = (data) => {
  return {
    type: MAILTOUSER_UPDATE,
    payload: data
  };
}


export const updateMailToUserSuccess = (data) => {
  return {
    type: MAILTOUSER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateMailToUserFailed = (data) => {
  return {
    type: MAILTOUSER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteMailToUser = (data) => {
  return {
    type: MAILTOUSER_DELETE,
    payload: data
  };
}

export const deleteMailToUserSuccess = () => {
  return {
    type: MAILTOUSER_DELETE_SUCCESS,
    message: 'MailToUser deleted successfully.'
  };
}

export const deleteMailToUserFailed = () => {
  return {
    type: MAILTOUSER_DELETE_FAILED
  };
}

export const deleteAllMailToUsers = (data) => {
  return {
    type: MAILTOUSER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllMailToUsersSuccess = () => {
  return {
    type: MAILTOUSER_DELETE_ALL_SUCCESS,
    message: 'MailToUser all deleted successfully.'
  };
}

export const deleteAllMailToUsersFailed = () => {
  return {
    type: MAILTOUSER_DELETE_ALL_FAILED
  };
}
