import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadLibraries as loadLibrariesAction,
  loadLibrariesSuccess,
  addLibrarySuccess,
  updateLibrarySuccess
} from '../actions/Library';

import {
  LIBRARIES_LOAD,
  LIBRARY_ADD,
  LIBRARY_UPDATE,
  LIBRARY_DELETE,
  LIBRARY_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getLibraryById = (id, callback) => {
  Api().get('library/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllLibraries = (callback) => {
  Api().post('library/search', { 
    limit: GET_ALL_SIZE,
    orderBy: "id",
    descending: true
  })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getMaxLibraryId = (callback) => {
  Api().post('library/search', { offset: 0,
    limit: 1,
    orderBy: "id",
    descending: true
  })
    .then(response => {
      let id = 1;
      let records = response.data.records;
      if (records.length) {
        id = records[0].id;
      }
      callback(id);
    })
}

const loadLibrariesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`library/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`library`, { params })
    .then(data => data)
    .catch(error => error);
}


const addLibraryRequest = async (payload) =>
  await Api().post(`library`, payload)
    .then(data => data)
    .catch(error => error);

const updateLibraryRequest = async (payload) =>
  await Api().patch(`library/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteLibraryRequest = async (id) =>
  await Api().delete(`library/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadLibraries({ payload }) {
  try {
    const data = yield call(loadLibrariesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadLibrariesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadLibraries() {
  yield takeEvery(LIBRARIES_LOAD, loadLibraries);
}

function* loadLibrary({ selectedLibraryId }) {
  try {
    const data = yield call(loadLibrariesRequest, selectedLibraryId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadLibrariesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadLibrary() {
  yield takeEvery(LIBRARIES_LOAD, loadLibrary);
}


function* addLibrary({ payload }) {
  try {
    const data = yield call(addLibraryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addLibrarySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddLibrary() {
  yield takeEvery(LIBRARY_ADD, addLibrary);
}


function* updateLibrary({ payload }) {
  try {
    const data = yield call(updateLibraryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateLibrarySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateLibrary() {
  yield takeEvery(LIBRARY_UPDATE, updateLibrary);
}

function* deleteLibrary({ payload }) {
  try {
    const data = yield call(deleteLibraryRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadLibrariesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllLibraries({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteLibraryRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadLibrariesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteLibrary() {
  yield takeEvery(LIBRARY_DELETE, deleteLibrary);
}

function* processDeleteAllLibraries() {
  yield takeEvery(LIBRARY_DELETE_ALL, deleteAllLibraries);
}

export default function* LibrarySagas() {
  yield all([fork(processLoadLibraries),
  fork(processLoadLibrary),
  fork(processAddLibrary),
  fork(processUpdateLibrary),
  fork(processDeleteAllLibraries),
  fork(processDeleteLibrary)]);
}
