import {
  LIBRARIES_SELECT_ALL,
  LIBRARIES_UNSELECT_ALL,
  LIBRARY_SELECTED,
  LIBRARIES_LOAD,
  LIBRARIES_LOAD_SUCCESS,
  LIBRARIES_LOAD_FAILED,
  LIBRARY_LOAD,
  LIBRARY_LOAD_SUCCESS,
  LIBRARY_LOAD_FAILED,
  LIBRARY_ADD,
  LIBRARY_ADD_SUCCESS,
  LIBRARY_ADD_FAILED,
  LIBRARY_UPDATE,
  LIBRARY_UPDATE_SUCCESS,
  LIBRARY_UPDATE_FAILED,
  LIBRARY_DELETE,
  LIBRARY_DELETE_SUCCESS,
  LIBRARY_DELETE_FAILED,
  LIBRARY_DELETE_ALL,
  LIBRARY_DELETE_ALL_SUCCESS,
  LIBRARY_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllLibraries = () => {
  return {
    type: LIBRARIES_SELECT_ALL
  }
}

export const unselectAllLibraries = () => {
  return {
    type: LIBRARIES_UNSELECT_ALL
  }
}

export const selectLibrary = data => {
  return {
    type: LIBRARY_SELECTED,
    payload: data
  }
}

export const loadLibraries = (data) => {
  return {
    type: LIBRARIES_LOAD,
    payload: data
  };
}

export const loadLibrariesSuccess = (data) => {
  return {
    type: LIBRARIES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadLibrariesFailed = () => {
  return {
    type: LIBRARIES_LOAD_FAILED
  };
}

export const loadLibrary = () => {
  return {
    type: LIBRARY_LOAD
  };
}

export const loadLibrarySuccess = (data) => {
  return {
    type: LIBRARY_LOAD_SUCCESS,
    payload: data
  };
}


export const loadLibraryFailed = () => {
  return {
    type: LIBRARY_LOAD_FAILED
  };
}


export const addLibrary = (data) => {
  return {
    type: LIBRARY_ADD,
    payload: data
  };
}


export const addLibrarySuccess = (filter) => {
  return {
    type: LIBRARY_ADD_SUCCESS,
    message: 'Library added successfully.',
    payload: filter
  };
}

export const addLibraryFailed = () => {
  return {
    type: LIBRARY_ADD_FAILED
  };
}

export const updateLibrary = (data) => {
  return {
    type: LIBRARY_UPDATE,
    payload: data
  };
}


export const updateLibrarySuccess = (data) => {
  return {
    type: LIBRARY_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateLibraryFailed = (data) => {
  return {
    type: LIBRARY_UPDATE_FAILED,
    payload: data
  };
}

export const deleteLibrary = (data) => {
  return {
    type: LIBRARY_DELETE,
    payload: data
  };
}

export const deleteLibrarySuccess = () => {
  return {
    type: LIBRARY_DELETE_SUCCESS,
    message: 'Library deleted successfully.'
  };
}

export const deleteLibraryFailed = () => {
  return {
    type: LIBRARY_DELETE_FAILED
  };
}

export const deleteAllLibraries = (data) => {
  return {
    type: LIBRARY_DELETE_ALL,
    payload: data
  };
}

export const deleteAllLibrariesSuccess = () => {
  return {
    type: LIBRARY_DELETE_ALL_SUCCESS,
    message: 'Library all deleted successfully.'
  };
}

export const deleteAllLibrariesFailed = () => {
  return {
    type: LIBRARY_DELETE_ALL_FAILED
  };
}
