import {
  WORKLOCATIONS_SELECT_ALL,
  WORKLOCATIONS_UNSELECT_ALL,
  WORKLOCATION_SELECTED,
  WORKLOCATIONS_LOAD,
  WORKLOCATIONS_LOAD_SUCCESS,
  WORKLOCATIONS_LOAD_FAILED,
  WORKLOCATION_LOAD,
  WORKLOCATION_LOAD_SUCCESS,
  WORKLOCATION_LOAD_FAILED,
  WORKLOCATION_ADD,
  WORKLOCATION_ADD_SUCCESS,
  WORKLOCATION_ADD_FAILED,
  WORKLOCATION_UPDATE,
  WORKLOCATION_UPDATE_SUCCESS,
  WORKLOCATION_UPDATE_FAILED,
  WORKLOCATION_DELETE,
  WORKLOCATION_DELETE_SUCCESS,
  WORKLOCATION_DELETE_FAILED,
  WORKLOCATION_DELETE_ALL,
  WORKLOCATION_DELETE_ALL_SUCCESS,
  WORKLOCATION_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllWorkLocations = () => {
  return {
    type: WORKLOCATIONS_SELECT_ALL
  }
}

export const unselectAllWorkLocations = () => {
  return {
    type: WORKLOCATIONS_UNSELECT_ALL
  }
}

export const selectWorkLocation = data => {
  return {
    type: WORKLOCATION_SELECTED,
    payload: data
  }
}

export const loadWorkLocations = (data) => {
  return {
    type: WORKLOCATIONS_LOAD,
    payload: data
  };
}

export const loadWorkLocationsSuccess = (data) => {
  return {
    type: WORKLOCATIONS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadWorkLocationsFailed = () => {
  return {
    type: WORKLOCATIONS_LOAD_FAILED
  };
}

export const loadWorkLocation = () => {
  return {
    type: WORKLOCATION_LOAD
  };
}

export const loadWorkLocationSuccess = (data) => {
  return {
    type: WORKLOCATION_LOAD_SUCCESS,
    payload: data
  };
}


export const loadWorkLocationFailed = () => {
  return {
    type: WORKLOCATION_LOAD_FAILED
  };
}


export const addWorkLocation = (data) => {
  return {
    type: WORKLOCATION_ADD,
    payload: data
  };
}


export const addWorkLocationSuccess = (filter) => {
  return {
    type: WORKLOCATION_ADD_SUCCESS,
    message: 'WorkLocation added successfully.',
    payload: filter
  };
}

export const addWorkLocationFailed = () => {
  return {
    type: WORKLOCATION_ADD_FAILED
  };
}

export const updateWorkLocation = (data) => {
  return {
    type: WORKLOCATION_UPDATE,
    payload: data
  };
}


export const updateWorkLocationSuccess = (data) => {
  return {
    type: WORKLOCATION_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateWorkLocationFailed = (data) => {
  return {
    type: WORKLOCATION_UPDATE_FAILED,
    payload: data
  };
}

export const deleteWorkLocation = (data) => {
  return {
    type: WORKLOCATION_DELETE,
    payload: data
  };
}

export const deleteWorkLocationSuccess = () => {
  return {
    type: WORKLOCATION_DELETE_SUCCESS,
    message: 'WorkLocation deleted successfully.'
  };
}

export const deleteWorkLocationFailed = () => {
  return {
    type: WORKLOCATION_DELETE_FAILED
  };
}

export const deleteAllWorkLocations = (data) => {
  return {
    type: WORKLOCATION_DELETE_ALL,
    payload: data
  };
}

export const deleteAllWorkLocationsSuccess = () => {
  return {
    type: WORKLOCATION_DELETE_ALL_SUCCESS,
    message: 'WorkLocation all deleted successfully.'
  };
}

export const deleteAllWorkLocationsFailed = () => {
  return {
    type: WORKLOCATION_DELETE_ALL_FAILED
  };
}
