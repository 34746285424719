import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadDocuments as loadDocumentsAction,
  loadDocumentsSuccess,
  addDocumentSuccess,
  updateDocumentSuccess,
  exportExcelDocuments,
  exportExcelDocumentsSuccess,
  exportExcelDocumentsFailed
} from '../actions/Document';

import {
  DOCUMENTS_LOAD,
  DOCUMENT_ADD,
  DOCUMENT_UPDATE,
  DOCUMENT_DELETE,
  DOCUMENT_DELETE_ALL,
  DOCUMENTS_EXPORT_EXCEL,
  DOCUMENTS_EXPORT_EXCEL_SUCCESS,
  DOCUMENTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getDocumentById = (id, callback) => {
  Api().get('document/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllDocuments = (callback) => {
  Api().post('document/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getDocumentsByCompany = (type, company, callback) => {
  Api().post('document/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxDocumentId = (callback) => {
  Api().post('document/search', { offset: 0,
    limit: 1,
    orderBy: "id",
    descending: true
  })
    .then(response => {
      let id = 1;
      let records = response.data.records;
      if (records.length) {
        id = records[0].id;
      }
      callback(id);
    })
}

const loadDocumentsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`document/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`document`, { params })
    .then(data => data)
    .catch(error => error);
}

const addDocumentRequest = async (payload) =>
  await Api().post(`document`, payload)
    .then(data => data)
    .catch(error => error);

const updateDocumentRequest = async (payload) =>
  await Api().patch(`document/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteDocumentRequest = async (id) =>
  await Api().delete(`document/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadDocuments({ payload }) {
  try {
    const data = yield call(loadDocumentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadDocumentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadDocuments() {
  yield takeEvery(DOCUMENTS_LOAD, loadDocuments);
}

function* loadDocument({ selectedDocumentId }) {
  try {
    const data = yield call(loadDocumentsRequest, selectedDocumentId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadDocumentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadDocument() {
  yield takeEvery(DOCUMENTS_LOAD, loadDocument);
}


function* addDocument({ payload }) {
  try {
    const data = yield call(addDocumentRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addDocumentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddDocument() {
  yield takeEvery(DOCUMENT_ADD, addDocument);
}


function* updateDocument({ payload }) {
  try {
    const data = yield call(updateDocumentRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateDocumentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateDocument() {
  yield takeEvery(DOCUMENT_UPDATE, updateDocument);
}

function* deleteDocument({ payload }) {
  try {
    const data = yield call(deleteDocumentRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadDocumentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllDocuments({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteDocumentRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadDocumentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteDocument() {
  yield takeEvery(DOCUMENT_DELETE, deleteDocument);
}

function* processDeleteAllDocuments() {
  yield takeEvery(DOCUMENT_DELETE_ALL, deleteAllDocuments);
}


const exportDocumentsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`document/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportDocuments({ payload }) {
  try {
    const data = yield call(exportDocumentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelDocumentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportDocuments() {
  yield takeEvery(DOCUMENTS_EXPORT_EXCEL, exportDocuments);
}

export default function* DocumentSagas() {
  yield all([fork(processLoadDocuments),
  fork(processLoadDocument),
  fork(processAddDocument),
  fork(processUpdateDocument),
  fork(processDeleteAllDocuments),
  fork(processDeleteDocument),
  fork(processExportDocuments)
]);
}
