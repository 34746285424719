import {
  ROLES_SELECT_ALL,
  ROLES_UNSELECT_ALL,
  ROLE_SELECTED,
  ROLES_LOAD,
  ROLES_LOAD_SUCCESS,
  ROLES_LOAD_FAILED,
  ROLE_LOAD,
  ROLE_LOAD_SUCCESS,
  ROLE_LOAD_FAILED,
  ROLE_ADD,
  ROLE_ADD_SUCCESS,
  ROLE_ADD_FAILED,
  ROLE_UPDATE,
  ROLE_UPDATE_SUCCESS,
  ROLE_UPDATE_FAILED,
  ROLE_DELETE,
  ROLE_DELETE_SUCCESS,
  ROLE_DELETE_FAILED,
  ROLE_DELETE_ALL,
  ROLE_DELETE_ALL_SUCCESS,
  ROLE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllRoles = () => {
  return {
    type: ROLES_SELECT_ALL
  }
}

export const unselectAllRoles = () => {
  return {
    type: ROLES_UNSELECT_ALL
  }
}

export const selectRole = data => {
  return {
    type: ROLE_SELECTED,
    payload: data
  }
}

export const loadRoles = (data) => {
  return {
    type: ROLES_LOAD,
    payload: data
  };
}

export const loadRolesSuccess = (data) => {
  return {
    type: ROLES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadRolesFailed = () => {
  return {
    type: ROLES_LOAD_FAILED
  };
}

export const loadRole = () => {
  return {
    type: ROLE_LOAD
  };
}

export const loadRoleSuccess = (data) => {
  return {
    type: ROLE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadRoleFailed = () => {
  return {
    type: ROLE_LOAD_FAILED
  };
}


export const addRole = (data) => {
  return {
    type: ROLE_ADD,
    payload: data
  };
}


export const addRoleSuccess = (filter) => {
  return {
    type: ROLE_ADD_SUCCESS,
    message: 'Role added successfully.',
    payload: filter
  };
}

export const addRoleFailed = () => {
  return {
    type: ROLE_ADD_FAILED
  };
}

export const updateRole = (data) => {
  return {
    type: ROLE_UPDATE,
    payload: data
  };
}


export const updateRoleSuccess = (data) => {
  return {
    type: ROLE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateRoleFailed = (data) => {
  return {
    type: ROLE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteRole = (data) => {
  return {
    type: ROLE_DELETE,
    payload: data
  };
}

export const deleteRoleSuccess = () => {
  return {
    type: ROLE_DELETE_SUCCESS,
    message: 'Role deleted successfully.'
  };
}

export const deleteRoleFailed = () => {
  return {
    type: ROLE_DELETE_FAILED
  };
}

export const deleteAllRoles = (data) => {
  return {
    type: ROLE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllRolesSuccess = () => {
  return {
    type: ROLE_DELETE_ALL_SUCCESS,
    message: 'Role all deleted successfully.'
  };
}

export const deleteAllRolesFailed = () => {
  return {
    type: ROLE_DELETE_ALL_FAILED
  };
}
