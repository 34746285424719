import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadProjects as loadProjectsAction,
  loadProjectsSuccess,
  addProjectSuccess,
  updateProjectSuccess,
  exportExcelProjects,
  exportExcelProjectsSuccess,
  exportExcelProjectsFailed
} from '../actions/Project';

import {
  PROJECTS_LOAD,
  PROJECT_ADD,
  PROJECT_UPDATE,
  PROJECT_DELETE,
  PROJECT_DELETE_ALL,
  PROJECTS_EXPORT_EXCEL,
  PROJECTS_EXPORT_EXCEL_SUCCESS,
  PROJECTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getProjectById = (id, callback) => {
  Api().get('project/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllProjects = (callback) => {
  Api().post('project/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getProjectsByCompany = (type, company, callback) => {
  Api().post('project/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

export const getMaxProjectId = (callback) => {
  Api().post('project/search', { offset: 0,
    limit: 1,
    orderBy: "id",
    descending: true
  })
    .then(response => {
      let id = 1;
      let records = response.data.records;
      if (records.length) {
        id = records[0].id;
      }
      callback(id);
    })
}

const loadProjectsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`project/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`project`, { params })
    .then(data => data)
    .catch(error => error);
}

const addProjectRequest = async (payload) =>
  await Api().post(`project`, payload)
    .then(data => data)
    .catch(error => error);

const updateProjectRequest = async (payload) =>
  await Api().patch(`project/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteProjectRequest = async (id) =>
  await Api().delete(`project/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadProjects({ payload }) {
  try {
    const data = yield call(loadProjectsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProjectsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProjects() {
  yield takeEvery(PROJECTS_LOAD, loadProjects);
}

function* loadProject({ selectedProjectId }) {
  try {
    const data = yield call(loadProjectsRequest, selectedProjectId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProjectsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadProject() {
  yield takeEvery(PROJECTS_LOAD, loadProject);
}


function* addProject({ payload }) {
  try {
    const data = yield call(addProjectRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addProjectSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddProject() {
  yield takeEvery(PROJECT_ADD, addProject);
}


function* updateProject({ payload }) {
  try {
    const data = yield call(updateProjectRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateProjectSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateProject() {
  yield takeEvery(PROJECT_UPDATE, updateProject);
}

function* deleteProject({ payload }) {
  try {
    const data = yield call(deleteProjectRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadProjectsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllProjects({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteProjectRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadProjectsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteProject() {
  yield takeEvery(PROJECT_DELETE, deleteProject);
}

function* processDeleteAllProjects() {
  yield takeEvery(PROJECT_DELETE_ALL, deleteAllProjects);
}


const exportProjectsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`project/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportProjects({ payload }) {
  try {
    const data = yield call(exportProjectsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelProjectsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportProjects() {
  yield takeEvery(PROJECTS_EXPORT_EXCEL, exportProjects);
}

export default function* ProjectSagas() {
  yield all([fork(processLoadProjects),
  fork(processLoadProject),
  fork(processAddProject),
  fork(processUpdateProject),
  fork(processDeleteAllProjects),
  fork(processDeleteProject),
  fork(processExportProjects)
]);
}
