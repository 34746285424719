import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadMailToUsers as loadMailToUsersAction,
  loadMailToUsersSuccess,
  updateMailToUserSuccess,
  addMailToUserSuccess,
  deleteMailToUserSuccess,
  deleteAllMailToUsersSuccess
} from '../actions/MailToUser';

import {
  MAILTOUSERS_LOAD,
  MAILTOUSER_ADD,
  MAILTOUSER_UPDATE,
  MAILTOUSER_DELETE,
  MAILTOUSER_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getMailToUserById = (id, callback) => {
  Api().get('mailToUser/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllMailToUsers = (callback) => {
  Api().post('mailToUser/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadMailToUsersRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`mailToUser/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`mailToUser`, { params })
    .then(data => data)
    .catch(error => error);
}

const addMailToUserRequest = async (payload) =>
  await Api().post(`mailToUser`, payload)
    .then(data => data)
    .catch(error => error);

const updateMailToUserRequest = async (payload) =>
  await Api().patch(`mailToUser/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteMailToUserRequest = async (id) =>
  await Api().delete(`mailToUser/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadMailToUsers({ payload }) {
  try {
    const data = yield call(loadMailToUsersRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadMailToUsersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadMailToUsers() {
  yield takeEvery(MAILTOUSERS_LOAD, loadMailToUsers);
}

function* loadMailToUser({ selectedMailToUserId }) {
  try {
    const data = yield call(loadMailToUsersRequest, selectedMailToUserId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadMailToUsersSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadMailToUser() {
  yield takeEvery(MAILTOUSERS_LOAD, loadMailToUser);
}

function* addMailToUser({ payload }) {
  try {
    const data = yield call(addMailToUserRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addMailToUserSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddMailToUser() {
  yield takeEvery(MAILTOUSER_ADD, addMailToUser);
}

function* updateMailToUser({ payload }) {
  try {
    const data = yield call(updateMailToUserRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateMailToUserSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateMailToUser() {
  yield takeEvery(MAILTOUSER_UPDATE, updateMailToUser);
}

function* deleteMailToUser({ payload }) {
  try {
    const data = yield call(deleteMailToUserRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(deleteMailToUserSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllMailToUsers({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteMailToUserRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllMailToUsersSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteMailToUser() {
  yield takeEvery(MAILTOUSER_DELETE, deleteMailToUser);
}

function* processDeleteAllMailToUsers() {
  yield takeEvery(MAILTOUSER_DELETE_ALL, deleteAllMailToUsers);
}

export default function* MailToUserSagas() {
  yield all([fork(processLoadMailToUsers),
  fork(processLoadMailToUser),
  fork(processAddMailToUser),
  fork(processUpdateMailToUser),
  fork(processDeleteAllMailToUsers),
  fork(processDeleteMailToUser)]);
}
