import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
  loadAlbums as loadAlbumsAction,
  loadAlbumsSuccess,
  loadAlbumSuccess,
  addAlbumSuccess,
  updateAlbumSuccess
} from '../actions/Album';

import {
  ALBUMS_LOAD,
  ALBUM_LOAD,
  ALBUM_ADD,
  ALBUM_UPDATE,
  ALBUM_DELETE,
  ALBUM_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

const loadAlbumsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`album/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`album`, { params })
    .then(data => data)
    .catch(error => error);
}

const loadAlbumRequest = async (id) => {
  return await Api().get(`album/${id}`)
    .then(data => data)
    .catch(error => error);
}

const addAlbumRequest = async (payload) =>
  await Api().post(`album`, payload)
    .then(data => data)
    .catch(error => error);

const updateAlbumRequest = async (payload) =>
  await Api().patch(`album/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteAlbumRequest = async (id) =>
  await Api().delete(`album/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadAlbums({ payload }) {
  try {
    const data = yield call(loadAlbumsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadAlbumsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadAlbums() {
  yield takeEvery(ALBUMS_LOAD, loadAlbums);
}

function* loadAlbum({ payload }) {
  try {
    const data = yield call(loadAlbumRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadAlbumSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadAlbum() {
  yield takeEvery(ALBUM_LOAD, loadAlbum);
}

function* addAlbum({ payload }) {
  try {
    const data = yield call(addAlbumRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addAlbumSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddAlbum() {
  yield takeEvery(ALBUM_ADD, addAlbum);
}

function* updateAlbum({ payload }) {
  try {
    const data = yield call(updateAlbumRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateAlbumSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateAlbum() {
  yield takeEvery(ALBUM_UPDATE, updateAlbum);
}

function* deleteAlbum({ payload }) {
  try {
    const data = yield call(deleteAlbumRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadAlbumsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllAlbums({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteAlbumRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadAlbumsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteAlbum() {
  yield takeEvery(ALBUM_DELETE, deleteAlbum);
}

function* processDeleteAllAlbums() {
  yield takeEvery(ALBUM_DELETE_ALL, deleteAllAlbums);
}

export default function* AlbumSagas() {
  yield all([fork(processLoadAlbums),
  fork(processLoadAlbum),
  fork(processAddAlbum),
  fork(processUpdateAlbum),
  fork(processDeleteAllAlbums),
  fork(processDeleteAlbum)]);
}
