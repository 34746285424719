import {
  PARTNERS_SELECT_ALL,
  PARTNERS_UNSELECT_ALL,
  PARTNER_SELECTED,
  PARTNERS_LOAD,
  PARTNERS_LOAD_SUCCESS,
  PARTNERS_LOAD_FAILED,
  PARTNER_LOAD,
  PARTNER_LOAD_SUCCESS,
  PARTNER_LOAD_FAILED,
  PARTNER_ADD,
  PARTNER_ADD_SUCCESS,
  PARTNER_ADD_FAILED,
  PARTNER_UPDATE,
  PARTNER_UPDATE_SUCCESS,
  PARTNER_UPDATE_FAILED,
  PARTNER_DELETE,
  PARTNER_DELETE_SUCCESS,
  PARTNER_DELETE_FAILED,
  PARTNER_DELETE_ALL,
  PARTNER_DELETE_ALL_SUCCESS,
  PARTNER_DELETE_ALL_FAILED,
  PARTNERS_EXPORT_EXCEL,
  PARTNERS_EXPORT_EXCEL_SUCCESS,
  PARTNERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllPartners = () => {
  return {
    type: PARTNERS_SELECT_ALL
  }
}

export const unselectAllPartners = () => {
  return {
    type: PARTNERS_UNSELECT_ALL
  }
}

export const selectPartner = data => {
  return {
    type: PARTNER_SELECTED,
    payload: data
  }
}

export const loadPartners = (data) => {
  return {
    type: PARTNERS_LOAD,
    payload: data
  };
}

export const loadPartnersSuccess = (data) => {
  return {
    type: PARTNERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadPartnersFailed = () => {
  return {
    type: PARTNERS_LOAD_FAILED
  };
}

export const loadPartner = () => {
  return {
    type: PARTNER_LOAD
  };
}

export const loadPartnerSuccess = (data) => {
  return {
    type: PARTNER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadPartnerFailed = () => {
  return {
    type: PARTNER_LOAD_FAILED
  };
}


export const addPartner = (data) => {
  return {
    type: PARTNER_ADD,
    payload: data
  };
}


export const addPartnerSuccess = (filter) => {
  return {
    type: PARTNER_ADD_SUCCESS,
    message: 'Partner added successfully.',
    payload: filter
  };
}

export const addPartnerFailed = () => {
  return {
    type: PARTNER_ADD_FAILED
  };
}

export const updatePartner = (data) => {
  return {
    type: PARTNER_UPDATE,
    payload: data
  };
}


export const updatePartnerSuccess = (data) => {
  return {
    type: PARTNER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updatePartnerFailed = (data) => {
  return {
    type: PARTNER_UPDATE_FAILED,
    payload: data
  };
}

export const deletePartner = (data) => {
  return {
    type: PARTNER_DELETE,
    payload: data
  };
}

export const deletePartnerSuccess = () => {
  return {
    type: PARTNER_DELETE_SUCCESS,
    message: 'Partner deleted successfully.'
  };
}

export const deletePartnerFailed = () => {
  return {
    type: PARTNER_DELETE_FAILED
  };
}

export const deleteAllPartners = (data) => {
  return {
    type: PARTNER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllPartnersSuccess = () => {
  return {
    type: PARTNER_DELETE_ALL_SUCCESS,
    message: 'Partner all deleted successfully.'
  };
}

export const deleteAllPartnersFailed = () => {
  return {
    type: PARTNER_DELETE_ALL_FAILED
  };
}

export const exportExcelPartners = (filter) => {
  return {
    type:PARTNERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelPartnersSuccess = (data) => {
  return {
    type:PARTNERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelPartnersFailed = () => {
  return {
    type:PARTNERS_EXPORT_EXCEL_FAILED
  };
}
