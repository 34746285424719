import {
  CUSTOMERS_SELECT_ALL,
  CUSTOMERS_UNSELECT_ALL,
  CUSTOMER_SELECTED,
  CUSTOMERS_LOAD,
  CUSTOMERS_LOAD_SUCCESS,
  CUSTOMERS_LOAD_FAILED,
  CUSTOMER_LOAD,
  CUSTOMER_LOAD_SUCCESS,
  CUSTOMER_LOAD_FAILED,
  CUSTOMER_ADD,
  CUSTOMER_ADD_SUCCESS,
  CUSTOMER_ADD_FAILED,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAILED,
  CUSTOMER_DELETE,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAILED,
  CUSTOMER_DELETE_ALL,
  CUSTOMER_DELETE_ALL_SUCCESS,
  CUSTOMER_DELETE_ALL_FAILED,
  CUSTOMERS_EXPORT_EXCEL,
  CUSTOMERS_EXPORT_EXCEL_SUCCESS,
  CUSTOMERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllCustomers = () => {
  return {
    type: CUSTOMERS_SELECT_ALL
  }
}

export const unselectAllCustomers = () => {
  return {
    type: CUSTOMERS_UNSELECT_ALL
  }
}

export const selectCustomer = data => {
  return {
    type: CUSTOMER_SELECTED,
    payload: data
  }
}

export const loadCustomers = (data) => {
  return {
    type: CUSTOMERS_LOAD,
    payload: data
  };
}

export const loadCustomersSuccess = (data) => {
  return {
    type: CUSTOMERS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadCustomersFailed = () => {
  return {
    type: CUSTOMERS_LOAD_FAILED
  };
}

export const loadCustomer = () => {
  return {
    type: CUSTOMER_LOAD
  };
}

export const loadCustomerSuccess = (data) => {
  return {
    type: CUSTOMER_LOAD_SUCCESS,
    payload: data
  };
}


export const loadCustomerFailed = () => {
  return {
    type: CUSTOMER_LOAD_FAILED
  };
}


export const addCustomer = (data) => {
  return {
    type: CUSTOMER_ADD,
    payload: data
  };
}


export const addCustomerSuccess = (filter) => {
  return {
    type: CUSTOMER_ADD_SUCCESS,
    message: 'Customer added successfully.',
    payload: filter
  };
}

export const addCustomerFailed = () => {
  return {
    type: CUSTOMER_ADD_FAILED
  };
}

export const updateCustomer = (data) => {
  return {
    type: CUSTOMER_UPDATE,
    payload: data
  };
}


export const updateCustomerSuccess = (data) => {
  return {
    type: CUSTOMER_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateCustomerFailed = (data) => {
  return {
    type: CUSTOMER_UPDATE_FAILED,
    payload: data
  };
}

export const deleteCustomer = (data) => {
  return {
    type: CUSTOMER_DELETE,
    payload: data
  };
}

export const deleteCustomerSuccess = () => {
  return {
    type: CUSTOMER_DELETE_SUCCESS,
    message: 'Customer deleted successfully.'
  };
}

export const deleteCustomerFailed = () => {
  return {
    type: CUSTOMER_DELETE_FAILED
  };
}

export const deleteAllCustomers = (data) => {
  return {
    type: CUSTOMER_DELETE_ALL,
    payload: data
  };
}

export const deleteAllCustomersSuccess = () => {
  return {
    type: CUSTOMER_DELETE_ALL_SUCCESS,
    message: 'Customer all deleted successfully.'
  };
}

export const deleteAllCustomersFailed = () => {
  return {
    type: CUSTOMER_DELETE_ALL_FAILED
  };
}

export const exportExcelCustomers = (filter) => {
  return {
    type:CUSTOMERS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelCustomersSuccess = (data) => {
  return {
    type:CUSTOMERS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelCustomersFailed = () => {
  return {
    type:CUSTOMERS_EXPORT_EXCEL_FAILED
  };
}
