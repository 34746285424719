import {
  SAMPLES_SELECT_ALL,
  SAMPLES_UNSELECT_ALL,
  SAMPLE_SELECTED,
  SAMPLES_LOAD,
  SAMPLES_LOAD_SUCCESS,
  SAMPLES_LOAD_FAILED,
  SAMPLE_LOAD,
  SAMPLE_LOAD_SUCCESS,
  SAMPLE_LOAD_FAILED,
  SAMPLE_ADD,
  SAMPLE_ADD_SUCCESS,
  SAMPLE_ADD_FAILED,
  SAMPLE_UPDATE,
  SAMPLE_UPDATE_SUCCESS,
  SAMPLE_UPDATE_FAILED,
  SAMPLE_DELETE,
  SAMPLE_DELETE_SUCCESS,
  SAMPLE_DELETE_FAILED,
  SAMPLE_DELETE_ALL,
  SAMPLE_DELETE_ALL_SUCCESS,
  SAMPLE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllSamples = () => {
  return {
    type: SAMPLES_SELECT_ALL
  }
}

export const unselectAllSamples = () => {
  return {
    type: SAMPLES_UNSELECT_ALL
  }
}

export const selectSample = data => {
  return {
    type: SAMPLE_SELECTED,
    payload: data
  }
}

export const loadSamples = (data) => {
  return {
    type: SAMPLES_LOAD,
    payload: data
  };
}

export const loadSamplesSuccess = (data) => {
  return {
    type: SAMPLES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadSamplesFailed = () => {
  return {
    type: SAMPLES_LOAD_FAILED
  };
}

export const loadSample = () => {
  return {
    type: SAMPLE_LOAD
  };
}

export const loadSampleSuccess = (data) => {
  return {
    type: SAMPLE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadSampleFailed = () => {
  return {
    type: SAMPLE_LOAD_FAILED
  };
}


export const addSample = (data) => {
  return {
    type: SAMPLE_ADD,
    payload: data
  };
}


export const addSampleSuccess = (filter) => {
  return {
    type: SAMPLE_ADD_SUCCESS,
    message: 'Sample added successfully.',
    payload: filter
  };
}

export const addSampleFailed = () => {
  return {
    type: SAMPLE_ADD_FAILED
  };
}

export const updateSample = (data) => {
  return {
    type: SAMPLE_UPDATE,
    payload: data
  };
}


export const updateSampleSuccess = (data) => {
  return {
    type: SAMPLE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateSampleFailed = (data) => {
  return {
    type: SAMPLE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteSample = (data) => {
  return {
    type: SAMPLE_DELETE,
    payload: data
  };
}

export const deleteSampleSuccess = () => {
  return {
    type: SAMPLE_DELETE_SUCCESS,
    message: 'Sample deleted successfully.'
  };
}

export const deleteSampleFailed = () => {
  return {
    type: SAMPLE_DELETE_FAILED
  };
}

export const deleteAllSamples = (data) => {
  return {
    type: SAMPLE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllSamplesSuccess = () => {
  return {
    type: SAMPLE_DELETE_ALL_SUCCESS,
    message: 'Sample all deleted successfully.'
  };
}

export const deleteAllSamplesFailed = () => {
  return {
    type: SAMPLE_DELETE_ALL_FAILED
  };
}
