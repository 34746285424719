import {
  MEDIAS_SELECT_ALL,
  MEDIAS_UNSELECT_ALL,
  MEDIA_SELECTED,
  MEDIAS_LOAD,
  MEDIAS_LOAD_SUCCESS,
  MEDIAS_LOAD_FAILED,
  MEDIA_LOAD,
  MEDIA_LOAD_SUCCESS,
  MEDIA_LOAD_FAILED,
  MEDIA_ADD,
  MEDIA_ADD_SUCCESS,
  MEDIA_ADD_FAILED,
  MEDIA_UPDATE,
  MEDIA_UPDATE_SUCCESS,
  MEDIA_UPDATE_FAILED,
  MEDIA_DELETE,
  MEDIA_DELETE_SUCCESS,
  MEDIA_DELETE_FAILED,
  MEDIA_DELETE_ALL,
  MEDIA_DELETE_ALL_SUCCESS,
  MEDIA_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllMedias = () => {
  return {
    type: MEDIAS_SELECT_ALL
  }
}

export const unselectAllMedias = () => {
  return {
    type: MEDIAS_UNSELECT_ALL
  }
}

export const selectMedia = data => {
  return {
    type: MEDIA_SELECTED,
    payload: data
  }
}

export const loadMedias = (data) => {
  return {
    type: MEDIAS_LOAD,
    payload: data
  };
}

export const loadMediasSuccess = (data) => {
  return {
    type: MEDIAS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadMediasFailed = () => {
  return {
    type: MEDIAS_LOAD_FAILED
  };
}

export const loadMedia = () => {
  return {
    type: MEDIA_LOAD
  };
}

export const loadMediaSuccess = (data) => {
  return {
    type: MEDIA_LOAD_SUCCESS,
    payload: data
  };
}


export const loadMediaFailed = () => {
  return {
    type: MEDIA_LOAD_FAILED
  };
}


export const addMedia = (data) => {
  return {
    type: MEDIA_ADD,
    payload: data
  };
}


export const addMediaSuccess = (filter) => {
  return {
    type: MEDIA_ADD_SUCCESS,
    message: 'Media added successfully.',
    payload: filter
  };
}

export const addMediaFailed = () => {
  return {
    type: MEDIA_ADD_FAILED
  };
}

export const updateMedia = (data) => {
  return {
    type: MEDIA_UPDATE,
    payload: data
  };
}


export const updateMediaSuccess = (data) => {
  return {
    type: MEDIA_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateMediaFailed = (data) => {
  return {
    type: MEDIA_UPDATE_FAILED,
    payload: data
  };
}

export const deleteMedia = (data) => {
  return {
    type: MEDIA_DELETE,
    payload: data
  };
}

export const deleteMediaSuccess = () => {
  return {
    type: MEDIA_DELETE_SUCCESS,
    message: 'Media deleted successfully.'
  };
}

export const deleteMediaFailed = () => {
  return {
    type: MEDIA_DELETE_FAILED
  };
}

export const deleteAllMedias = (data) => {
  return {
    type: MEDIA_DELETE_ALL,
    payload: data
  };
}

export const deleteAllMediasSuccess = () => {
  return {
    type: MEDIA_DELETE_ALL_SUCCESS,
    message: 'Media all deleted successfully.'
  };
}

export const deleteAllMediasFailed = () => {
  return {
    type: MEDIA_DELETE_ALL_FAILED
  };
}
